import React, { useState, useEffect, useCallback } from "react";
import { Button, Container, Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";
import PayslipsUploadForm from "./PayslipsUploadForm";
import format from "date-fns/format";
import { deletePayslip, getProfile, getAuthUser } from "../payslipsActions";
import { Link } from "react-router-dom";
import addSeconds from "date-fns/addSeconds";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function PayslipsDashboardPage(props) {
    const [profile, setProfile] = useState([]);
    const [profileOriginal, setProfileOriginal] = useState([]);
    const [clientView, setClientView] = useState(false);
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();
    const [payslips, setPayslips] = useState([]);
    const [year, setYear] = useState(format(new Date(), "yyyy"));
    const [uniqueYears, setUniqueYears] = useState([]);

    useEffect(() => {
        let userId = "";
        if (id === "payslips") {
            userId = firebase.auth().currentUser.uid;
        } else {
            userId = id;
        }

        const unsubscribe2 = firestore
            .collection("users")
            .doc(userId)
            .onSnapshot((snapshot) => {
                setProfile(snapshot.data());
                if (id === snapshot.data().id && id !== "" && id !== "payslips") {
                    setClientView(true);
                } else {
                    setClientView(false);
                }
            });
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfileOriginal(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );

        const unsubscribe1 = firestore
            .collection("payslips")
            .where("userUid", "==", userId)
            .where("status", "==", "Mapped")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                list.sort((a, b) => (a.date < b.date ? 1 : -1));

                let years = [];

                Object.keys(list).forEach(function (key) {
                    var date = addSeconds(new Date(0), list[key]["date"]["seconds"]);
                    years.push(format(date, "yyyy"));
                });

                setUniqueYears([...new Set(years.map((item) => item))]);
                setPayslips(list);
            });
        return () => {
            unsubscribe1();
            unsubscribe2();
        };
    }, []);

    const changeYear = useCallback((value) => {
        setYear(value);
    }, []);

    function updateYear(uniqueYear) {
        changeYear(uniqueYear);
    }

    function removeFile(payslip) {
        deletePayslip(payslip);
    }

    return (
        <Container>
            {clientView === true && (
                <Segment style={{ marginBottom: "20px" }}>
                    <Grid>
                        <Grid.Row columns={2} key="toplevel">
                            <Grid.Column>
                                <h1>Staff Member: {profile.displayName ? profile.displayName : profile.email}</h1>
                            </Grid.Column>

                            <Grid.Column textAlign="right">
                                <Link className="ui button" as="a" to={"/home/" + profile.id}>
                                    Back to Dashboard
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}

            {profileOriginal && (profileOriginal.isSuper || profileOriginal.isAdmin || profileOriginal.isHR) ? <PayslipsUploadForm profileOriginal={profileOriginal} /> : ""}

            {payslips && payslips.length === 0 && <p>No Payslips Uploaded</p>}

            {uniqueYears &&
                uniqueYears.length > 0 &&
                uniqueYears.map((uniqueYear, idx) => (
                    <Segment secondary key={uniqueYear + "_" + idx}>
                        <Grid>
                            <GridRow>
                                <h1 className="payslipsOrder" onClick={() => updateYear(uniqueYear)}>
                                    {uniqueYear}
                                </h1>

                                {payslips &&
                                    payslips.length > 0 &&
                                    payslips.map((payslip, idx2) => (
                                        <>
                                            {format(payslip.date.toDate(), "yyyy") === uniqueYear ? (
                                                <Segment secondary key={payslip.id + "_" + idx2} style={{ width: "100%" }} hidden={year !== format(payslip.date.toDate(), "yyyy")}>
                                                    <Grid>
                                                        <GridRow>
                                                            <GridColumn width="four" verticalAlign="middle">
                                                                {payslip.documentName}
                                                            </GridColumn>
                                                            <GridColumn width="twelve">
                                                                <Segment>
                                                                    <Grid>
                                                                        <GridRow>
                                                                            <GridColumn width="eight" verticalAlign="middle">
                                                                                {format(payslip.date.toDate(), "d MMMM yyyy")}
                                                                                <br />
                                                                                Version {payslip.version}
                                                                            </GridColumn>

                                                                            <GridColumn width="eight" textAlign="right">
                                                                                <Button color="blue" as="a" target="_blank" href={payslip.fileUrl}>
                                                                                    Download File
                                                                                </Button>

                                                                                {(profile.isSuper || profileOriginal.isSuper) && (
                                                                                    <Button
                                                                                        color="red"
                                                                                        onClick={() => window.confirm("Are you sure you wish to delete this item?") && removeFile(payslip)}
                                                                                    >
                                                                                        Delete File
                                                                                    </Button>
                                                                                )}
                                                                            </GridColumn>
                                                                        </GridRow>
                                                                    </Grid>
                                                                </Segment>
                                                            </GridColumn>
                                                        </GridRow>
                                                    </Grid>
                                                </Segment>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ))}
                            </GridRow>
                        </Grid>
                    </Segment>
                ))}
        </Container>
    );
}
