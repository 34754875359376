import React, { useState, useEffect, useCallback } from "react";
import LeaveList from "../../staff/LeaveList/LeaveList";
import StaffDashboardStats from "../../staff/StaffDashboard/StaffDashboardStats";
import { Container, Grid, Segment } from "semantic-ui-react";
import { generateLeaveStats, getUserLeave, getLeave, getAuthUser } from "../../manager/managerActions";
import firebase from "../../../app/config/firebase";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { Formik } from "formik";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const firestore = firebase.firestore();

export default function StaffViewPage(props) {
    let userUid = props.match.params.id;

    const { currentUserProfile } = useSelector((state) => state.profile);

    const [leaveYears, setLeaveYears] = useState([]);
    const [profileClient, setProfileClient] = useState([]);
    const [leave, setLeave] = useState([]);
    const [leaveOld, setLeaveOld] = useState([]);
    const [auth, setAuth] = useState([]);
    const [clientView, setClientView] = useState(false);
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    const [selectedYear, setSelectedYear] = useState("");
    const [company, setCompany] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            async (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), ["id"]: `${res.id}` };
                    setAuth(appObj);

                    if (userId !== res.data().id) {
                        setClientView(true);
                    } else {
                        setClientView(false);

                        await firestore
                            .collection("companies")
                            .doc(appObj.companyId)
                            .onSnapshot((snapshot) => {
                                setCompany(snapshot.data());
                            });
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getUserLeave(userUid).then(
            async (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    setProfileClient(appObj);
                    await firestore
                        .collection("companies")
                        .doc(appObj.companyId)
                        .onSnapshot((snapshot) => {
                            setCompany(snapshot.data());
                        });
                }
            },
            (err) => {
                console.log(err);
            }
        );

        const unsubscribe2 = firestore
            .collection("leaveStatus")
            .where("userUid", "==", userUid)
            .where("periodType", "==", "Leave")
            .onSnapshot((snapshot) => {
                let allYears = [];
                let arrYear = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };

                        if (!arrYear.includes(format(obj.fromDate.toDate(), "yyyy"))) {
                            arrYear.push(format(obj.fromDate.toDate(), "yyyy"));

                            allYears.push({
                                key: obj.id,
                                text: format(obj.fromDate.toDate(), "yyyy"),
                                value: obj.id,
                            });
                        }
                    }
                });

                allYears.sort((a, b) => (b.text > a.text ? 1 : -1));
                setLeaveYears(allYears);

                if (selectedYear === "") {
                    setSelectedYear(allYears[0].key);
                }
            });

        const unsubscribe = firestore
            .collection("leave")
            .where("userUid", "==", userUid)
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });

                list.sort((a, b) => (a.annualFrom < b.annualFrom ? 1 : -1));

                setLeaveOld(list);

                if (selectedYear === "") {
                    firestore
                        .collection("leaveStatus")
                        .where("userUid", "==", userUid)
                        .where("periodType", "==", "Leave")
                        // .where("toDate", ">=", new Date())
                        .onSnapshot((snapshot) => {
                            let docs = snapshot.docs;
                            let dataExists = false;
                            let KeyId = 0;

                            Object.keys(docs).forEach((key) => {
                                let dateData = docs[key].data().toDate.toDate();
                                if (new Date(dateData.toDateString()) >= new Date(new Date().toDateString())) {
                                    // console.log(dateData.toDateString());
                                    dataExists = true;
                                    KeyId = key;
                                }
                            });

                            if (dataExists === true) {
                                let leaveByYear = [];

                                // if (selectedYear === "") {
                                //     console.log(snapshot.docs[KeyId].data());
                                //     console.log(snapshot.docs[KeyId].id);
                                //     setSelectedYear(snapshot.docs[KeyId].id);
                                // }

                                Object.keys(list).forEach(function (key) {
                                    if (
                                        list[key].annualFrom &&
                                        list[key].annualFrom.toDate() >= snapshot.docs[KeyId].data().fromDate.toDate() &&
                                        list[key].annualTo.toDate() <= snapshot.docs[KeyId].data().toDate.toDate()
                                    ) {
                                        leaveByYear.push(list[key]);
                                    }
                                });

                                setLeave(leaveByYear);
                            }
                        });
                } else {
                    firestore
                        .collection("leaveStatus")
                        .doc(selectedYear)
                        .onSnapshot((snapshot) => {
                            let leaveByYear = [];

                            // console.log(list);

                            Object.keys(list).forEach(function (key) {
                                // console.log(list[key]);
                                if (
                                    list[key].annualFrom &&
                                    list[key].annualFrom.toDate() >= snapshot.data().fromDate.toDate()
                                    // && list[key].annualTo.toDate() <= snapshot.data().toDate.toDate()
                                ) {
                                    leaveByYear.push(list[key]);
                                }
                            });

                            setLeave(leaveByYear);
                        });
                }
            });

        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, [selectedYear]);

    // console.log(selectedYear);

    async function genStats(profileClient) {
        generateLeaveStats(profileClient.id, "Leave stats updated!");
    }

    let genNewStats = false;

    //Always generate stats on first load
    if (profileClient && profileClient.isLoaded === true && profileClient.isEmpty === false && genNewStats === false) {
        genStats(profileClient);
        genNewStats = true;
    }

    const updateYear = useCallback((value) => {
        setSelectedYear(value);
    }, []);

    function changeYear(value) {
        updateYear(value);
    }

    return (
        <>
            {(clientView === true && (auth.isAdmin !== false || auth.isLineManager !== false)) || clientView === false ? (
                <div>
                    {clientView == false && (
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            enableReinitialize
                            validate={(values) => {
                                const errors = {};

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {}}
                        >
                            {({ values, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Grid>
                                        <MySelectInput
                                            label="Filter By Year"
                                            name="year"
                                            options={leaveYears}
                                            value={selectedYear}
                                            onChange={(field, value) => {
                                                changeYear(value.value);
                                                setFieldValue("year", value.value);
                                            }}
                                            placeholder={format(new Date(), "yyyy")}
                                            clearable={false}
                                        />
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    )}

                    {profileClient && (
                        <div style={{ padding: "40px 0" }}>
                            {profileClient.id !== auth.id && (
                                <Container>
                                    <Segment textAlign="center" style={{ marginBottom: "20px" }}>
                                        <Formik
                                            initialValues={{ email: "", password: "" }}
                                            enableReinitialize
                                            validate={(values) => {
                                                const errors = {};

                                                return errors;
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {}}
                                        >
                                            {({ values, handleSubmit, setFieldValue }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <Grid>
                                                        <MySelectInput
                                                            label="Filter By Year"
                                                            name="year"
                                                            options={leaveYears}
                                                            value={selectedYear || format(new Date(), "yyyy")}
                                                            onChange={(field, value) => {
                                                                changeYear(value.value);
                                                                setFieldValue("year", value.value);
                                                            }}
                                                            placeholder={format(new Date(), "yyyy")}
                                                            clearable={false}
                                                        />
                                                    </Grid>
                                                </form>
                                            )}
                                        </Formik>
                                        <h1 style={{ marginTop: -50 }}>Staff Member: {profileClient.displayName ? profileClient.displayName : profileClient.email}</h1>
                                        <Link className="ui button" style={{ float: "right", marginTop: "-40px" }} as="a" to={"/home/" + profileClient.id}>
                                            Back to Dashboard
                                        </Link>
                                    </Segment>
                                </Container>
                            )}
                            {/* <StaffDashboardStats auth={auth} profile={currentUserProfile} profileClient={profileClient} year={selectedYear} /> */}
                            {profileClient.id && <StaffDashboardStats auth={auth} profile={currentUserProfile} profileClient={profileClient} year={selectedYear} />}
                        </div>
                    )}

                    <Container>
                        <h2>Leave Requests</h2>
                        {company && company.leaveMethodType && company.leaveMethodType === "New Method" ? (
                            <LeaveList leave={leave} profileClient={profileClient} year={selectedYear} />
                        ) : (
                            <LeaveList leave={leaveOld} profileClient={profileClient} year={selectedYear} />
                        )}
                    </Container>
                </div>
            ) : (
                <Container>
                    <Segment textAlign="center">
                        <h1>Error 404 - page not found!</h1>
                    </Segment>
                </Container>
            )}
        </>
    );
}
