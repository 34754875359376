import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Grid, Container } from "semantic-ui-react";
import SettingsNav from "./SettingsNav";
import AccountPage from "./AccountPage";
import BankingPage from "./BankingPage";
import { updatePassword } from "../../auth/authActions";
import { updateProfile, getAuth, getAuthUser, getProfileClient } from "../userActions";
import OnboardingPage from "../../home/OnboardingPage";

export default function SettingsDashbaord(props) {
    const [user, setUser] = useState([]);
    const [providerId, setProviderId] = useState([]);
    const [clientProfile, setClientProfile] = useState([]);
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();
    useEffect(() => {
        setProviderId(getAuth());
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setUser(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getProfileClient(id).then(
            (res) => {
                if (res.exists) {
                    setClientProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    return (
        <Container>
            <Grid stackable reversed="mobile">
                <Grid.Column>
                    <Switch>
                        {id !== "basic" && id !== "settings" ? (
                            <>
                                <Redirect exact from="/settings" to={`/settings/basic/${id}`} />
                                <Route path={`/settings/basic/${id}`} render={() => <OnboardingPage updateProfile={updateProfile} initialValues={clientProfile} />} />
                            </>
                        ) : (
                            <>
                                <Redirect exact from="/settings" to="/settings/basic" />
                                <Route path="/settings/basic" render={() => <OnboardingPage updateProfile={updateProfile} initialValues={user} />} />
                                <Route path="/settings/account" render={() => <AccountPage updatePassword={updatePassword} providerId={providerId} />} />

                                <Route path="/settings/banking" render={() => <BankingPage user={user} />} />
                            </>
                        )}
                    </Switch>
                </Grid.Column>

                {/* <SettingsNav /> */}
            </Grid>
        </Container>
    );
}
