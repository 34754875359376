import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { List, ListItem, Segment, Grid, Label, Button } from "semantic-ui-react";
import format from "date-fns/format";
import { openModal } from "../../../app/common/modals/modalReducer";
import { updateRows } from "../staffActions";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import firebase from "../../../app/config/firebase";
import { addDays } from "date-fns";
const firestore = firebase.firestore();

export default function AllExpensePage(props) {
    let url = window.location.href.split("/");
    let lastSegment = url.pop() || url.pop();
    const { currentUserProfile } = useSelector((state) => state.profile);
    const [expenses, setExpenses] = useState([]);
    const [profileClient, setProfileClient] = useState({});
    const [clientView, setClientView] = useState(false);

    const [exportExp, setExport] = useState([]);

    const [dateFrom, setDateFrom] = useState(new Date(new Date().getFullYear() + "-03-01"));
    const [dateTo, setDateTo] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [userUid, setUserUid] = useState("");

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    useEffect(() => {
        let usr = "";
        if (lastSegment == "all") {
            usr = currentUserProfile.id;
            setUserUid(currentUserProfile.id);
        } else {
            usr = lastSegment;
            setUserUid(lastSegment);
            firestore
                .collection("users")
                .doc(lastSegment)
                .onSnapshot((snapshot) => {
                    if (snapshot.exists) {
                        setProfileClient({ ...snapshot.data(), id: snapshot.id });
                    }
                });
            setClientView(true);
        }

        updateRows(usr);

        const unsubscribe = firestore
            .collection("expenses")
            .where("userUid", "==", usr)
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                list.sort((a, b) => (b.createDate < a.createDate ? 1 : -1));
                setExpenses(list);
            });

        return () => {
            unsubscribe();
        };
    }, []);

    async function generateSARSReport(userUid, dateFrom, dateTo) {
        setLoading(true);

        await firestore
            .collection("expenses")
            .where("status", "==", "Approved")
            .where("userUid", "==", userUid)
            .onSnapshot(async (snapshot) => {
                let expenseList = [];
                snapshot.docs.forEach(async (result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };

                        await firestore
                            .collection("expenses")
                            .doc(obj.id)
                            .collection("loaded")
                            .onSnapshot((res) => {
                                res.docs.forEach((doc) => {
                                    let expObj = { ...doc.data(), id: doc.id };
                                    let startDate = format(dateFrom, "yyyy-MM-dd");
                                    let endDate = format(dateTo, "yyyy-MM-dd");

                                    if (expObj.category == "Travel" || expObj.category == "Fuel") {
                                        if (format(expObj.dateTimeFrom.toDate(), "yyyy-MM-dd") >= startDate && format(expObj.dateTimeTo.toDate(), "yyyy-MM-dd") < endDate) {
                                            if (expObj.status === "Approved") {
                                                expObj.dateTimeFrom = expObj.dateTimeFrom.toDate();
                                                expObj.dateTimeTo = expObj.dateTimeTo.toDate();
                                                expenseList.push(expObj);
                                            }
                                        }
                                    }
                                });
                            });
                    }
                });

                const someTimeoutAction = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve(expenseList);
                        }, 3000);
                    });
                };

                const expList = await someTimeoutAction();
                expList.sort((a, b) => (b.dateTimeFrom < a.dateTimeFrom ? 1 : -1));

                let exportData = [];
                let min = 0;
                let max = 0;
                let date = "";
                let comment = "";

                let total = 0;
                let pvt = 0;
                let business = 0;
                let weekday = "";
                let clientName = "";
                let reason = "Business";

                for (var i = 0; i < expList.length; i++) {
                    let newDate = format(expList[i].dateTimeFrom, "yyyy-MM-dd");

                    if (date !== newDate) {
                        date = newDate;
                        min = expList[i].odometerFrom;
                        weekday = format(expList[i].dateTimeFrom, "eeee");
                        comment = "";
                        total = 0;
                        pvt = 0;
                        business = 0;
                        clientName = "";
                    }

                    if ((date = newDate)) {
                        max = expList[i].odometerTo;
                        comment = comment + expList[i].description ? expList[i].description : "" + "  ";
                        total += expList[i].odometerTo - expList[i].odometerFrom;
                        clientName = clientName + expList[i].clientName ? expList[i].clientName : "" + "  ";

                        if (expList[i].type == "Personal") {
                            pvt += expList[i].odometerTo - expList[i].odometerFrom;
                        } else {
                            business += expList[i].odometerTo - expList[i].odometerFrom;
                        }
                    }

                    let nxt = expList[i + 1] !== undefined && format(expList[i + 1].dateTimeFrom, "yyyy-MM-dd");

                    if (newDate !== nxt) {
                        exportData.push({ date: date, open: min, close: max, total: total, pvt: pvt, business: business, comment: comment, weekday: weekday, clientName: clientName, reason: reason });
                    }
                }

                setExport(exportData);
                document.getElementById("downloadHere").click();
            });

        setTimeout(function () {
            setLoading(false);
        }, 3000);
    }

    return (
        <>
            <div className="ui form" style={{ backgroundColor: "#fff", padding: 20 }}>
                <Grid>
                    <Grid.Column width={16}>
                        <h3>SARS Report</h3>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <label>
                            <b>From Date:</b>
                        </label>
                        <DatePicker
                            name="fromDate"
                            id="fromDate"
                            dateFormat="yyyy-MM-dd"
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            dropdownMode="select"
                            value={dateFrom}
                            selected={dateFrom}
                            onChange={(value) => {
                                setDateFrom(value);
                            }}
                            disabledKeyboardNavigation
                        />
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <label>
                            <b>To Date:</b>
                        </label>
                        <DatePicker
                            name="toDate"
                            id="toDate"
                            dateFormat="yyyy-MM-dd"
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            dropdownMode="select"
                            value={dateTo}
                            selected={dateTo}
                            onChange={(value) => {
                                setDateTo(value);
                            }}
                            disabledKeyboardNavigation
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Button color="blue" style={{ float: "right", marginTop: 20 }} loading={loading} onClick={() => generateSARSReport(userUid, dateFrom, dateTo)}>
                            Download SARS Report
                        </Button>

                        <ExcelFile
                            element={
                                <Button color="blue" style={{ display: "none" }} id="downloadHere">
                                    Download
                                </Button>
                            }
                        >
                            <ExcelSheet data={exportExp} name="Leave">
                                <ExcelColumn label="DATE" value="date" />
                                <ExcelColumn label="OPENING KILOMETERS" value="open" />
                                <ExcelColumn label="CLOSING KILOMETERS" value="close" />
                                <ExcelColumn label="TOTAL KILOMETERS" value="total" />
                                <ExcelColumn label="PRIVATE KILOMETERS " value="pvt" />
                                <ExcelColumn label="BUSINESS KILOMETERS " value="business" />
                                <ExcelColumn label="BUSINESS TRAVEL DETAILS" value="comment" />
                                <ExcelColumn label="WEEKDAY" value="weekday" />
                                <ExcelColumn label="CLIENT NAME" value="clientName" />
                                <ExcelColumn label="REASON FOR VISIT" value="reason" />
                            </ExcelSheet>
                        </ExcelFile>
                    </Grid.Column>
                </Grid>
            </div>

            <br />
            <h2>Expenses</h2>
            {clientView === false ? (
                <Link as="a" to={`/expense/add`}>
                    <Button color="blue" style={{ float: "right" }}>
                        Add Expense
                    </Button>
                </Link>
            ) : (
                <Link as="a" to={`/expense/add/${profileClient.id}`}>
                    <Button color="blue" style={{ float: "right" }}>
                        Add Expense
                    </Button>
                </Link>
            )}
            <br />
            <br />
            <List>
                {expenses &&
                    expenses.length > 0 &&
                    expenses.map((expense, index) => (
                        <ListItem key={index}>
                            <Segment>
                                <Link as="a" to={"/expense/view/" + expense.id}>
                                    <Grid>
                                        <Grid.Row columns={3} verticalAlign="middle">
                                            <Grid.Column width={6}>
                                                <div className="header">
                                                    {expense.fromDate && expense.fromDate !== undefined ? format(expense.fromDate.toDate(), "d MMMM yyyy") + " - " : null}
                                                    {expense.toDate && expense.toDate !== undefined ? format(expense.toDate.toDate(), "d MMMM yyyy") : null}
                                                </div>
                                            </Grid.Column>

                                            <Grid.Column width={6}>
                                                <div style={{ color: "black" }}>Expenses = {expense.loaded}</div>
                                                <div style={{ color: "black" }}>Total = R {expense.total}</div>
                                            </Grid.Column>

                                            <Grid.Column width={4} textAlign="right">
                                                {expense.status === "Approved" && <Label color="green">{expense.status}</Label>}
                                                {expense.status === "Pending" && <Label color="orange">{expense.status}</Label>}
                                                {expense.status === "In Progress" && <Label color="blue">{expense.status}</Label>}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Link>
                            </Segment>
                        </ListItem>
                    ))}

                {expenses && expenses.length == 0 && <p>No expenses loaded yet</p>}
            </List>
        </>
    );
}
