import React, { useState, useEffect } from "react";
import { Segment, Container, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { getAuthUser, getCompanies, getTimeTrackAction } from "../reportsAction";
import addSeconds from "date-fns/addSeconds";
import { useSelector } from "react-redux";
import firebase from "../../../app/config/firebase";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";
import { toast } from "react-toastify";

const firestore = firebase.firestore();

export default function ReportTimeTrack(props) {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1));
    // const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [Row, setRow] = useState([]);
    const [departmentsList, setDepartmentsList] = useState([]);
    const [companyFilterId, setCompanyFilterId] = useState("0");
    const [statusFilter, setStatusFilter] = useState("");
    const [filter, setFilter] = useState("");
    const [filterDir, setFilterDir] = useState("asc");

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore.collection("departments").onSnapshot((snapshot) => {
            let list = [];
            snapshot.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });

            let departmentList = [];
            departmentList = list.map(function (department) {
                return {
                    key: department.id,
                    text: department.departmentName,
                    value: department.id,
                };
            });

            setDepartmentsList(departmentList);

            getTimes(departmentList, companyFilterId, statusFilter, startDate);
        });

        console.log(filter);

        return () => unsubscribe();
    }, [companyFilterId, statusFilter, startDate, filter, filterDir]);

    async function getTimes(departmentList, companyFilterId, statusFilter, startDate) {
        startDate = new Date(startDate);
        const response = await axios.post(apiURL + "/api/time_report", { date: startDate, department: companyFilterId, statusFilter: statusFilter });
        let allRows = [];
        response.data.forEach((document) => {
            if (document.submitted_by_timestamp !== null) {
                document.submitted_by_time = document.submitted_by_time + " " + document.submitted_by_timestamp;
            }
            if (document.first_submitted_timestamp !== null) {
                document.first_submitted_timestamp = document.first_submitted_timestamp;
            }
            if (document.approved_by_timestamp !== null) {
                document.approved_by_time = document.approved_by_time + " " + document.approved_by_timestamp;
            }

            if (currentUserProfile.isAdmin == true || currentUserProfile.isSuper == true) {
                if (companyFilterId == "0") {
                    departmentList.forEach((department) => {
                        if (document.departmentId == department.key) {
                            document.departmentName = department.text;
                        }
                    });
                    allRows.push(document);
                } else {
                    departmentList.forEach((department) => {
                        if (document.departmentId == department.key) {
                            document.departmentName = department.text;
                        }
                    });
                    if (document.departmentId == companyFilterId) {
                        allRows.push(document);
                    }
                }
            } else {
                if (currentUserProfile.departments.includes(document.departmentId)) {
                    departmentList.forEach((department) => {
                        if (document.departmentId == department.key) {
                            document.departmentName = department.text;
                        }
                    });

                    allRows.push(document);
                }

                if (document.approvalType == "Specific User" && document.approvalUser == currentUserProfile.id) {
                    departmentList.forEach((department) => {
                        if (document.departmentId == department.key) {
                            document.departmentName = department.text;

                            // document.status
                        }
                    });

                    // console.log(document);
                    allRows.push(document);
                }
            }

            document.status = document.status == "In Progress Previous" || document.status == "Progress Edit" ? "In Progress" : document.status;
        });

        // filter

        var newRows = allRows;

        if (filter == "displayName" && filterDir == "asc") {
            var newRows = [...allRows].sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
        } else if (filter == "displayName" && filterDir == "desc") {
            var newRows = [...allRows].sort((a, b) => (a.displayName > b.displayName ? -1 : 1));
        } else if (filter == "departmentName" && filterDir == "asc") {
            var newRows = [...allRows].sort((a, b) => (a.departmentName > b.departmentName ? 1 : -1));
        } else if (filter == "departmentName" && filterDir == "desc") {
            var newRows = [...allRows].sort((a, b) => (a.departmentName > b.departmentName ? -1 : 1));
        } else if (filter == "status" && filterDir == "asc") {
            var newRows = [...allRows].sort((a, b) => (a.status > b.status ? 1 : -1));
        } else if (filter == "status" && filterDir == "desc") {
            var newRows = [...allRows].sort((a, b) => (a.status > b.status ? -1 : 1));
        } else if (filter == "totalWorked" && filterDir == "asc") {
            var newRows = [...allRows].sort((a, b) => (a.hoursWorked > b.hoursWorked ? 1 : -1));
        } else if (filter == "totalWorked" && filterDir == "desc") {
            var newRows = [...allRows].sort((a, b) => (a.hoursWorked > b.hoursWorked ? -1 : 1));
        }

        if (newRows.length > 0) {
            console.log(newRows);
            setRow(newRows);
        } else {
            setRow([]);
        }
    }

    async function filterData(value) {
        setStartDate(value);
        // const response = await axios.post(apiURL + "/api/time_report", { date: value, department: companyFilterId });
        // setRow(response.data);
        // setEndDate(new Date(value.getFullYear(), value.getMonth() + 1, 0));
        getTimes(departmentsList, companyFilterId);
    }

    function changeId(value) {
        setCompanyFilterId(value);
    }

    function changeStatus(value) {
        setStatusFilter(value);
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let StatusList = [
        { key: "In Progress", text: "In Progress", value: "In Progress" },
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Approved", text: "Approved", value: "Approved" },
        { key: "Declined", text: "Declined", value: "Declined" },
        { key: "Cancelled", text: "Cancelled", value: "Cancelled" },
    ];

    async function sendReminder(userId) {
        await firestore.collection("email").add({
            userUid: userId,
            status: "Reminder",
        });
        toast.success("Reminder sent");
    }

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <h1>Time Track Activity Report</h1>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            {Row && Row.length > 0 && (
                                <ExcelFile element={<Button color="blue">Download</Button>}>
                                    <ExcelSheet data={Row} name="Leave">
                                        <ExcelColumn label="Display Name" value="displayName" />
                                        <ExcelColumn label="Department Name" value="departmentName" />
                                        <ExcelColumn label="Status" value="status" />
                                        <ExcelColumn label="Total Worked" value="hoursTotal" />
                                        <ExcelColumn label="First Submitted" value="first_submitted_timestamp" />
                                        <ExcelColumn label="Submitted At" value="submitted_by_time" />
                                        <ExcelColumn label="Actioned At" value="approved_by_time" />
                                    </ExcelSheet>
                                </ExcelFile>
                            )}

                            <Button as={Link} to="/reports/landing">
                                Back to Reports
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Segment>
                            <Grid stackable>
                                <Grid.Row columns={5}>
                                    <Grid.Column>
                                        <label>Choose a period</label>
                                        {/* <DatePicker
                                            name="startDate"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            dropdownMode="select"
                                            value={values.startDate}
                                            selected={startDate}
                                            onChange={(value) => {
                                                filterData(value);
                                            }}
                                            disabledKeyboardNavigation
                                        /> */}
                                        <input type="month" selected={startDate} onChange={(value) => filterData(value.target.value)} />
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        {currentUserProfile && (currentUserProfile.isSuper || currentUserProfile.isAdmin) && (
                                            <MySelectInput
                                                name="departmentId"
                                                options={departmentsList}
                                                placeholder="Choose a Department"
                                                value={values.companyId}
                                                onChange={(field, value) => {
                                                    changeId(value.value);
                                                    setFieldValue("departmentId", value.value);
                                                }}
                                            />
                                        )}
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: 4 }}>
                                        <MySelectInput
                                            name="status"
                                            options={StatusList}
                                            placeholder="Choose a Status"
                                            value={values.status}
                                            onChange={(field, value) => {
                                                changeStatus(value.value);
                                                setFieldValue("status", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </form>
                )}
            </Formik>

            <table className="ui celled table selectable">
                <thead>
                    <tr style={{ fontSize: 11 }}>
                        <th
                            onClick={() => {
                                setFilter("displayName");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            User Display Name
                        </th>
                        <th
                            onClick={() => {
                                setFilter("departmentName");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            Department Name
                        </th>
                        <th
                            onClick={() => {
                                setFilter("status");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            Status
                        </th>
                        <th
                            onClick={() => {
                                setFilter("totalWorked");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            Total Worked
                        </th>
                        <th
                            onClick={() => {
                                setFilter("totalWorked");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            First Submitted At
                        </th>
                        <th
                            onClick={() => {
                                setFilter("totalWorked");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            Submitted At
                        </th>
                        <th
                            onClick={() => {
                                setFilter("totalWorked");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            Actioned At
                        </th>
                        <th
                            onClick={() => {
                                setFilter("");
                                setFilterDir(filterDir == "asc" ? "desc" : "asc");
                            }}
                        >
                            Actions
                        </th>
                    </tr>
                </thead>

                <tbody style={{ fontSize: 11 }}>
                    {Row &&
                        Row.length > 0 &&
                        Row.map((timeTrackRow) => (
                            <tr key={timeTrackRow.id}>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.displayName}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.departmentName}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.status == "In Progress Previous" || timeTrackRow.status == "Progress Edit" ? "In Progress" : timeTrackRow.status}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.hoursTotal}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.first_submitted_timestamp}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.submitted_by_time}</strong>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <strong>{timeTrackRow.approved_by_time}</strong>
                                    </span>
                                </td>

                                <td width={150} data-label="Action">
                                    {timeTrackRow.status == "In Progress Previous" || timeTrackRow.status == "Progress Edit" || timeTrackRow.status == "In Progress" ? (
                                        <Button color="yellow" onClick={() => sendReminder(timeTrackRow.id)}>
                                            Send Reminder
                                        </Button>
                                    ) : (
                                        <Button color="blue" as={Link} to={`/timetracking/view/${timeTrackRow.id}`}>
                                            View Timetrack
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Container>
    );
}
