import React, { Component } from "react";
import { Button, Image, Modal, Divider, Confirm, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { deleteTimetrack } from "../manager/managerActions";

import { closeModal } from "./modalActions";

const mapState = (state, ownProps) => {
    return {
        profile: state.firebase.profile,
    };
};

const actions = { closeModal, deleteTimetrack };

class TimeViewModal extends Component {
    state = {
        open: false,
        timetrack: "",
        deleting: false,
    };

    open(timetrack) {
        this.setState({
            open: true,
            timetrack: timetrack,
        });
    }

    close() {
        this.setState({
            open: false,
            timetrack: "",
        });
    }

    removeTimetrack = async (timetrack) => {
        this.setState({
            deleting: true,
        });

        await this.props.deleteTimetrack(timetrack);

        this.setState({
            deleting: false,
        });

        this.props.closeModal();
    };

    render() {
        const { timetrack, profile, profileClient } = this.props;

        let header_cls = "orange_header";

        if (timetrack.status === "Approved") {
            header_cls = "green_header";
        } else if (timetrack.status === "Denied") {
            header_cls = "red_header";
        }

        return (
            <Modal size="large" open={true} onClose={this.props.closeModal} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
                <Modal.Header>
                    <Image style={{ zIndex: 2 }} avatar spaced="right" size="small" src={profileClient.photoURL || "/assets/user.png"} />

                    <div className={header_cls}>
                        <div className="timetracktype">???</div>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className="content_top">
                            <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                            <h2 className="ui center aligned header">DRG Time Tracking Form</h2>
                        </div>
                        <Divider />

                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell rowSpan={2}>Day</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={2}>Total Required</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan={2}>Total Worked</Table.HeaderCell>

                                    <Table.HeaderCell colSpan={timetrack.projectsCount} textAlign="center">
                                        Projects
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            {timetrack && timetrack.timetrack && timetrack.projectsCount > 0 && (
                                <Table.Body>
                                    {timetrack.timetrack.map((row, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell>{row.day}</Table.Cell>
                                            <Table.Cell>8</Table.Cell>
                                            <Table.Cell>?</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            )}
                        </Table>

                        {profile.isSuper && (
                            <Button
                                color="red"
                                fluid
                                size="large"
                                loading={this.state.deleting}
                                disabled={this.state.deleting}
                                style={{ marginTop: 10 }}
                                onClick={() => window.confirm("Are you sure you wish to delete this item?") && this.removeTimetrack(timetrack)}
                            >
                                DELETE
                            </Button>
                        )}
                        <Confirm open={this.state.open} onCancel={this.close} onConfirm={this.removeFile} header="You are about to remove a Record" />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default connect(mapState, actions)(TimeViewModal);
