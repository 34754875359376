import firebase from "../../app/config/firebase";
import addSeconds from "date-fns/addSeconds";

const firestore = firebase.firestore();

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    return firestore.collection("users").doc(userData).get();
};

export async function getLeaveInfo(companyIdFilter) {
    return await firestore.collection("companies").doc(companyIdFilter).get();
}

export async function getUserList(companyIdFilter) {
    const userId = firebase.auth().currentUser.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo.isSuper) {
        if (companyIdFilter !== "0") {
            return await firestore.collection("users").where("companyId", "==", companyIdFilter).where("isTerminated", "==", false).get();
        } else {
            return await firestore.collection("users").where("isTerminated", "==", false).get();
        }
    } else {
        if (userInfo.isAdmin == false) {
            return await firestore.collection("users").where("companyId", "==", userInfo.companyId).where("isTerminated", "==", false).get();
        } else {
            return await firestore.collection("users").where("companyId", "==", userInfo.companyId).where("isTerminated", "==", false).where("departmentId", "in", userInfo.departments).get();
        }
    }
}

export async function getCompanies() {
    return await firestore.collection("companies").get();
}

export async function getLeave(companyIdFilter) {
    const userId = firebase.auth().currentUser.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo.isSuper) {
        if (companyIdFilter !== "0") {
            return await firestore.collection("leave").where("leaveType", "==", "Sick").where("status", "==", "Approved").where("companyId", "==", companyIdFilter).get();
        } else {
            return await firestore.collection("leave").where("leaveType", "==", "Sick").where("status", "==", "Approved").get();
        }
    } else {
        return await firestore.collection("leave").where("leaveType", "==", "Sick").where("status", "==", "Approved").where("companyId", "==", userInfo.companyId).get();
    }
}

export async function getUsersAction(companyFilterId) {
    const userId = firebase.auth().currentUser.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo.isSuper) {
        if (companyFilterId !== "0") {
            return await firestore.collection("users").where("companyId", "==", companyFilterId).where("isTerminated", "==", false).get();
        } else {
            return await firestore.collection("users").where("isTerminated", "==", false).get();
        }
    } else {
        return await firestore
            .collection("users")
            .where("companyId", "==", userInfo.companyId)
            .where("departmentId", "in", userInfo.departments && userInfo.departments.length > 0 ? userInfo.departments : ["None"])
            .where("isTerminated", "==", false)
            .get();
    }
}

export async function getLeaveAction(companyFilterId) {
    const userId = firebase.auth().currentUser.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), ["id"]: `${res.id}` };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo.isSuper) {
        if (companyFilterId !== "0") {
            return await firestore.collection("leave").where("companyId", "==", companyFilterId).get();
        } else {
            return await firestore.collection("leave").get();
        }
    } else if (userInfo.isAdmin || userInfo.isHR) {
        if (companyFilterId !== "0") {
            return await firestore.collection("leave").where("companyId", "==", companyFilterId).get();
        } else {
            return [];
        }
    } else {
        return await firestore
            .collection("leave")
            .where("companyId", "==", userInfo.companyId)
            .where("departmentId", "in", userInfo.departments && userInfo.departments.length > 0 ? userInfo.departments : ["None"])
            .get();
    }
}

export async function getSiteName(userUid) {
    const userDoc = await firestore.collection('users').doc(userUid).get();
    // console.log(userDoc.data().siteName)
    if (userDoc.exists) {
        if (userDoc.data().siteName) {
            return userDoc.data().siteName;
        } else if (userDoc.data().site) {
            return userDoc.data().site;
        } else {
            return "No site for this user."
        }
    } else {
        return "No doc exists";
    }
}

export async function getTimeTrackAction(companyFilterId, startDate) {
    const userId = firebase.auth().currentUser.uid;
    let infoData = await firestore.collection("users").doc(userId).get();
    let userInfo = infoData.data();

    const timestamp1 = firebase.firestore.Timestamp.fromDate(startDate);

    let allRows = [];

    if (userInfo.isSuper) {
        let currData = [];

        if (companyFilterId !== "0") {
            let CompanyData = await firestore.collection("timetrack").where("companyId", "==", companyFilterId).where("minDate", ">=", timestamp1).get();

            for (let i = 0; i < CompanyData.docs.length; i++) {
                let timeId = CompanyData.docs[i].data().id;

                if (timeId !== undefined) {
                    let RowData = await firestore.collection("timetrackRow").where("timeId", "==", timeId).get();

                    for (let x = 0; x < RowData.docs.length; x++) {
                        let dataRow = { ...RowData.docs[x].data(), id: RowData.docs[x].id, displayName: CompanyData.docs[i].data().displayName };
                        currData.push(dataRow);
                    }
                }
            }
        } else {
            let CompanyData = await firestore.collection("timetrack").where("minDate", ">=", timestamp1).get();

            for (let i = 0; i < CompanyData.docs.length; i++) {
                let timeId = CompanyData.docs[i].data().id;

                if (timeId !== undefined) {
                    let RowData = await firestore.collection("timetrackRow").where("timeId", "==", timeId).get();

                    for (let x = 0; x < RowData.docs.length; x++) {
                        let dataRow = { ...RowData.docs[x].data(), id: RowData.docs[x].id, displayName: CompanyData.docs[i].data().displayName };
                        currData.push(dataRow);
                    }
                }
            }
        }

        allRows.push(currData);
    } else {
        let currData = [];

        let CompanyData = await firestore.collection("timetrack").where("companyId", "==", userInfo.companyId).where("minDate", ">=", timestamp1).get();

        for (let i = 0; i < CompanyData.docs.length; i++) {
            let timeId = CompanyData.docs[i].data().id;

            if (timeId !== undefined) {
                let RowData = await firestore.collection("timetrackRow").where("timeId", "==", timeId).get();

                for (let x = 0; x < RowData.docs.length; x++) {
                    let dataRow = { ...RowData.docs[x].data(), id: RowData.docs[x].id, displayName: CompanyData.docs[i].data().displayName };
                    currData.push(dataRow);
                }
            }
        }
    }

    return allRows;
}

export async function getProjectTimeTrackAction(companyFilterId, startDate, endDate) {
    const userId = firebase.auth().currentUser.uid;
    let infoData = await firestore.collection("users").doc(userId).get();
    let userInfo = infoData.data();
    const timestamp1 = firebase.firestore.Timestamp.fromDate(startDate);
    let allRows = [];

    if (userInfo.isSuper) {
        let currData = [];

        if (companyFilterId !== "0") {
            let ProjectData = await firestore.collection("projects").where("companyId", "==", companyFilterId).get();

            for (let i = 0; i < ProjectData.docs.length; i++) {
                let projectId = ProjectData.docs[i].id;
                let totalTime = 0;

                let TimeTrackRowData = await firestore.collection("timetrackRow").where("projectId", "==", projectId).where("day", ">=", timestamp1).get();

                for (let x = 0; x < TimeTrackRowData.docs.length; x++) {
                    if (addSeconds(new Date(0), TimeTrackRowData.docs[x].data().day.seconds) <= endDate) {
                        totalTime += parseInt(TimeTrackRowData.docs[x].data().timeWorked);
                    }
                }
                currData.push({ projectName: ProjectData.docs[i].data().projectName, totalTime: totalTime });
                totalTime = 0;
            }
        } else {
            let ProjectData = await firestore.collection("projects").get();

            for (let i = 0; i < ProjectData.docs.length; i++) {
                let projectId = ProjectData.docs[i].id;
                let totalTime = 0;

                let TimeTrackRowData = await firestore.collection("timetrackRow").where("projectId", "==", projectId).where("day", ">=", timestamp1).get();

                for (let x = 0; x < TimeTrackRowData.docs.length; x++) {
                    if (addSeconds(new Date(0), TimeTrackRowData.docs[x].data().day.seconds) <= endDate) {
                        totalTime += parseInt(TimeTrackRowData.docs[x].data().timeWorked);
                    }
                }
                currData.push({ projectName: ProjectData.docs[i].data().projectName, totalTime: totalTime });
                totalTime = 0;
            }
        }

        allRows.push(currData);
    } else {
        let currData = [];

        let ProjectData = await firestore.collection("projects").where("companyId", "==", userInfo.companyId).get();

        for (let i = 0; i < ProjectData.docs.length; i++) {
            let projectId = ProjectData.docs[i].id;
            let totalTime = 0;

            let TimeTrackRowData = await firestore.collection("timetrackRow").where("projectId", "==", projectId).where("day", ">=", timestamp1).get();

            for (let x = 0; x < TimeTrackRowData.docs.length; x++) {
                if (addSeconds(new Date(0), TimeTrackRowData.docs[x].data().day.seconds) <= endDate) {
                    totalTime += parseInt(TimeTrackRowData.docs[x].data().timeWorked);
                }
            }
            currData.push({ projectName: ProjectData.docs[i].data().projectName, totalTime: totalTime });
            totalTime = 0;
        }
    }

    return allRows;
}
