import cuid from "cuid";
import { toast } from "react-toastify";

//This is used if we want to access firebase directly without using getFirestore
import firebase from "../../app/config/firebase";

import { genSickLeave, generateNewLeaveStats, generateLeaveStats } from "../manager/managerActions";

export async function createUser(user) {
    const firestore = firebase.firestore();

    const { isLoaded, isEmpty, email, password, emailAddress, ...updatedUser } = user;

    updatedUser.companyName = "";

    // if (updatedUser.dateOfBirth !== getState().firebase.profile.dateOfBirth) {
    //     updatedUser.dateOfBirth = updatedUser.dateOfBirth;
    // }

    // if (updatedUser.employmentStartDate) {
    //     updatedUser.employmentStartDate = updatedUser.employmentStartDate;
    // }

    // if (updatedUser.takeOnStartDate) {
    //     updatedUser.takeOnStartDate = updatedUser.takeOnStartDate;
    // }

    if (updatedUser.changeEmploymentStartDate) {
        updatedUser.employmentStartDate = updatedUser.changeEmploymentStartDate;
        delete updatedUser.changeEmploymentStartDate;
    }

    if (updatedUser.changeTakeOnStartDate) {
        updatedUser.takeOnStartDate = updatedUser.changeTakeOnStartDate;
        delete updatedUser.changeTakeOnStartDate;
    }

    if (updatedUser.companyId) {
        let companyDoc = await firestore.collection("companies").doc(updatedUser.companyId).get();

        if (companyDoc.exists) {
            updatedUser.companyName = companyDoc.data().companyName;

            if (companyDoc.data().logoUrl) {
                updatedUser.logoUrl = companyDoc.data().logoUrl;
            }

            if (companyDoc.data().hasLeave) {
                updatedUser.hasLeave = companyDoc.data().hasLeave;
            } else {
                updatedUser.hasLeave = false;
            }

            if (companyDoc.data().hasTimeTracking) {
                updatedUser.hasTimeTracking = companyDoc.data().hasTimeTracking;
            } else {
                updatedUser.hasTimeTracking = false;
            }

            if (companyDoc.data().yearStart) {
                updatedUser.companyYearStart = companyDoc.data().yearStart;
            } else {
                updatedUser.companyYearStart = "";
            }

            if (companyDoc.data().leavePerMonth) {
                updatedUser.companyLeavePerMonth = companyDoc.data().leavePerMonth;
            } else {
                updatedUser.companyLeavePerMonth = "";
            }
        }
    }

    if (updatedUser.departmentId !== "") {
        let departmentDoc = await firestore.collection("departments").doc(updatedUser.departmentId).get();
        updatedUser.departmentName = departmentDoc.data().departmentName;
    } else {
        delete updatedUser.departmentId;
    }

    updatedUser.onboardingDone = false;

    //Make sure that white spaces are removed
    updatedUser.email = email.replace(/\s/g, "");

    try {
        //We need to set up a second firebase login because otherwise we get logged into the newly created user
        const hostname = window && window.location && window.location.hostname;

        var config = {};

        //Figure out which Firebase instance to connect to
        if (hostname === "think-drg.web.app" || hostname === "drgonline.think.org.za") {
            //Think Live
            config = {
                apiKey: "AIzaSyDfu4amaOD7Drc310cM8qP90z1IwkBFhiM",
                authDomain: "think-drg.firebaseapp.com",
                databaseURL: "https://think-drg.firebaseapp.com",
            };
        } else if (hostname === "drgonline.co.za" || hostname === "drg-outsourcing.web.app" || hostname === "drg-outsourcing.firebaseapp.com") {
            //DRG LIVE
            config = {
                apiKey: "AIzaSyCe4OqsAeeXMljIS29QYJPeA570Hpe3K3A",
                authDomain: "drg-outsourcing.firebaseapp.com",
                databaseURL: "https://drg-outsourcing.firebaseio.com",
            };
        } else if (hostname === "drg-outsourcing-test.web.app" || hostname === "drg-outsourcing-test.firebaseapp.com") {
            //DRG TEST
            config = {
                apiKey: "AIzaSyDlsSuDAA7sZw3Dyf2HRDHM5JmDMKiUCXM",
                authDomain: "drg-outsourcing-test.firebaseapp.com",
                databaseURL: "https://drg-outsourcing-test.firebaseio.com",
            };
        } else if (hostname === "localhost") {
            //Think Live
            config = {
                apiKey: "AIzaSyDlsSuDAA7sZw3Dyf2HRDHM5JmDMKiUCXM",
                authDomain: "drg-outsourcing-test.firebaseapp.com",
                databaseURL: "https://drg-outsourcing-test.firebaseio.com",
            };

            // //DRG TEST
            // config = {
            //     apiKey: "AIzaSyDlsSuDAA7sZw3Dyf2HRDHM5JmDMKiUCXM",
            //     authDomain: "drg-outsourcing-test.firebaseapp.com",
            //     databaseURL: "https://drg-outsourcing-test.firebaseio.com",
            // };
        } else {
            //DRG TEST
            config = {
                apiKey: "AIzaSyDlsSuDAA7sZw3Dyf2HRDHM5JmDMKiUCXM",
                authDomain: "drg-outsourcing-test.firebaseapp.com",
                databaseURL: "https://drg-outsourcing-test.firebaseio.com",
            };
        }

        //var secondaryApp = firebase.initializeApp(config, "Secondary");
        //if (!secondaryApp) {
        var secondaryApp = firebase.initializeApp(config, "Secondary" + cuid());
        //}

        secondaryApp
            .auth()
            .createUserWithEmailAndPassword(emailAddress, password)
            .then(async function (firebaseUser) {
                secondaryApp.auth().signOut();
                secondaryApp.delete();

                updatedUser.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                updatedUser.id = firebaseUser.user.uid;
                updatedUser.isTerminated = false;

                console.log(updatedUser.id);
                console.log(firebaseUser.user.uid);

                await firestore
                    .collection("users")
                    .doc(updatedUser.id)
                    .set(updatedUser, { merge: true })
                    .then(async function () {
                        toast.success("User created");

                        await firestore
                            .collection("companies")
                            .doc(updatedUser.companyId)
                            .onSnapshot(async (snapshot) => {
                                let companyDetails = snapshot.data();

                                if (companyDetails.hasLeave == true) {
                                    if (companyDetails.leaveMethodType && companyDetails.leaveMethodType === "New Method") {
                                        await genSickLeave(updatedUser.id);
                                        await generateNewLeaveStats(updatedUser.id);
                                    } else {
                                        await generateLeaveStats(updatedUser.id, "Leave Created");
                                    }
                                }
                            });
                    });
            })
            .catch(function (error) {
                console.log(error.message);
                toast.error("something went wrong");
            });
    } catch (e) {
        console.log(e);
    }
}

export async function updateProfile(user) {
    const firestore = firebase.firestore();

    try {
        user.companyName = "";

        if (user.companyId) {
            await firestore
                .collection("companies")
                .doc(user.companyId)
                .get()
                .then((res) => {
                    if (res.exists) {
                        let companyData = res.data();

                        user.companyName = companyData.companyName ? companyData.companyName : "";

                        user.companyLeaveRolloverType = companyData.leaveRolloverType ? companyData.leaveRolloverType : "";

                        user.companyLeaveAccumulativeForfeit = 0;

                        if (companyData.leaveAccumulativeForfeit) {
                            user.companyLeaveAccumulativeForfeit = companyData.leaveAccumulativeForfeit;
                        }

                        user.companyLeaveAccumulativeAfterForfeit = 0;

                        if (companyData.leaveAccumulativeAfterForfeit) {
                            user.companyLeaveAccumulativeAfterForfeit = companyData.leaveAccumulativeAfterForfeit;
                        }
                    }
                });

            await firestore
                .collection("leave")
                .where("userUid", "==", user.id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(async function (doc) {
                        if (doc.exists) {
                            await firestore.collection("leave").doc(doc.id).update({ companyId: user.companyId });
                        }
                    });
                });
        }

        if (user.departmentId !== undefined && user.departmentId !== "") {
            let departmentDoc = await firestore.collection("departments").doc(user.departmentId).get();
            user.departmentName = departmentDoc.data().departmentName;
        } else {
            user.departmentName = "";
        }

        if (user.siteId !== undefined && user.siteId !== "") {
            let siteDoc = await firestore.collection("sites").doc(user.siteId).get();
            user.siteName = siteDoc.data().siteName;
        } else {
            user.siteName = "";
        }

        if (user.isLoaded) {
            delete user.isLoaded;
        }

        if (user.isEmpty) {
            delete user.isEmpty;
        }

        await firestore.collection("users").doc(user.id).set(user);

        await firestore
            .collection("companies")
            .doc(user.companyId)
            .onSnapshot(async (snapshot) => {
                let companyDetails = snapshot.data();

                if (companyDetails.hasLeave == true) {
                    if (companyDetails.leaveMethodType && companyDetails.leaveMethodType === "New Method") {
                        await genSickLeave(user.id);
                        await generateNewLeaveStats(user.id);
                    } else {
                        await generateLeaveStats(user.id, "Leave Created");
                    }
                }
            });

        toast.success("Profile updated");
    } catch (e) {
        console.log(e);
    }
}

export async function deleteUser(userUid) {
    const firestore = firebase.firestore();
    const collections = [
        "comments",
        "email",
        "expenses",
        "irp5",
        "leave",
        "leaveStatus",
        "leave_outstanding",
        "notifications",
        "payslips",
        "remoteWork",
        "resources",
        "support",
        "timetrack",
        "timetrackRow",
        "timetrack_outstanding",
    ];

    // loop through collections and get files from each collection where userUid === userUid
    // loop through files and delete each one
    for (let i = 0; i < collections.length; i++) {
        firestore
            .collection(collections[i])
            .where("userUid", "==", userUid)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.delete();
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // search through all projects and check if userUid is in users array.
    // delete from array if present
    let projects = await firestore.collection("projects").where("users", "array-contains", userUid).get();
    projects.forEach((doc) => {
        const newUsersArr = doc.data().users.filter((el) => el != userUid);
        doc.ref.update({
            users: newUsersArr,
        });
    });

    // delete from users collection
    let userDoc = firestore
        .collection("users")
        .where("id", "==", userUid)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.delete();
            });
        });

    // delete from firebase auth
    // call cloud function here

    toast.success("User deleted");
}

export async function createCompany(company) {
    const firestore = firebase.firestore();

    const { isLoaded, isEmpty, ...newCompany } = company;

    let companyLogo = company.uploadFile;
    delete newCompany.uploadFile;

    try {
        //Upload files
        let companyData = await firestore.collection("companies").add(newCompany);

        if (companyLogo !== undefined) {
            const file = companyLogo;

            let values = {};

            const path = `${companyData.id}/company_logo`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            values.logoName = file.name;
            values.logoUrl = uploadedFile;
            await firestore.collection("companies").doc(companyData.id).update(values);
        }

        toast.success("Company created");
    } catch (e) {
        toast.error("Something went wrong");
        console.log(e);
    }
}

export async function updateCompany(company) {
    const firestore = firebase.firestore();
    const { isLoaded, isEmpty, ...updatedCompany } = company;

    company = updatedCompany;

    let companyLogo = company.uploadFile;
    delete company.uploadFile;

    try {
        if (companyLogo !== undefined) {
            const file = companyLogo;

            const path = `${company.id}/company_logo`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            // upload the file to firebase storage
            let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            // add the new photo to photos collection
            // await firestore.collection("companies").doc(company.id).update({ logoName: file.name, logoUrl: downloadURL });

            company.logoUrl = downloadURL;
            company.logoName = file.name;
        }

        let companyDocRef = firestore.collection("companies").doc(company.id);

        let batch = firestore.batch();
        await batch.update(companyDocRef, company);

        let usersRef = firestore.collection("users");
        let usersQuery = await usersRef.where("companyId", "==", company.id);
        let usersQuerySnap = await usersQuery.get();

        let companyQueryRef = await firestore.collection("companies").doc(company.id).get();

        for (let i = 0; i < usersQuerySnap.docs.length; i++) {
            let usersDocRef = await firestore.collection("users").doc(usersQuerySnap.docs[i].id);

            let newData = {
                hasLeave: false,
                hasTimeTracking: false,
                companyYearStart: company.yearStart !== undefined ? company.yearStart : "",
                companyName: company.companyName,
                companyLeavePerMonth: company.leavePerMonth !== undefined ? company.leavePerMonth : "",
                companyNegLeaveApproval: company.negLeaveApproval !== undefined ? company.negLeaveApproval : "",
            };

            if (company.hasLeave) {
                newData.hasLeave = company.hasLeave;
            }

            if (company.hasTimeTracking) {
                newData.hasTimeTracking = company.hasTimeTracking;
            }

            if (company.logoUrl) {
                newData.logoUrl = company.logoUrl;
            }

            if (company.hrCompany) {
                newData.hrCompany = company.hrCompany;
            }

            if (company.hrDRG) {
                newData.hrDRG = company.hrDRG;
            }

            if (company.leaveType) {
                newData.companyLeaveType = company.leaveType;
            } else {
                newData.companyLeaveType = "Company Financial Year";
            }

            if (company.companyNegLeaveApproval) {
                newData.companyNegLeaveApproval = company.negLeaveApproval;
            }

            await batch.update(usersDocRef, newData);
        }

        await batch.commit();

        toast.success("Company updated");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function createDepartment(department) {
    const firestore = firebase.firestore();

    const { isLoaded, isEmpty, ...newDepartment } = department;

    if (department.companyId) {
        let companyDoc = await firestore.collection("companies").doc(department.companyId).get();

        if (companyDoc.exists) {
            newDepartment.companyName = companyDoc.data().companyName;
        }
    }

    try {
        await firestore.collection("departments").add(newDepartment);
        toast.success("Department created");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function updateDepartment(department) {
    const firestore = firebase.firestore();
    const { isLoaded, isEmpty, ...updatedDepartment } = department;

    if (department.companyId) {
        let companyDoc = await firestore.collection("companies").doc(department.companyId).get();

        if (companyDoc.exists) {
            updatedDepartment.companyName = companyDoc.data().companyName;
        }
    }

    try {
        await firestore.collection("departments").doc(department.id).set(updatedDepartment);
        toast.success("Department updated");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function createSite(site) {
    const firestore = firebase.firestore();

    const { isLoaded, isEmpty, ...newSite } = site;

    if (site.companyId) {
        let companyDoc = await firestore.collection("companies").doc(site.companyId).get();

        if (companyDoc.exists) {
            newSite.companyName = companyDoc.data().companyName;
        }
    }

    try {
        await firestore.collection("sites").add(newSite);
        toast.success("Site created");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function updateSite(site) {
    const firestore = firebase.firestore();
    const { isLoaded, isEmpty, ...updatedSite } = site;

    if (site.companyId) {
        let companyDoc = await firestore.collection("companies").doc(site.companyId).get();

        if (companyDoc.exists) {
            updatedSite.companyName = companyDoc.data().companyName;
        }
    }

    try {
        await firestore.collection("sites").doc(site.id).set(updatedSite);
        toast.success("Site updated");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function createProject(project) {
    const firestore = firebase.firestore();

    const { isLoaded, isEmpty, ...newProject } = project;

    if (project.companyId) {
        let companyDoc = await firestore.collection("companies").doc(project.companyId).get();

        if (companyDoc.exists) {
            newProject.companyName = companyDoc.data().companyName;
        }
    }

    try {
        await firestore.collection("projects").add(newProject);
        toast.success("Project created");
    } catch (e) {
        toast.error("Something went wrong");
        console.log(e);
    }
}

export async function updateProject(project) {
    const firestore = firebase.firestore();
    const { isLoaded, isEmpty, ...newProject } = project;

    if (project.companyId) {
        let companyDoc = await firestore.collection("companies").doc(project.companyId).get();

        if (companyDoc.exists) {
            newProject.companyName = companyDoc.data().companyName;
        }
    }

    try {
        await firestore.collection("projects").doc(project.id).update(newProject);
        toast.success("Project updated");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function addBlockedDate(blockeddate) {
    const firestore = firebase.firestore();

    const { isLoaded, isEmpty, ...newDate } = blockeddate;

    newDate.blockDate = newDate.blockDate;

    try {
        await firestore.collection("blockedDates").add(newDate);
        toast.success("Date added");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function deleteBlockedDate(blockeddate) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("blockedDates").doc(blockeddate.id).delete();

        toast.success("Date deleted");
    } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
    }
}

export async function terminateUser(user) {
    const firestore = firebase.firestore();

    try {
        let terminate = false;
        let txt = "Activated";
        if (!user.isTerminated || user.isTerminated === false) {
            terminate = true;
            txt = "Terminated";
        }

        await firestore.collection("users").doc(user.id).update({ isTerminated: terminate });

        toast.success("User " + txt);
    } catch (e) {
        console.log(e);
    }
}

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getUsers() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userId = auth.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), ["id"]: `${res.id}` };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    if (userInfo.isSuper === true) {
        return firestore.collection("users").get();
    } else {
        return firestore.collection("users").where("companyId", "==", userInfo.companyId).get();
    }
}

export async function getUser(userID) {
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userID).get();
}
export async function getCompany() {
    const firestore = firebase.firestore();
    return firestore.collection("companies").get();
}
export async function getDepartments(id) {
    const firestore = firebase.firestore();
    return firestore.collection("departments").where("companyId", "==", id).get();
}
export async function getSites(id) {
    const firestore = firebase.firestore();
    return firestore.collection("sites").where("companyId", "==", id).get();
}
export async function getCompanyUsers(id) {
    const firestore = firebase.firestore();
    return firestore.collection("users").where("companyId", "==", id).where("isTerminated", "==", false).get();
}
export async function getCompanyHrUsers(id) {
    const firestore = firebase.firestore();
    return firestore.collection("users").where("companyId", "==", id).where("isTerminated", "==", false).where("isHR", "==", true).get();
}
export async function getAllDepartments() {
    const firestore = firebase.firestore();
    return firestore.collection("departments").orderBy("departmentName", "asc").get();
}
export async function getProjects() {
    const firestore = firebase.firestore();
    return firestore.collection("projects").orderBy("companyId", "asc").get();
}
export async function getProject(projectId) {
    const firestore = firebase.firestore();
    return firestore.collection("projects").doc(projectId).get();
}
export async function getCompanyData(companyId) {
    const firestore = firebase.firestore();
    return firestore.collection("companies").doc(companyId).get();
}
export async function getBlockedDates() {
    const firestore = firebase.firestore();
    return firestore.collection("blockedDates").orderBy("blockDate", "desc").get();
}
export async function getDepartment(departmentId) {
    const firestore = firebase.firestore();
    return firestore.collection("departments").doc(departmentId).get();
}
export async function deleteDepartment(departmentId) {
    const firestore = firebase.firestore();
    return firestore.collection("departments").doc(departmentId).delete();
}
export async function getSite(siteId) {
    const firestore = firebase.firestore();
    return firestore.collection("sites").doc(siteId).get();
}
export async function deleteSite(siteId) {
    const firestore = firebase.firestore();
    return firestore.collection("sites").doc(siteId).delete();
}

export async function resetPassword(user) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("users").doc(user.id).update({ isPasswordReset: true, passwordResetLink: "" });

        toast.success("Reset Link is being generated");
    } catch (e) {
        console.log(e);
    }
}
