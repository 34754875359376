import React from "react";
import NavBar from "../../features/nav/NavBar/NavBar";
import { Container } from "semantic-ui-react";
import { Route } from "react-router-dom";
import ModalManager from "../../features/modals/modalManager";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingComponent from "./LoadingComponent";
import ProfilePage from "../../features/profiles/profilePage/ProfilePage";
import AccountPage from "../../features/auth/AccountPage";
import PrivateRoute from "./PrivateRoute";
import "react-toastify/dist/ReactToastify.css";

import HomePage from "../../features/home/HomePage";
import ErrorComponent from "../common/errors/ErrorComponent";
import LoginForm from "../../features/auth/LoginForm";

import NotificationsDashboardPage from "../../features/notifications/NotificationsDashboard/NotificationsDashboardPage";
import LeaveViewPage from "../../features/staff/LeaveView/LeaveViewPage";
import StaffViewPage from "../../features/clients/StaffDashboard/StaffViewPage";

import SuperPage from "../../features/super/SuperPage";
import UsersPage from "../../features/super/Users/UsersPage";
import UsersDetailedPage from "../../features/super/Users/UsersDetailedPage";
import DepartmentsPage from "../../features/super/Departments/DepartmentsPage";
import DepartmentsDetailedPage from "../../features/super/Departments/DepartmentsDetailedPage";
import ProjectsPage from "../../features/super/Projects/ProjectsPage";
import ProjectsDetailedPage from "../../features/super/Projects/ProjectsDetailedPage";
import SitesPage from "../../features/super/Sites/SitesPage";
import SitesDetailedPage from "../../features/super/Sites/SitesDetailedPage";
import CompaniesPage from "../../features/super/Companies/CompaniesPage";
import CompaniesDetailedPage from "../../features/super/Companies/CompaniesDetailedPage";
import BlockedDatesPage from "../../features/super/BlockedDates/BlockedDatesPage";

import TimeTrackPage from "../../features/staff/TimeTrackList/TimeTrackPage";

import TimeTrackOutstanding from "../../features/staff/TimeTrackList/TimeTrackOutstanding";

import ResourcesDashboardPage from "../../features/resources/ResourcesDashboard/ResourcesDashboardPage";
import CompanyResourcesDashboardPage from "../../features/resources/CompanyResources/CompanyResourcesDashboardPage";

import StaffDashboardPage from "../../features/clients/StaffDashboard/StaffDashboardPage";
import ClientsDashboardPage from "../../features/clients/ClientsDashboard/ClientsDashboardPage";
import ManagerDashboardPage from "../../features/manager/ManagerDashboard/ManagerDashboardPage";
import StaffCalendarPage from "../../features/staff/StaffCalendar/StaffCalendarPage";

import WorkHomeLanding from "../../features/staff/WorkFromHome/WorkHomeLanding";

import AdminDashboardPage from "../../features/admin/AdminDashboard/AdminDashboardPage";
import AdminLoadLeaveTakeOnPage from "../../features/admin/AdminLoadLeave/AdminLoadLeaveTakeOnPage";
import AdminLoadLeavePage from "../../features/admin/AdminLoadLeave/AdminLoadLeavePage";

import PayslipsDashboardPage from "../../features/payslips/PayslipsDashboard/PayslipsDashboardPage";
import PayslipsBulkPage from "../../features/payslips/PayslipsBulk/PayslipsBulkPage";

import TimeReportPage from "../../features/staff/TimeReport/TimeReportPage";

import SettingsDashboard from "../../features/user/Settings/SettingsDashboard";
import TimeViewPage from "../../features/clients/TimeDashboard/TimeViewPage";

import ViewTimePage from "../../features/staff/TimeView/ViewTimePage";

import IRP5DashboardPage from "../../features/irp5/IRP5Dashboard/IRP5DashboardPage";
import IRP5BulkPage from "../../features/irp5/IRP5Dashboard/IRP5BulkPage";

import ReportsDashboardPage from "../../features/reports/ReportsDashboard/ReportsDashboardPage";
import ReportLeaveDuePage from "../../features/reports/ReportLeaveDue/ReportLeaveDuePage";
import ReportUserLeave from "../../features/reports/ReportLeaveDue/ReportUserLeave";
import ReportLongService from "../../features/reports/ReportLeaveDue/ReportLongService";
import LeaveActivity from "../../features/reports/ReportLeaveActivity/LeaveActivity";

import ReportTimeTrack from "../../features/reports/TimeTrackReport/ReportTimeTrack";
import ReportProjectTime from "../../features/reports/TimeTrackReport/ReportProjectTime";
import ReportTransaction from "../../features/reports/ReportTransaction";

import SupportDashboardPage from "../../features/support/SupportLanding/SupportDashboardPage";
import SupportViewPage from "../../features/support/SupportView/SupportViewPage";

import AddExpensePage from "../../features/staff/Expense/AddExpensePage";
import AllExpensePage from "../../features/staff/Expense/AllExpensePage";
import ExpenseViewPage from "../../features/staff/Expense/ExpenseViewPage";
import comparePage from "../../features/staff/LeaveList/ComparePage";

import NotFound from "./NotFound";
import Sandbox from "../../features/sandbox/sandbox";
import LineManagerReport from "../../features/reports/LineManagers/LineManagerReport";

export default function App() {
    const { initialized } = useSelector((state) => state.async);

    if (!initialized) return <LoadingComponent content="Loading app..." />;

    return (
        <>
            <ModalManager />
            <ToastContainer position="bottom-right" hideProgressBar />
            <Route exact path="/" component={LoginForm} />
            <Route
                path={"/(.+)"}
                render={() => (
                    <>
                        <NavBar />
                        <Container className="main">
                            <PrivateRoute path="/home/landing" component={HomePage} />
                            <PrivateRoute path="/dashboard/landing" component={HomePage} />
                            <PrivateRoute path="/home/:id?" component={HomePage} />

                            <PrivateRoute path="/manager" component={ManagerDashboardPage} />

                            <PrivateRoute path="/notifications" component={NotificationsDashboardPage} />
                            <PrivateRoute path="/staff/view/:id" component={LeaveViewPage} />
                            <PrivateRoute path="/clients/leaveview/:id" component={StaffViewPage} />

                            <PrivateRoute path="/clients/landing" component={ClientsDashboardPage} />
                            <PrivateRoute path="/clients/staff/:id" component={StaffDashboardPage} />

                            <PrivateRoute path="/clients/time/:clientId" component={TimeViewPage} />
                            <PrivateRoute path="/timetracking/view/:id" component={ViewTimePage} />
                            <PrivateRoute path="/timetracking/monthly_report/:id" component={TimeReportPage} />
                            <PrivateRoute path="/timetracking/input/:id?" component={TimeTrackPage} />
                            <PrivateRoute path="/timetracking/edit/:id/:id" component={TimeTrackPage} />
                            <PrivateRoute path="/timetracking/previous/:id?" component={TimeTrackPage} />
                            <PrivateRoute path="/timetracking/previous_edit/:id/:id" component={TimeTrackPage} />
                            <PrivateRoute path="/timetracking/outstanding" component={TimeTrackOutstanding} />

                            <PrivateRoute path="/staffcalendar" component={StaffCalendarPage} />

                            <PrivateRoute path="/resources/:id?" component={ResourcesDashboardPage} />
                            <PrivateRoute path="/company/resources/:id?" component={CompanyResourcesDashboardPage} />

                            <PrivateRoute path="/account" component={AccountPage} />
                            <PrivateRoute path="/profile/:id" component={ProfilePage} />

                            <PrivateRoute path="/admin/landing" component={AdminDashboardPage} />
                            <PrivateRoute path="/admin/loadleave/takeon/" component={AdminLoadLeaveTakeOnPage} />
                            <PrivateRoute path="/admin/loadleave/manual" component={AdminLoadLeavePage} />

                            <PrivateRoute path="/payslips/:id?" component={PayslipsDashboardPage} />
                            <PrivateRoute path="/payslips_bulk" component={PayslipsBulkPage} />

                            <PrivateRoute path="/irp5/:id?" component={IRP5DashboardPage} />
                            <PrivateRoute path="/irp5_bulk" component={IRP5BulkPage} />

                            <PrivateRoute path="/reports/landing" component={ReportsDashboardPage} />
                            <PrivateRoute path="/reports/leave_due" component={ReportLeaveDuePage} />
                            <PrivateRoute path="/reports/leave_user" component={ReportUserLeave} />
                            <PrivateRoute path="/reports/long_service" component={ReportLongService} />
                            <PrivateRoute path="/reports/leave_activity" component={LeaveActivity} />
                            <PrivateRoute path="/reports/timetrack" component={ReportTimeTrack} />
                            <PrivateRoute path="/reports/projects/timetrack" component={ReportProjectTime} />
                            <PrivateRoute path="/reports/transaction" component={ReportTransaction} />
                            <PrivateRoute path="/reports/line_managers" component={LineManagerReport} />

                            <PrivateRoute path="/support/landing" component={SupportDashboardPage} />
                            <PrivateRoute path="/support/view/:id" component={SupportViewPage} />

                            <PrivateRoute path="/settings" component={SettingsDashboard} />
                            {/* <PrivateRoute path="/settings/basic/:id" component={SettingsDashboard} /> */}

                            <PrivateRoute path="/super/landing" component={SuperPage} />
                            <PrivateRoute path="/super/users" component={UsersPage} />
                            <PrivateRoute path="/super/user/:id" component={UsersDetailedPage} />
                            <PrivateRoute path="/super/departments" component={DepartmentsPage} />
                            <PrivateRoute path="/super/department/:id" component={DepartmentsDetailedPage} />
                            <PrivateRoute path="/super/projects" component={ProjectsPage} />
                            <PrivateRoute path="/super/project/:id" component={ProjectsDetailedPage} />
                            <PrivateRoute path="/super/sites" component={SitesPage} />
                            <PrivateRoute path="/super/site/:id" component={SitesDetailedPage} />
                            <PrivateRoute path="/super/companies" component={CompaniesPage} />
                            <PrivateRoute path="/super/company/:id" component={CompaniesDetailedPage} />
                            <PrivateRoute path="/super/blocked_dates" component={BlockedDatesPage} />

                            <PrivateRoute path="/not-found" component={NotFound} />
                            <PrivateRoute path="/sandbox" component={Sandbox} />

                            <PrivateRoute path="/compare/:id" component={comparePage} />

                            <PrivateRoute path="/staff/home/:id" component={WorkHomeLanding} />

                            <PrivateRoute path="/not-found" component={NotFound} />

                            <Route path="/expense/add/:id?" component={AddExpensePage} />
                            <Route path="/expenses/view/all/:id?" component={AllExpensePage} />
                            <Route path="/expense/view/:id" component={ExpenseViewPage} />
                        </Container>
                    </>
                )}
            />
        </>
    );
}
