import React from "react";
import { useField } from "formik";
import { FormField, Label, Grid } from "semantic-ui-react";

export default function MyRadioButton({ label, labelcb, ...props }) {
    const [field, meta] = useField(props);

    return (
        <FormField error={meta.touched && !!meta.error}>
            <Grid verticalAlign="middle">
                <Grid.Row>
                    {label && label.length > 0 && (
                        <Grid.Column width={5}>
                            <label>
                                <strong>{label}</strong>
                            </label>
                        </Grid.Column>
                    )}

                    <Grid.Column width={label && label.length > 0 ? 11 : 16}>
                        <FormField error={meta.touched && !!meta.error}>
                            <div className="ui radio">
                                <input {...field} {...props} />
                                <label>{labelcb}</label>
                            </div>

                            {meta.touched && meta.error ? (
                                <Label basic color="red">
                                    {meta.error}
                                </Label>
                            ) : null}
                        </FormField>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </FormField>
    );
}
