import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Segment, Label, Button, Comment, Form, Table } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import { deleteExpense, expenseComment, multiExpenseApprove } from "../../manager/managerActions";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { Formik } from "formik";
import ReactExport from "react-data-export";

import firebase from "../../../app/config/firebase";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyRadioButton from "../../../app/common/form/MyRadioButton";
const firestore = firebase.firestore();

export default function ExpenseViewPage(props) {
    let url = window.location.href.split("/");
    let lastSegment = url.pop() || url.pop();
    const { currentUserProfile } = useSelector((state) => state.profile);
    const dispatch = useDispatch();

    const [expense, setExpense] = useState({});
    const [expenseComments, setExpenseComments] = useState([]);
    const [profileClient, setProfileClient] = useState({});
    const [clientView, setClientView] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [travel, setTravel] = useState([]);
    const [fuel, setFuel] = useState([]);
    const [nonTax, setNonTax] = useState([]);
    const [tax, setTax] = useState([]);
    const [allTravel, setAlltravel] = useState(false);
    const [allFuel, setAllFuel] = useState(false);
    const [allNontax, setAllNontax] = useState(false);
    const [allTax, setAllTax] = useState(false);
    const [type, setType] = useState("");
    const [date, setDate] = useState("");
    const [edit, setEdit] = useState(false);
    const [downloadExp, setDownloadExp] = useState([]);
    const [deny, setDeny] = useState([]);
    const [approve, setApprove] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    useEffect(() => {
        const unsubscribe3 = firestore
            .collection("expenses")
            .doc(lastSegment)
            .onSnapshot((snapshot) => {
                setType(snapshot.data().status);

                setDate(format(snapshot.data().fromDate.toDate(), "MMMM yyyy"));

                if (snapshot.data().userUid !== currentUserProfile.id) {
                    setClientView(true);
                    setProfileClient(snapshot.data().userUid);
                }
            });

        const unsubscribe = firestore
            .collection("expenses")
            .doc(lastSegment)
            .collection("loaded")
            .onSnapshot((snapshot) => {
                let listTravel = [];
                let listFuel = [];
                let listNonTax = [];
                let listTax = [];
                let downloadList = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };

                        if (obj.category == "Travel") {
                            listTravel.push(obj);
                        } else if (obj.category == "Fuel") {
                            listFuel.push(obj);
                        } else if (obj.category == "Reimbursement non taxable") {
                            listNonTax.push(obj);
                        } else if (obj.category == "Reimbursement taxable") {
                            listTax.push(obj);
                        }

                        let dwnloadObj = { ...result.data(), id: result.id };

                        dwnloadObj.date = dwnloadObj.date ? format(dwnloadObj.date.toDate(), "yyyy-MM-dd") : "";
                        dwnloadObj.dateTimeFrom = dwnloadObj.dateTimeFrom ? format(dwnloadObj.dateTimeFrom.toDate(), "yyyy-MM-dd HH:mm") : "";
                        dwnloadObj.dateTimeTo = dwnloadObj.dateTimeTo ? format(dwnloadObj.dateTimeTo.toDate(), "yyyy-MM-dd HH:mm") : "";
                        dwnloadObj.locationFrom = dwnloadObj.locationFrom ? dwnloadObj.locationFrom : "";
                        dwnloadObj.locationTo = dwnloadObj.locationTo ? dwnloadObj.locationTo : "";
                        dwnloadObj.odometerFrom = dwnloadObj.odometerFrom ? dwnloadObj.odometerFrom : "";
                        dwnloadObj.odometerTo = dwnloadObj.odometerTo ? dwnloadObj.odometerTo : "";

                        downloadList.push(dwnloadObj);
                    }
                });
                setTravel(listTravel);
                setFuel(listFuel);
                setNonTax(listNonTax);
                setTax(listTax);
                setDownloadExp(downloadList);
            });

        const unsubscribe2 = firestore
            .collection("expenses")
            .doc(lastSegment)
            .collection("comments")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setExpenseComments(list);
            });

        return () => {
            unsubscribe();
            unsubscribe2();
            unsubscribe3();
        };
    }, []);

    function changeTravelAll(value) {
        setAlltravel(value);
    }
    function changeFuelAll(value) {
        setAllFuel(value);
    }
    function changeNonTaxAll(value) {
        setAllNontax(value);
    }
    function changeTaxAll(value) {
        setAllTax(value);
    }

    const sumTotal = (arr) => arr.reduce((sum, { amount }) => sum + parseFloat(amount), 0);

    function editPage() {
        setEdit(true);
        setType("Pending");
    }

    return (
        <>
            <h2 style={{ textAlign: "center" }}>{edit === false ? type : "Editing"}</h2>
            <p>{date}</p>

            {downloadExp && downloadExp.length > 0 && (
                <ExcelFile element={<Button color="blue">DOWNLOAD</Button>}>
                    <ExcelSheet data={downloadExp} name="Expenses">
                        <ExcelColumn label="Date" value="date" />
                        <ExcelColumn label="Type" value="type" />
                        <ExcelColumn label="Category" value="category" />
                        <ExcelColumn label="Amount" value="amount" />
                        <ExcelColumn label="Description" value="description" />
                        <ExcelColumn label="Time From" value="dateTimeFrom" />
                        <ExcelColumn label="Time To" value="dateTimeTo" />
                        <ExcelColumn label="Location From" value="locationFrom" />
                        <ExcelColumn label="Location To" value="locationTo" />
                        <ExcelColumn label="Odometer From" value="odometerFrom" />
                        <ExcelColumn label="Odometer To" value="odometerTo" />
                    </ExcelSheet>
                </ExcelFile>
            )}

            <Formik
                initialValues={{}}
                enableReinitialize
                validate={(values) => {
                    const errors = {};

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values.row = lastSegment;
                    values.edit = edit;

                    Object.keys(travel).forEach(async function (key) {
                        if (edit === true) {
                            if (!deny.includes(travel[key].id)) {
                                var newKey = travel[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        } else {
                            if (!deny.includes(travel[key].id) && approve.includes(travel[key].id)) {
                                var newKey = travel[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        }
                    });

                    Object.keys(fuel).forEach(async function (key) {
                        if (edit === true) {
                            if (!deny.includes(fuel[key].id)) {
                                var newKey = fuel[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        } else {
                            if (!deny.includes(fuel[key].id) && approve.includes(fuel[key].id)) {
                                var newKey = fuel[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        }
                    });

                    Object.keys(nonTax).forEach(async function (key) {
                        if (edit === true) {
                            if (!deny.includes(nonTax[key].id)) {
                                var newKey = nonTax[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        } else {
                            if (!deny.includes(nonTax[key].id) && approve.includes(nonTax[key].id)) {
                                var newKey = nonTax[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        }
                    });

                    Object.keys(allTax).forEach(async function (key) {
                        if (edit === true) {
                            if (!deny.includes(allTax[key].id)) {
                                var newKey = allTax[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        } else {
                            if (!deny.includes(allTax[key].id) && approve.includes(allTax[key].id)) {
                                var newKey = allTax[key].id;
                                var obj = {};
                                obj[newKey] = true;

                                values.check = { ...values.check, ...obj };
                            }
                        }
                    });

                    values.deny = deny;

                    await multiExpenseApprove(values);
                    setSubmitting(false);

                    setTimeout(function () {
                        if (clientView === false) {
                            window.location.replace("/expenses/view/all/");
                        } else {
                            window.location.replace(`expenses/view/all/${profileClient}`);
                        }
                    }, 3000);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <br />
                        <Grid inline="true">
                            <Grid.Column width={8}>
                                {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                    <Button type="submit" loading={isSubmitting} disabled={isSubmitting} color="blue" size="large">
                                        Approve
                                    </Button>
                                ) : null}
                                {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Approved" ? (
                                    <Button type="button" color="yellow" size="large" onClick={() => editPage()}>
                                        Edit
                                    </Button>
                                ) : null}
                            </Grid.Column>
                            <Grid.Column width={8}>
                                {clientView === false ? (
                                    <Link as="a" to={`/expenses/view/all/`}>
                                        <Button size="large" style={{ float: "right" }}>
                                            Back
                                        </Button>
                                    </Link>
                                ) : (
                                    <Link as="a" to={`expenses/view/all/${profileClient}`}>
                                        <Button size="large" style={{ float: "right" }}>
                                            Back
                                        </Button>
                                    </Link>
                                )}
                            </Grid.Column>
                        </Grid>
                        <br />
                        {travel && travel.length > 0 && (
                            <Table celled>
                                <Table.Header>
                                    <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                        <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                                            <b>TRAVEL</b>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {travel &&
                                        travel.length > 0 &&
                                        allTravel === false &&
                                        travel.map((expenseTravel, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                name={`check.${expenseTravel.id}`}
                                                                value="true"
                                                                className="ui checkbox"
                                                                checked={values.check === undefined ? expenseTravel.status === "Approved" : values.checkbox}
                                                                onChange={(value) => {
                                                                    setFieldValue(`check.${expenseTravel.id}`, value.target.checked);

                                                                    if (value.target.checked === false) {
                                                                        let newDeny = [deny, expenseTravel.id].flat();
                                                                        setDeny(newDeny);
                                                                    } else {
                                                                        let newApprove = [approve, expenseTravel.id].flat();
                                                                        setApprove(newApprove);
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    ) : null}
                                                    {type == "Approved" ? <>{expenseTravel.status == "Approved" ? <>&#9989;</> : <>&#10060;</>}</> : null}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {expenseTravel.dateTimeFrom && expenseTravel.dateTimeFrom !== undefined
                                                        ? format(expenseTravel.dateTimeFrom.toDate(), "dd MMM yyyy HH:mm") + " - "
                                                        : null}
                                                    {expenseTravel.dateTimeTo && expenseTravel.dateTimeTo !== undefined ? format(expenseTravel.dateTimeTo.toDate(), "dd MMM yyyy HH:mm") : null}
                                                    <br />
                                                    <b>Location:</b> {expenseTravel.locationFrom && expenseTravel.locationFrom !== undefined ? expenseTravel.locationFrom + " - " : null}
                                                    {expenseTravel.locationTo && expenseTravel.locationTo !== undefined ? expenseTravel.locationTo : null}
                                                    <br />
                                                    <b>Odometer:</b> {expenseTravel.odometerFrom && expenseTravel.odometerFrom !== undefined ? expenseTravel.odometerFrom + " - " : null}
                                                    {expenseTravel.odometerTo && expenseTravel.odometerTo !== undefined ? expenseTravel.odometerTo : null}
                                                    <br />
                                                    {expenseTravel.description && expenseTravel.description !== undefined ? expenseTravel.description : null}
                                                    {expenseTravel.fileUrl && expenseTravel.fileUrl !== "" && expenseTravel.fileUrl !== undefined ? (
                                                        <Button color="blue" size="tiny" as="a" target="_blank" href={expenseTravel.fileUrl} style={{ float: "right" }}>
                                                            View File
                                                        </Button>
                                                    ) : null}
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>R {expenseTravel.amount && expenseTravel.amount !== undefined ? expenseTravel.amount : null}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    <Table.Row>
                                        <Table.Cell style={{ width: "50px" }}>
                                            {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                <input
                                                    type="checkbox"
                                                    name="alltravel"
                                                    value="true"
                                                    className="ui checkbox"
                                                    onChange={(value) => {
                                                        {
                                                            changeTravelAll(value.target.checked);
                                                            setFieldValue("alltravel", value.target.checked);
                                                            Object.keys(travel).forEach(function (key) {
                                                                setFieldValue(`check.${travel[key].id}`, value.target.checked);
                                                            });
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>All Travel expenses</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ width: "200px" }}>
                                            <b>TOTAL: </b> R {sumTotal(travel)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )}

                        {fuel && fuel.length > 0 && (
                            <Table celled>
                                <Table.Header>
                                    <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                        <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                                            <b>FUEL</b>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {fuel &&
                                        fuel.length > 0 &&
                                        allFuel === false &&
                                        fuel.map((expenseFuel, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                        <input
                                                            type="checkbox"
                                                            name={`check.${expenseFuel.id}`}
                                                            value="true"
                                                            className="ui checkbox"
                                                            checked={values.check === undefined ? expenseFuel.status === "Approved" : values.checkbox}
                                                            onChange={(value) => {
                                                                setFieldValue(`check.${expenseFuel.id}`, value.target.checked);
                                                                if (value.target.checked === false) {
                                                                    let newDeny = [deny, expenseFuel.id].flat();
                                                                    setDeny(newDeny);
                                                                } else {
                                                                    let newApprove = [approve, expenseFuel.id].flat();
                                                                    setApprove(newApprove);
                                                                }
                                                            }}
                                                        />
                                                    ) : null}
                                                    {type == "Approved" ? <>{expenseFuel.status == "Approved" ? <>&#9989;</> : <>&#10060;</>}</> : null}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {expenseFuel.dateTimeFrom && expenseFuel.dateTimeFrom !== undefined ? format(expenseFuel.dateTimeFrom.toDate(), "dd MMM yyyy HH:mm") + " - " : null}
                                                    {expenseFuel.dateTimeTo && expenseFuel.dateTimeTo !== undefined ? format(expenseFuel.dateTimeTo.toDate(), "dd MMM yyyy HH:mm") : null}
                                                    <br />
                                                    <b>Location:</b> {expenseFuel.locationFrom && expenseFuel.locationFrom !== undefined ? expenseFuel.locationFrom + " - " : null}
                                                    {expenseFuel.locationTo && expenseFuel.locationTo !== undefined ? expenseFuel.locationTo : null}
                                                    <br />
                                                    <b>Odometer:</b> {expenseFuel.odometerFrom && expenseFuel.odometerFrom !== undefined ? expenseFuel.odometerFrom + " - " : null}
                                                    {expenseFuel.odometerTo && expenseFuel.odometerTo !== undefined ? expenseFuel.odometerTo : null}
                                                    <br />
                                                    {expenseFuel.description && expenseFuel.description !== undefined ? expenseFuel.description : null}
                                                    {expenseFuel.fileUrl && expenseFuel.fileUrl !== "" && expenseFuel.fileUrl !== undefined ? (
                                                        <Button color="blue" size="tiny" as="a" target="_blank" href={expenseFuel.fileUrl} style={{ float: "right" }}>
                                                            View File
                                                        </Button>
                                                    ) : null}
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>R {expenseFuel.amount && expenseFuel.amount !== undefined ? expenseFuel.amount : null}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    <Table.Row>
                                        <Table.Cell style={{ width: "50px" }}>
                                            {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                <input
                                                    type="checkbox"
                                                    name="allfuel"
                                                    value="true"
                                                    className="ui checkbox"
                                                    onChange={(value) => {
                                                        {
                                                            changeFuelAll(value.target.checked);
                                                            setFieldValue("allfuel", value.target.checked);
                                                            Object.keys(fuel).forEach(function (key) {
                                                                setFieldValue(`check.${fuel[key].id}`, value.target.checked);
                                                            });
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>All Fuel expenses</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ width: "200px" }}>
                                            <b>TOTAL: </b> R {sumTotal(fuel)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )}

                        {nonTax && nonTax.length > 0 && (
                            <Table celled>
                                <Table.Header>
                                    <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                        <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                                            <b>REIMBURSEMENT NON TAXABLE</b>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {nonTax &&
                                        nonTax.length > 0 &&
                                        allNontax == false &&
                                        nonTax.map((expenseNonTax, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                        <input
                                                            type="checkbox"
                                                            name={`check.${expenseNonTax.id}`}
                                                            value="true"
                                                            className="ui checkbox"
                                                            checked={values.check === undefined ? expenseNonTax.status === "Approved" : values.checkbox}
                                                            onChange={(value) => {
                                                                setFieldValue(`check.${expenseNonTax.id}`, value.target.checked);
                                                                if (value.target.checked === false) {
                                                                    let newDeny = [deny, expenseNonTax.id].flat();
                                                                    setDeny(newDeny);
                                                                } else {
                                                                    let newApprove = [approve, expenseNonTax.id].flat();
                                                                    setApprove(newApprove);
                                                                }
                                                            }}
                                                        />
                                                    ) : null}
                                                    {type == "Approved" ? <>{expenseNonTax.status == "Approved" ? <>&#9989;</> : <>&#10060;</>}</> : null}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {expenseNonTax.date && expenseNonTax.date !== undefined ? format(expenseNonTax.date.toDate(), "dd MMMM yyyy") : null}
                                                    <br />
                                                    {expenseNonTax.description && expenseNonTax.description !== undefined ? expenseNonTax.description : null}
                                                    {expenseNonTax.fileUrl && expenseNonTax.fileUrl !== "" && expenseNonTax.fileUrl !== undefined ? (
                                                        <Button color="blue" size="tiny" as="a" target="_blank" href={expenseNonTax.fileUrl} style={{ float: "right" }}>
                                                            View File
                                                        </Button>
                                                    ) : null}
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>R {expenseNonTax.amount && expenseNonTax.amount !== undefined ? expenseNonTax.amount : null}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    <Table.Row>
                                        <Table.Cell style={{ width: "50px" }}>
                                            {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                <input
                                                    type="checkbox"
                                                    name="allnonTax"
                                                    value="true"
                                                    className="ui checkbox"
                                                    onChange={(value) => {
                                                        {
                                                            changeNonTaxAll(value.target.checked);
                                                            setFieldValue("allnonTax", value.target.checked);
                                                            Object.keys(nonTax).forEach(function (key) {
                                                                setFieldValue(`check.${nonTax[key].id}`, value.target.checked);
                                                            });
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>Approve all Reimbursement non taxable expenses</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ width: "200px" }}>
                                            <b>TOTAL: </b> R {sumTotal(nonTax)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )}

                        {tax && tax.length > 0 && (
                            <Table celled>
                                <Table.Header>
                                    <Table.Row style={{ backgroundColor: "#f5f5f5" }}>
                                        <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                                            <b>REIMBURSEMENT TAXABLE</b>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {tax &&
                                        tax.length > 0 &&
                                        allTax === false &&
                                        tax.map((expenseTax, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell style={{ width: "50px" }}>
                                                    {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                        <input
                                                            type="checkbox"
                                                            name={`check.${expenseTax.id}`}
                                                            value="true"
                                                            className="ui checkbox"
                                                            checked={values.check === undefined ? expenseTax.status === "Approved" : values.checkbox}
                                                            onChange={(value) => {
                                                                setFieldValue(`check.${expenseTax.id}`, value.target.checked);
                                                                if (value.target.checked === false) {
                                                                    let newDeny = [deny, expenseTax.id].flat();
                                                                    setDeny(newDeny);
                                                                } else {
                                                                    let newApprove = [approve, expenseTax.id].flat();
                                                                    setApprove(newApprove);
                                                                }
                                                            }}
                                                        />
                                                    ) : null}
                                                    {type == "Approved" ? <>{expenseTax.status == "Approved" ? <>&#9989;</> : <>&#10060;</>}</> : null}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {expenseTax.date && expenseTax.date !== undefined ? format(expenseTax.date.toDate(), "dd MMMM yyyy") : null}
                                                    <br />
                                                    {expenseTax.description && expenseTax.description !== undefined ? expenseTax.description : null}
                                                    {expenseTax.fileUrl && expenseTax.fileUrl !== "" && expenseTax.fileUrl !== undefined ? (
                                                        <Button color="blue" size="tiny" as="a" target="_blank" href={expenseTax.fileUrl} style={{ float: "right" }}>
                                                            View File
                                                        </Button>
                                                    ) : null}
                                                </Table.Cell>
                                                <Table.Cell style={{ width: "200px" }}>R {expenseTax.amount && expenseTax.amount !== undefined ? expenseTax.amount : null}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    <Table.Row>
                                        <Table.Cell style={{ width: "50px" }}>
                                            {(currentUserProfile.isAdmin === true || currentUserProfile.isSuper === true) && type === "Pending" ? (
                                                <input
                                                    type="checkbox"
                                                    name="allTax"
                                                    value="true"
                                                    className="ui checkbox"
                                                    onChange={(value) => {
                                                        {
                                                            changeTaxAll(value.target.checked);
                                                            setFieldValue("allTax", value.target.checked);
                                                            Object.keys(tax).forEach(function (key) {
                                                                setFieldValue(`check.${tax[key].id}`, value.target.checked);
                                                            });
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <b>All Reimbursement non taxable expenses</b>
                                        </Table.Cell>
                                        <Table.Cell style={{ width: "200px" }}>
                                            <b>TOTAL: </b> R {sumTotal(tax)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )}

                        <br />
                    </form>
                )}
            </Formik>
        </>
    );
}
