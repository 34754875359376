import React from "react";
import { useField } from "formik";
import { FormField, Label, Select, Grid } from "semantic-ui-react";

export default function MySelectInput({ label, labelTop, ...props }) {
    const [field, meta, helpers] = useField(props);
    return (
        <Grid verticalAlign="middle">
            <Grid.Row>
                {label && label.length > 0 && (
                    <Grid.Column width={5}>
                        <label>
                            <strong>{label}</strong>
                        </label>
                    </Grid.Column>
                )}

                <Grid.Column width={label && label.length > 0 ? 11 : 16}>
                    <FormField error={meta.touched && !!meta.error}>
                        {labelTop && labelTop.length > 0 && <label>{labelTop}</label>}

                        <Select
                            clearable
                            search={true}
                            value={field.value || null}
                            onChange={(e, d) => helpers.setValue(d.value)}
                            onBlur={() => helpers.setTouched(true)}
                            {...props}
                        />
                        {meta.touched && meta.error ? (
                            <Label basic color="red">
                                {meta.error}
                            </Label>
                        ) : null}
                    </FormField>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
