import React, { useState, useEffect } from "react";
import { Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import LeaveHomeFormBtn from "./LeaveHomeFormBtn";
import { getClientTime, getClientLeave } from "../managerActions";
import firebase from "../../../app/config/firebase";
import { getAuthUser } from "../managerActions";
import axios from "axios";
import { Button } from "semantic-ui-react";
import apiURL from "../../../app/config/apiURL";

const firestore = firebase.firestore();

export default function LeaveHomeForm(props) {
    const [leave, setLeave] = useState([]);
    const [time, setTime] = useState([]);
    const [allLeave, setAllLeave] = useState([]);
    const [timePending, setTimePending] = useState([]);
    const [remote, setRemote] = useState([]);

    useEffect(() => {
        getAuthUser().then(
            async (res) => {
                if (res.exists) {
                    const response = await axios.post(apiURL + "/api/get_notification_time", { profile: { ...res.data(), id: res.id } });
                    setTimePending(response.data);
                }
            },
            (err) => {
                console.log(err);
            }
        );
        const unsubscribe = firestore
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot(async (result) => {
                let userInfo = [];
                userInfo = { ...result.data(), id: result.id };

                let leaveList = [];

                await firestore
                    .collection("leave")
                    .where("companyId", "==", userInfo.companyId)
                    .where("departmentId", "in", userInfo.departments.length > 0 ? userInfo.departments : ["None"])
                    .where("status", "==", "Pending")
                    .onSnapshot((snapshot) => {
                        let list = [];
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let obj = { ...doc.data(), id: doc.id };
                                if (obj.negativeEscalated === undefined) {
                                    leaveList.push(obj);
                                }
                            }
                        });
                    });

                // console.log("2");

                await firestore
                    .collection("leave")
                    .where("companyId", "==", userInfo.companyId)
                    .where("approvalType", "==", "Specific User")
                    .where("approvalUser", "==", userInfo.id ? userInfo.id : ["None"])
                    .where("status", "==", "Pending")
                    .onSnapshot((snapshot) => {
                        let list = [];
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let obj = { ...doc.data(), id: doc.id };
                                if (obj.negativeEscalated === undefined) {
                                    leaveList.push(obj);
                                }
                            }
                        });
                    });
                await firestore
                    .collection("leave")
                    .where("companyId", "==", userInfo.companyId)
                    .where("specificApprovalHRUser", "==", userInfo.id ? userInfo.id : ["None"])
                    .where("negativeRequested", "==", true)
                    .where("status", "==", "Pending")
                    .where("negativeEscalated", "==", true)
                    .get()
                    .then(
                        (res) => {
                            res.forEach((doc) => {
                                if (doc.exists) {
                                    let appObj = { ...doc.data(), id: doc.id };
                                    leaveList.push(appObj);
                                }
                            });

                        },
                        (err) => {
                            console.log(err);
                        }
                    );

                const someTimeoutAction = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve(leaveList);
                        }, 2000);
                    });
                };

                const status = await someTimeoutAction();
                setLeave(status);

                let remoteList = [];

                await firestore
                    .collection("remoteWork")
                    .where("companyId", "==", userInfo.companyId)
                    .where("approvalUser", "==", userInfo.id ? userInfo.id : ["None"])
                    .where("status", "==", "Pending")
                    .onSnapshot((snapshot) => {
                        let list = [];
                        snapshot.docs.forEach((result) => {
                            if (result.exists) {
                                let obj = { ...result.data(), id: result.id };
                                list.push(obj);
                            }
                        });
                        remoteList.push(list);
                    });

                await firestore
                    .collection("remoteWork")
                    .where("approvalType", "==", "Line Manager/s in Department")
                    .where("companyId", "==", userInfo.companyId)
                    .where("departmentId", "in", userInfo.departments.length > 0 ? userInfo.departments : ["None"])
                    .where("status", "==", "Pending")
                    .onSnapshot((snapshot) => {
                        let list = [];
                        snapshot.docs.forEach((result) => {
                            if (result.exists) {
                                let obj = { ...result.data(), id: result.id };
                                list.push(obj);
                            }
                        });
                        remoteList.push(list);
                    });

                const someTimeoutAction2 = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve(remoteList.flat());
                        }, 2000);
                    });
                };

                const statusRemote = await someTimeoutAction2();
                setRemote(statusRemote);
            });

        // getClientTime().then(
        //     (res) => {
        //         setTime(res);
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );
        getClientLeave().then(
            (res) => {
                setAllLeave(res);
            },
            (err) => {
                console.log(err);
            }
        );

        return () => unsubscribe();
    }, []);

    // console.log(timePending);

    return (
        <Segment>
            <h2>Requests for Approval</h2>
            <p> View and submit pending applications now.</p>

            <div className="img">
                {leave && leave.length > 0 && (
                    <div>
                        <img src="/assets/leave_request.png" alt="Leave Request" />
                        <p className="no_m">
                            <strong>{leave[0].displayName}</strong>
                            <br />
                            {leave[0] && leave[0].annualFrom && format(leave[0].annualFrom.toDate(), "d MMMM yyyy")} -{" "}
                            {leave[0] && leave[0].annualTo && format(leave[0].annualTo.toDate(), "d MMMM yyyy")}
                            <br />
                            {leave[0].leaveType}
                        </p>

                        <LeaveHomeFormBtn leave={leave[0]} />
                    </div>
                )}
                {timePending && timePending.length > 0 && leave.length == 0 && (
                    <div>
                        <img src="/assets/leave_request.png" alt="Leave Request" />
                        <p className="no_m">
                            <strong>{timePending[0].displayName}</strong>
                            <br />
                            {timePending[0] && timePending[0].minDate && format(new Date(timePending[0].minDate), "d MMMM yyyy")} -{" "}
                            {timePending[0] && timePending[0].maxDate && format(new Date(timePending[0].maxDate), "d MMMM yyyy")}
                            <br />
                            Time Tracking Request
                        </p>

                        <Link as="a" to={`/timetracking/view/` + timePending[0].id}>
                            <Button color="orange">VIEW REQUEST</Button>
                        </Link>
                    </div>
                )}
                {remote && remote.length > 0 && timePending == 0 && leave.length == 0 && (
                    <div>
                        <img src="/assets/leave_request.png" alt="Leave Request" />
                        <p className="no_m">
                            <strong>{remote[0].displayName}</strong>
                            <br />
                            {remote[0] && remote[0].dateFrom && format(new Date(remote[0].dateFrom.toDate()), "d MMMM yyyy")} - <br />
                            remote Working Requst
                        </p>

                        <Link as="a" to={`/staff/home/` + remote[0].id}>
                            <Button color="orange">VIEW REQUEST</Button>
                        </Link>
                    </div>
                )}
            </div>

            <div className="center">
                <p>
                    ({allLeave.length + timePending.length + remote.length}) more requests pending
                    <br />
                    <span className="bluetxt">
                        <Link as="a" to="/manager">
                            View all requests here.
                        </Link>
                    </span>
                </p>
            </div>
        </Segment>
    );
}
