import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Divider, Form, Grid, GridRow, GridColumn, Segment } from "semantic-ui-react";
import { createDepartment, updateDepartment, getAuthUser, getCompany, getDepartment } from "../superActions";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function DepartmentsDetailedPage(props) {
    let parts = window.location.href.split("/");
    let departmentId = parts.pop() || parts.pop();
    const [profile, setProfile] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [department, setDepartment] = useState({ companyId: "", departmentName: "" });

    useEffect(() => {
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    setProfile(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getDepartment(departmentId).then(
            (res) => {
                if (res.exists) {
                    setDepartment(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getCompany().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let companyList = [];
                companyList = list.map(function (company) {
                    return {
                        key: company.id,
                        text: company.companyName,
                        value: company.id,
                    };
                });

                companyList.sort((a, b) => (b.text < a.text ? 1 : -1));

                setCompanyList(companyList);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);
    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={department}
                    enableReinitialize="true"
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (departmentId !== "0") {
                            values.id = departmentId;
                            await updateDepartment(values);
                        } else {
                            await createDepartment(values);
                        }

                        props.history.push("/super/departments/");
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <GridRow>
                                    <GridColumn width={8}>
                                        <Form.Field>
                                            <MySelectInput
                                                width={8}
                                                label="Company"
                                                name="companyId"
                                                options={companyList}
                                                value={values.companyId}
                                                onChange={(field, value) => setFieldValue("companyId", value.value)}
                                                placeholder="Select your Company"
                                                disabled={profile.isSuper === true ? false : true}
                                            />
                                        </Form.Field>
                                    </GridColumn>

                                    <GridColumn width={8}>
                                        <Form.Field>
                                            <MyTextInput label="Department Name" name="departmentName" type="text" placeholder="Department Name" />
                                        </Form.Field>
                                    </GridColumn>
                                </GridRow>
                            </Grid>

                            <Divider />

                            <Button
                                disabled={!dirty || isSubmitting}
                                loading={isSubmitting}
                                size="large"
                                type="submit"
                                positive
                                content={departmentId !== "0" ? "Update Department" : "Create Department"}
                            />
                            <Button size="large" as={Link} to={`/super/departments/`}>
                                Cancel
                            </Button>
                        </form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}
