import React from "react";
import { ListItem, Segment, Grid, Label } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function TimeTrackListItem({ timetrack, profileClient }) {
    const dispatch = useDispatch();
    return (
        <ListItem>
            {timetrack.status !== "In Progress" && timetrack.status !== "Progress Edit" && timetrack.status !== "In Progress Previous" ? (
                <Segment>
                    <Link as="a" to={"/timetracking/view/" + timetrack.id}>
                        <Grid>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <div className="header">
                                        {timetrack.minDate
                                            ? format(new Date(timetrack.minDate), "d MMMM yyyy") + " - " + format(new Date(timetrack.maxDate), "d MMMM yyyy")
                                            : format(new Date(timetrack.created_at), "d MMMM yyyy")}
                                    </div>
                                    {timetrack.description}
                                </Grid.Column>

                                <Grid.Column width={4} style={{ color: "black" }}>
                                    Hours: {timetrack.hoursTotal}
                                    <br />
                                    Days: {timetrack.daysCount}
                                </Grid.Column>

                                <Grid.Column width={4} textAlign="right">
                                    {timetrack.status === "Approved" && <Label color="green">{timetrack.status}</Label>}
                                    {timetrack.status === "Denied" && <Label color="red">{timetrack.status}</Label>}
                                    {(timetrack.status === "Pending" || timetrack.status === "Pending Change") && <Label color="orange">{timetrack.status}</Label>}
                                    {timetrack.status === "Cancelled" && <Label color="grey">{timetrack.status}</Label>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Link>
                </Segment>
            ) : null}

            {timetrack.status === "In Progress" ? (
                <Link as={Link} to={"/timetracking/input/" + profileClient.id} params={{ testvalue: "hello" }}>
                    <Segment>
                        <Grid>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <div className="header">
                                        {timetrack.minDate
                                            ? format(new Date(timetrack.minDate), "d MMMM yyyy") + " - " + format(new Date(timetrack.maxDate), "d MMMM yyyy")
                                            : format(new Date(timetrack.created_at), "d MMMM yyyy")}{" "}
                                        (CURRENT)
                                    </div>
                                    {timetrack.description}
                                </Grid.Column>

                                <Grid.Column width={4} style={{ color: "black" }}>
                                    Hours: {timetrack.hoursTotal}
                                    <br />
                                    Days: {timetrack.daysCount}
                                </Grid.Column>

                                <Grid.Column width={4} textAlign="right">
                                    {timetrack.status === "In Progress" && <Label color="blue">{timetrack.status}</Label>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Link>
            ) : null}

            {timetrack.status === "Progress Edit" ? (
                <Link as={Link} to={"/timetracking/edit/" + profileClient.id + "/" + timetrack.id}>
                    <Segment>
                        <Grid>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <div className="header">
                                        {timetrack.minDate
                                            ? format(new Date(timetrack.minDate), "d MMMM yyyy") + " - " + format(new Date(timetrack.maxDate), "d MMMM yyyy")
                                            : format(new Date(timetrack.created_at), "d MMMM yyyy")}
                                    </div>
                                    {timetrack.description}
                                </Grid.Column>

                                <Grid.Column width={4} style={{ color: "black" }}>
                                    Hours: {timetrack.hoursTotal}
                                    <br />
                                    Days: {timetrack.daysCount}
                                </Grid.Column>

                                <Grid.Column width={4} textAlign="right">
                                    {timetrack.status === "Progress Edit" && <Label color="blue">In Progress</Label>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Link>
            ) : null}

            {timetrack.status === "In Progress Previous" ? (
                <Link as={Link} to={"/timetracking/previous_edit/" + profileClient.id + "/" + timetrack.id}>
                    <Segment>
                        <Grid>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <div className="header">
                                        {timetrack.minDate
                                            ? format(new Date(timetrack.minDate), "d MMMM yyyy") + " - " + format(new Date(timetrack.maxDate), "d MMMM yyyy")
                                            : format(new Date(timetrack.created_at), "d MMMM yyyy")}
                                    </div>
                                    {timetrack.description}
                                </Grid.Column>

                                <Grid.Column width={4} style={{ color: "black" }}>
                                    Hours: {timetrack.hoursTotal}
                                    <br />
                                    Days: {timetrack.daysCount}
                                </Grid.Column>

                                <Grid.Column width={4} textAlign="right">
                                    {timetrack.status === "In Progress Previous" && <Label color="blue">In Progress</Label>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Link>
            ) : null}
        </ListItem>
    );
}
