import cuid from "cuid";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore();

export async function uploadResource(resource) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    try {
        let path = ``;
        if (resource.documentFile === "company") {
            path = `${user.uid}/company_resources/`;
        } else {
            path = `${user.uid}/resources/`;
        }

        const options = {
            name: cuid() + "-" + resource.uploadFile.name,
        };

        const file = resource.uploadFile;

        let new_resource = {};
        new_resource.fileName = resource.uploadFile.name;
        new_resource.documentName = resource.documentName;
        new_resource.documentNameRand = options.name;
        new_resource.viewedBy = resource.viewedBy;

        if (resource.documentFile === "company") {
            new_resource.type = "company";
        }

        if (new_resource.viewedBy === "Company") {
            new_resource.companyId = resource.companyId;
        }

        if (new_resource.viewedBy === "User") {
            new_resource.userUid = resource.userUid;
        } else {
            new_resource.userUid = "None";
        }

        if (resource.viewedBy === undefined) {
            new_resource.viewedBy = "All";
        }

        new_resource.date = new Date();
        new_resource.version = 1;

        var storageRef = firebase.storage().ref();
        var uploadTask = await storageRef.child(path + options.name).put(file);
        let url = "";

        await uploadTask.ref.getDownloadURL().then((downloadURL) => {
            url = downloadURL;
        });

        new_resource.fileUrl = url;

        await firestore.collection("resources").add(new_resource);

        toast.success("Resource added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function deleteResource(resource) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;
    var storageRef = firebase.storage().ref();

    try {
        if (resource.documentFile === "company") {
            storageRef.child(`${user.uid}/company_resources/${resource.documentNameRand}`).getDownloadURL().then(onResolve, onReject);

            async function onResolve(foundURL) {
                await firebase.deleteFile(`${user.uid}/company_resources/${resource.documentNameRand}`);
                await firestore.collection("resources").doc(resource.id).delete();
            }

            async function onReject(error) {
                await firestore.collection("resources").doc(resource.id).delete();
            }
        } else {
            storageRef.child(`${user.uid}/resources/${resource.documentNameRand}`).getDownloadURL().then(onResolve, onReject);

            async function onResolve(foundURL) {
                await firebase.deleteFile(`${user.uid}/resources/${resource.documentNameRand}`);
                await firestore.collection("resources").doc(resource.id).delete();
            }

            async function onReject(error) {
                await firestore.collection("resources").doc(resource.id).delete();
            }
        }
    } catch (e) {
        console.log(e);
        toast.error("Problem deleting the photo");
    }
}

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getProfileClient(id) {
    if (id === undefined || id === "") {
        id = firebase.auth().currentUser.uid;
    }
    return firestore.collection("users").doc(id).get();
}

export async function getResourceUser(id) {
    if (id === undefined || id === "") {
        id = firebase.auth().currentUser.uid;
    }
    return firestore.collection("resources").where("userUid", "==", id).get();
}

export async function getResourceCompany(companyId) {
    return firestore.collection("resources").where("companyId", "==", companyId).where("userUid", "==", "None").get();
}
export async function getResourceAll() {
    return firestore.collection("resources").where("viewedBy", "==", "All").get();
}
export async function getUsers() {
    return firestore.collection("users").get();
}
export async function getCompanies() {
    return firestore.collection("companies").get();
}

export async function getResourceUserType(id) {
    if (id === undefined || id === "") {
        id = firebase.auth().currentUser.uid;
    }
    return firestore.collection("resources").where("userUid", "==", id).where("type", "==", "company").get();
}
export async function getResourceCompanyType(companyId) {
    return firestore.collection("resources").where("companyId", "==", companyId).where("userUid", "==", "None").where("type", "==", "company").get();
}
export async function getResourceAllType() {
    return firestore.collection("resources").where("viewedBy", "==", "All").where("type", "==", "company").get();
}
