import React, { useState, useEffect } from "react";
import { Container, Segment, Label, Button, Grid } from "semantic-ui-react";
import { leaveManualTrackV2, getUsers, getCompanies } from "../adminActions";
import { generateLeaveStats, clearProfileLeave } from "../../manager/managerActions";
import format from "date-fns/format";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function AdminLoadLeaveTakeOnPage(props) {
    const [takeOnDate, setTakeOnDate] = useState("");
    const [assignUsers, setAssignUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [accumulativeDays, setAccumulativeDays] = useState(false);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [FinancialStartMonth, setFinancialStartMonth] = useState("");
    const [leavePerMonth, setLeavePerMonth] = useState("");

    useEffect(() => {
        getCompanies().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                setCompanies(list);
            },
            (err) => {
                console.log(err);
            }
        );
        getUsers().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                setCompanyUsers(list);

                let assignUsers = [
                    {
                        key: "",
                        text: "Choose a User",
                        value: "",
                    },
                ];

                Object.keys(list).forEach(function (key) {
                    assignUsers.push({
                        key: list[key].id,
                        text: list[key].displayName && list[key].displayName.length > 0 ? list[key].companyName + " - " + list[key].displayName : list[key].companyName + " - " + list[key].email,
                        value: list[key].id,
                    });
                });

                setAssignUsers(assignUsers);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    function ChosenUser(value) {
        let userId = value.value;

        if (userId && userId !== "") {
            let leavePerMonth = 0;
            let yearStart = 0;
            let accumulativeDays = false;

            if (companyUsers && companies) {
                Object.keys(companyUsers).forEach(function (key) {
                    if (companyUsers[key].id === userId) {
                        leavePerMonth = 0;

                        Object.keys(companies).forEach(function (key2) {
                            if (companies[key2].id === companyUsers[key].companyId && companies[key2].leavePerMonth) {
                                leavePerMonth = companies[key2].leavePerMonth;
                                yearStart = companies[key2].yearStart;

                                if (companies[key2].leaveRolloverType === "Accumulative") {
                                    accumulativeDays = true;
                                }
                            }
                        });
                    }
                });
            }

            setLeavePerMonth(leavePerMonth);

            let startMonth = format(new Date(), "yyyy") + "-" + yearStart + "-1";
            startMonth = format(new Date(startMonth), "MMMM");

            setFinancialStartMonth(startMonth);

            setAccumulativeDays(accumulativeDays);
        }
    }

    function updateTakeOn(value) {
        setTakeOnDate(value);
    }

    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={{ description: "", leavePerMonth: "", FinancialStartMonth: "", annualDays: "", accumulativeDays: "", sickDays: "" }}
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        //First get this users profile and remove existing data
                        let profileClient = await clearProfileLeave(values, true);

                        if (profileClient !== false) {
                            //Generate all blank leave data
                            let years = [];

                            let dtUse = profileClient.createdAt;

                            //Check if this user has a Take On start date
                            if (profileClient.takeOnStartDate) {
                                dtUse = profileClient.takeOnStartDate;

                                //Check if this user has an employment start date
                            } else if (profileClient.employmentStartDate) {
                                dtUse = profileClient.employmentStartDate;
                            }

                            //Split up date
                            let startYear = format(dtUse.toDate(), "yyyy");
                            let startMonth = format(dtUse.toDate(), "M");
                            let yearNow = format(Date.now(), "yyyy");

                            //Default to Company Financial Year
                            let leaveYearStart = parseInt(profileClient.companyYearStart);

                            //Now check if the company has a different Leave Type set
                            if (profileClient.companyLeaveType && profileClient.companyLeaveType === "Anniversary Date of Employee") {
                                if (profileClient.employmentStartDate) {
                                    leaveYearStart = format(profileClient.employmentStartDate.toDate(), "M");
                                }
                            }

                            //Work out the financial year
                            if (startMonth < leaveYearStart) {
                                startYear--;
                            }

                            for (let i = startYear; i <= yearNow; i++) {
                                years.push(parseInt(i));
                            }

                            //Use reduce to run through each and generate stats
                            await years.reduce(async (promise, year) => {
                                await promise;

                                let date = new Date(year + "-" + leaveYearStart + "-01");

                                await generateLeaveStats(profileClient.id, "", date);
                            }, undefined);

                            //Create take on leave
                            await leaveManualTrackV2(values, profileClient);

                            await generateLeaveStats(values.userId, "Take On Balances Loaded!");
                        } else {
                            //
                        }
                        setSubmitting(false);
                    }}
                >
                    {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <label>Staff Member</label>
                                        <MySelectInput
                                            name="userId"
                                            options={assignUsers}
                                            value={values.userId}
                                            placeholder="Choose a User"
                                            onChange={(e, val) => {
                                                ChosenUser(val);
                                                setFieldValue("userId", val.value);
                                            }}
                                        />

                                        <label>Description</label>
                                        <MyTextInput name="description" />

                                        <label>Leave per Month</label>
                                        <MyTextInput name="leavePerMonth" value={leavePerMonth} readOnly={true} />

                                        <label>Financial Start Month</label>
                                        <MyTextInput name="FinancialStartMonth" value={FinancialStartMonth} readOnly={true} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Take On Date</label>
                                        <DatePicker
                                            name="takeOnDate"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            dropdownMode="select"
                                            value={values.takeOnDate}
                                            selected={takeOnDate}
                                            onChange={(value) => {
                                                updateTakeOn(value);
                                                setFieldValue("takeOnDate", value.value);
                                            }}
                                            disabledKeyboardNavigation
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Annual Leave Left</label>
                                        <MyTextInput name="annualDays" />
                                        <p>
                                            <em>Leave blank to skip</em>
                                        </p>

                                        {accumulativeDays && accumulativeDays === true && (
                                            <div>
                                                <label>Accumulative Leave Left</label>
                                                <MyTextInput name="accumulativeDays" />
                                                <p>
                                                    <em>Leave blank to skip</em>
                                                </p>
                                            </div>
                                        )}
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Sick Leave Left</label>
                                        <MyTextInput name="sickDays" />
                                        <p>
                                            <em>Leave blank to skip</em>
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue" type="submit">
                                            ADD TAKE ON LEAVE
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {error && (
                                <Label basic color="red">
                                    {error}
                                </Label>
                            )}
                        </form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}
