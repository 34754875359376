import React, { useState, useEffect } from "react";
import { Segment, Container, Table, Button } from "semantic-ui-react";
import { deleteIRP5, confirmMapping, getCompanies } from "../irp5Actions";
import firebase from "../../../app/config/firebase";
import IRP5BulkUploadForm from "./IRP5BulkUploadForm";
const firestore = firebase.firestore();

export default function IRP5BulkPage(props) {
    const [irp5, setIrp5] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("irp5")
            .where("status", "==", "Unsorted")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setIrp5(list);
            });

        getCompanies().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                setCompanies(list);
            },
            (err) => {
                console.log(err);
            }
        );

        return () => unsubscribe();
    }, []);

    function confirmMappingData(value) {
        confirmMapping(value);
    }
    function deleteMappingData(irp5) {
        deleteIRP5(irp5);
    }
    return (
        <Container>
            <Segment>
                <IRP5BulkUploadForm irp5={irp5} companies={companies} />
            </Segment>

            <Segment>
                {irp5 && irp5.length > 0 ? (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>IRP5 Date</Table.HeaderCell>
                                <Table.HeaderCell>Company</Table.HeaderCell>
                                <Table.HeaderCell>File Name</Table.HeaderCell>
                                <Table.HeaderCell>Suggested Map</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {irp5.map((ps) => (
                                <Table.Row key={ps.id}>
                                    <Table.Cell>{ps.documentName}</Table.Cell>
                                    <Table.Cell>{ps.companyName}</Table.Cell>
                                    <Table.Cell>
                                        <Button as="a" target="_blank" href={ps.fileUrl}>
                                            {ps.fileName}
                                        </Button>
                                    </Table.Cell>

                                    <Table.Cell>
                                        {ps.userUid && ps.userUid.length > 0 && ps.displayName && ps.displayName.length > 0 && ps.employeeNr && ps.employeeNr > 0 && (
                                            <p>
                                                {ps.displayName} - {ps.employeeNr}
                                            </p>
                                        )}
                                    </Table.Cell>

                                    <Table.Cell textAlign="center">
                                        {ps.userUid && ps.userUid.length > 0 && ps.displayName && ps.displayName.length > 0 && ps.employeeNr && ps.employeeNr > 0 && (
                                            <Button as="a" color="blue" onClick={() => confirmMappingData(ps)}>
                                                CONFIRM
                                            </Button>
                                        )}

                                        <Button as="a" color="red" onClick={() => window.confirm("Are you sure you wish to delete this item?") && deleteMappingData(ps)}>
                                            DELETE
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                ) : (
                    <p>No IRP5 to map, upload an IRP5 above</p>
                )}
            </Segment>
        </Container>
    );
}
