import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Form, Segment, Header, Grid, Confirm, Divider } from "semantic-ui-react";
import { createUser, updateProfile, terminateUser, getUser, getCompany, getAuthUser, getCompanyUsers, getDepartments, getSites, resetPassword, getCompanyHrUsers, deleteUser } from "../superActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyRadioButton from "../../../app/common/form/MyRadioButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addSeconds from "date-fns/addSeconds";
import { useDispatch } from "react-redux";
import { verifyAuth } from "../../auth/authActions";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";

import firebase from "../../../app/config/firebase";

const firestore = firebase.firestore();

export default function UsersDetailedPage(props) {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [delOpen, setDelOpen] = useState(false);
    const [userEdit, setUserEdit] = useState({
        email: "",
        companyId: "",
        employeeNr: "",
        siteId: "",
        sendPayslip: "",
        isStaff: false,
        isLineManager: false,
        isAdmin: false,
        isHR: false,
        isSuper: false,
        viewDocuments: false,
        viewPayslips: false,
        viewIrp5: false,
        isTerminated: false,
        onboardingEmail: "",
        sendPayslip: "",
        employmentStartDate: "",
        takeOnStartDate: "",
        leaveTotalFamily: "",
        leaveTotalMoving: "",
        leaveTotalCSR: "",
        leavePerMonth: "",
        leaveTotalStudy: "",
        leaveTotalSick: "",
        leaveTotalMaternity: "",
        leaveTotalPaternity: "",
        leaveTotalIOD: "",
        leaveTotalParental: "",
        leaveTotalReligious: "",
        departmentId: "",
        approvalType: "",
        approvalUser: "",
        departments: [],
        sites: [],
        bank_type: "",
        bank_name: "",
        bank_branch: "",
        bank_acc_no: "",
        displayName: "",
        gender: "",
        companyNegLeaveApproval: "",
    });
    // const [submitting, setSubmitting] = useState(false);
    let parts = window.location.href.split("/");
    let lastSegment = parts.pop() || parts.pop();
    const [companyList, setCompanyList] = useState([{ key: "", text: "None", value: "" }]);
    const [departmentList, setDepartmentList] = useState([{ key: "", text: "None", value: "" }]);
    const [siteList, setSiteList] = useState([{ key: "", text: "None", value: "" }]);
    const [usersList, setUsersList] = useState([{ key: "", text: "None", value: "" }]);
    const [hrUsersList, setHrUsersList] = useState([{ key: "", text: "None", value: "" }]);
    const [auth, setAuth] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [isLineManager, setIsLineManager] = useState(false);
    const [isHR, setIsHR] = useState(false);
    const [isSuper, setIsSuper] = useState(false);
    const [viewDocuments, setViewDocuments] = useState(false);
    const [viewPayslips, setViewPayslips] = useState(false);
    const [viewIrp5, setViewIrp5] = useState(false);
    const [isTerminated, setIsTerminated] = useState(false);
    const [onboardingEmail, setOnboardingEmail] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [leaveRolloverType, setLeaveRolloverType] = useState("");

    const [companyChosen, setCompanyChosen] = useState([]);

    const [approvalType, setApprovalType] = useState("");
    const [employmentStartDate, setChangeEmploymentStartDate] = useState("");
    const [takeOnStartDate, setChangeTakeOnStartDate] = useState("");

    const [requiredTime, setRequiredTime] = useState([]);

    function changeRollType(value) {
        // leaveRolloverType = value;
        setLeaveRolloverType(value);
    }

    const RolloverType = [
        { key: "None", text: "None", value: "None" },
        { key: "Accumulative", text: "Accumulative", value: "Accumulative" },
        { key: "Annual Rollover", text: "Annual Rollover", value: "Annual Rollover" },
    ];

    useEffect(() => {
        getUser(lastSegment).then(
            async (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    if (res.data().departmentId == undefined) {
                        appObj.departmentId = "";
                    }
                    if (res.data().siteId == undefined) {
                        appObj.siteId = "";
                    }
                    if (res.data().departments == undefined) {
                        appObj.departments = [];
                    }

                    await firestore
                        .collection("projects")
                        .where("companyId", "==", appObj.companyId)
                        .onSnapshot((snapshot) => {
                            let list = [];
                            snapshot.docs.forEach((doc) => {
                                if (doc.data().users) {
                                    if (doc.data().users.includes(appObj.id)) {
                                        list.push(doc.data());
                                    }
                                }
                            });

                            setRequiredTime(list);
                        });

                    setUserEdit(appObj);
                    setIsStaff(appObj.isStaff);
                    setIsLineManager(appObj.isLineManager);
                    setIsAdmin(appObj.isAdmin);
                    setIsHR(appObj.isHR);
                    setIsSuper(appObj.isSuper);
                    setViewDocuments(appObj.viewDocuments);
                    setViewPayslips(appObj.viewPayslips);
                    setViewIrp5(appObj.viewIrp5);
                    setIsTerminated(appObj.isTerminated);
                    setOnboardingEmail(appObj.onboardingDone);
                    setApprovalType(appObj.approvalType);
                    setLeaveRolloverType(appObj.leaveRolloverType);

                    if (appObj.employmentStartDate !== undefined && appObj.employmentStartDate !== "") {
                        setChangeEmploymentStartDate(addSeconds(new Date(0), appObj.employmentStartDate.seconds));
                    }
                    if (appObj.takeOnStartDate !== undefined && appObj.takeOnStartDate !== "") {
                        setChangeTakeOnStartDate(addSeconds(new Date(0), appObj.takeOnStartDate.seconds));
                    }

                    if (appObj.companyId && appObj.companyId !== "" && appObj.companyId !== undefined) {
                        await firestore
                            .collection("companies")
                            .doc(appObj.companyId)
                            .onSnapshot((snapshot) => {
                                setCompanyChosen(snapshot.data());
                            });
                    }

                    getDepartments(appObj.companyId).then((res) => {
                        let list = [];
                        res.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });
                        let departments = {};
                        departments = list.map(function (department) {
                            return {
                                key: department.id,
                                text: department.departmentName,
                                value: department.id,
                            };
                        });
                        setDepartmentList(departments);
                    });

                    getSites(appObj.companyId).then((res) => {
                        let list = [];
                        res.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });
                        let sites = {};
                        sites = list.map(function (site) {
                            return {
                                key: site.id,
                                text: site.siteName,
                                value: site.id,
                            };
                        });
                        setSiteList(sites);
                    });

                    getCompanyUsers(appObj.companyId).then((res) => {
                        let list = [];
                        res.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });
                        let users = {};
                        users = list.map(function (user) {
                            return {
                                key: user.id,
                                text: user.displayName ? user.displayName : user.email,
                                value: user.id,
                            };
                        });
                        setUsersList(users);
                    });

                    getCompanyHrUsers(appObj.companyId).then((res) => {
                        let list = [];
                        res.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });
                        let users = {};
                        users = list.map(function (user) {
                            return {
                                key: user.id,
                                text: user.displayName ? user.displayName : user.email,
                                value: user.id,
                            };
                        });
                        setHrUsersList(users);
                    });
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getAuthUser().then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    setAuth(appObj);
                }
            },
            (err) => {
                console.log(err);
            }
        );
        getCompany().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                let companies = {};

                companies = list.map(function (company) {
                    return {
                        key: company.id,
                        text: company.companyName,
                        value: company.id,
                    };
                });
                setCompanyList(companies);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    const sendPayslip = [
        { key: "Company Default", text: "Company Default", value: "Company Default" },
        { key: "Email", text: "Email", value: "Email" },
        { key: "Print", text: "Print", value: "Print" },
        { key: "Nothing", text: "Nothing", value: "Nothing" },
    ];

    const approvalTypeList = [
        { key: "Line Manager/s in Department", text: "Line Manager/s in Department", value: "Line Manager/s in Department" },
        { key: "Specific User", text: "Specific User", value: "Specific User" },
    ];

    const days = [
        { key: "01", text: "01", value: "01" },
        { key: "02", text: "02", value: "02" },
        { key: "03", text: "03", value: "03" },
        { key: "04", text: "04", value: "04" },
        { key: "05", text: "05", value: "05" },
        { key: "06", text: "06", value: "06" },
        { key: "07", text: "07", value: "07" },
        { key: "08", text: "08", value: "08" },
        { key: "09", text: "09", value: "09" },
        { key: "10", text: "10", value: "10" },
        { key: "11", text: "11", value: "11" },
        { key: "12", text: "12", value: "12" },
        { key: "13", text: "13", value: "13" },
        { key: "14", text: "14", value: "14" },
        { key: "15", text: "15", value: "15" },
        { key: "16", text: "16", value: "16" },
        { key: "17", text: "17", value: "17" },
        { key: "18", text: "18", value: "18" },
        { key: "19", text: "19", value: "19" },
        { key: "20", text: "20", value: "20" },
        { key: "21", text: "21", value: "21" },
        { key: "22", text: "22", value: "22" },
        { key: "23", text: "23", value: "23" },
        { key: "24", text: "24", value: "24" },
        { key: "25", text: "25", value: "25" },
        { key: "26", text: "26", value: "26" },
        { key: "27", text: "27", value: "27" },
        { key: "28", text: "28", value: "28" },
        { key: "29", text: "29", value: "29" },
        { key: "30", text: "30", value: "30" },
        { key: "31", text: "31", value: "31" },
    ];

    function close() {
        setOpen(false);
    }

    function openTerm() {
        setOpen(true);
    }

    function closeDel() {
        setDelOpen(false);
    }

    function openDel() {
        setDelOpen(true);
    }

    function setCompanyNegLeavePolicyOnUser(userEdit, companyChosen) {
        userEdit.companyNegLeaveApproval = companyChosen.negLeaveApproval ? companyChosen.negLeaveApproval : "";
    }
    if (!userEdit.companyNegLeaveApproval) {
        setCompanyNegLeavePolicyOnUser(userEdit, companyChosen)
    }

    async function terminateUserData(userEdit) {
        userEdit.id = lastSegment;
        userEdit.terminatedDate = new Date();
        await terminateUser(userEdit);
        setOpen(false);
        props.history.push("/super/users/");
    }

    async function permDeleteUser(userEdit) {
        await deleteUser(userEdit.id);
        setDelOpen(false);
        props.history.push("/super/users/");
    }

    function adminUser(value) {
        setIsAdmin(value.target.checked);
    }
    function superUser(value) {
        setIsSuper(value.target.checked);
    }

    function changeApproval(value) {
        setApprovalType(value);
    }

    function HRUser(value) {
        setIsHR(value.target.checked);
    }
    function viewDocumentsUser(value) {
        setViewDocuments(value.target.checked);
    }

    function lineManagerUser(value) {
        setIsLineManager(value.target.checked);
    }
    function isStaffUser(value) {
        setIsStaff(value.target.checked);
    }
    function viewPayslipUser(value) {
        setViewPayslips(value.target.checked);
    }
    function viewIRP5User(value) {
        setViewIrp5(value.target.checked);
    }
    function isTerminatedUser(value) {
        setIsTerminated(value.target.checked);
    }
    function onBoardingEmailUser(value) {
        setOnboardingEmail(value.target.checked);
    }

    const updateStartDate = useCallback((value) => {
        setChangeEmploymentStartDate(value);
    }, []);

    function changeStartDate(values) {
        updateStartDate(values);
    }

    const updateTakeOn = useCallback((value) => {
        setChangeTakeOnStartDate(value);
    }, []);

    function ChangeTakeOn(values) {
        updateTakeOn(values);
    }

    const updateThis = useCallback(async (value) => {
        await firestore
            .collection("companies")
            .doc(value)
            .onSnapshot((snapshot) => {
                setCompanyChosen(snapshot.data());
            });
        getDepartments(value).then((res) => {
            let list = [];
            res.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });
            let departments = {};
            departments = list.map(function (department) {
                return {
                    key: department.id,
                    text: department.departmentName,
                    value: department.id,
                };
            });
            setDepartmentList(departments);
        });
        getSites(value).then((res) => {
            let list = [];
            res.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });
            let sites = {};
            sites = list.map(function (site) {
                return {
                    key: site.id,
                    text: site.siteName,
                    value: site.id,
                };
            });
            setSiteList(sites);
        });
    }, []);

    function changeCompany(value) {
        updateThis(value);
    }

    function doResetPassword() {
        resetPassword(userEdit);
    }

    function logInAs() {
        setLoggingIn(true);

        let impersonateUser = firebase.app().functions("europe-west2").httpsCallable("impersonateUser");

        impersonateUser({ userId: userEdit.id })
            .then((result) => {
                // Read result of the Cloud Function.
                // var sanitizedMessage = result.data.text;

                if (result.data && result.data !== "") {
                    firebase
                        .auth()
                        .signInWithCustomToken(result.data)
                        .then((userCredential) => {
                            // Signed in
                            let user = userCredential.user;

                            dispatch(verifyAuth());

                            props.history.push("/dashboard/landing");
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;

                            console.log(errorCode, errorMessage);

                            setLoggingIn(false);
                        });
                } else {
                    alert("Token not returned!");
                }
            })
            .catch((error) => {
                let code = error.code;
                let message = error.message;
                let details = error.details;

                console.log(error);

                setLoggingIn(false);
            });
    }

    return (
        <Container>
            {lastSegment !== "0" && (
                <Segment>
                    <Grid>
                        <Grid.Row columns={2} verticalAlign="middle">
                            <Grid.Column>Edit {userEdit.displayName}</Grid.Column>

                            <Grid.Column textAlign="right">
                                <Button size="large" as={Link} to={`/super/users/`}>
                                    Cancel
                                </Button>

                                <Button
                                    // disabled={submitting}
                                    // loading={submitting}
                                    size="large"
                                    color={!userEdit.isTerminated || userEdit.isTerminated === false ? "red" : "green"}
                                    content={!userEdit.isTerminated || userEdit.isTerminated === false ? "Terminate User" : "Activate User"}
                                    onClick={openTerm}
                                />

                                {auth.isSuper && auth.isSuper === true && (!userEdit.isTerminated || userEdit.isTerminated === false) && (
                                    <Button disabled={loggingIn} loading={loggingIn} size="large" content="Log In As" onClick={logInAs} />
                                )}
                                {auth.isSuper && auth.isSuper === true && (userEdit.isTerminated || userEdit.isTerminated === true) && (
                                    <Button size="large" content="Delete User Records" color="red" onClick={openDel} />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Confirm open={open} onCancel={close} onConfirm={() => terminateUserData(userEdit)} />
                    <Confirm open={delOpen} onCancel={closeDel} content="This will delete all the user's data from the database." onConfirm={() => permDeleteUser(userEdit)} />
                </Segment>
            )}

            <Formik
                initialValues={userEdit}
                enableReinitialize="true"
                validate={(values) => {
                    const error = {};

                    if (!values.displayName) {
                        error.displayName = "Name is required";
                    }
                    if (!values.companyId) {
                        error.companyId = "Required";
                    }

                    return error;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log(values);
                    if (userEdit.id) {
                        values.id = lastSegment;

                        if (values.file !== undefined) {
                            var formData = new FormData();
                            var imagefile = document.querySelector("#file");
                            formData.append("file", imagefile.files[0]);
                            formData.append("id", values.id);

                            await axios.post(apiURL + "/api/save_img", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });
                            delete values.file;
                        }

                        await updateProfile(values);
                        props.history.push("/super/users/");
                    } else {
                        values.password = "12345678";
                        values.emailAddress = values.email;
                        if (values.file !== undefined) {
                            delete values.file;
                        }
                        await createUser(values);
                        props.history.push("/super/users/");
                    }

                    console.log(values.departments);

                    setSubmitting(false);
                }}
            >
                {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid stackable columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment>
                                        <Header dividing size="large" content="User Details" />
                                        {/* {lastSegment !== "0" ? (
                                            <Form.Group inline>
                                                <label style={{ width: "80px" }}>Email:</label>
                                                <MyTextInput width={8} name="email" type="email" placeholder="Email Address" readOnly={true} />
                                            </Form.Group>
                                        ) : ( */}
                                        <Form.Group inline>
                                            <label style={{ width: "80px" }}>Email:</label>
                                            <MyTextInput width={8} name="email" type="email" placeholder="Email Address" disabled={userEdit.email} />
                                            <p>
                                                <em>If email is incorrect, a new user account is required.</em>
                                            </p>
                                        </Form.Group>
                                        {/* )} */}
                                        <Form.Group inline>
                                            <label style={{ width: "80px" }}>Company:</label>
                                            <MySelectInput
                                                width={8}
                                                name="companyId"
                                                options={companyList}
                                                value={values.companyId}
                                                onChange={(field, value) => {
                                                    changeCompany(value.value);
                                                    setFieldValue("companyId", value.value);
                                                }}
                                                placeholder="Select your Company"
                                            />
                                        </Form.Group>
                                        <Form.Group inline>
                                            <label style={{ width: "80px" }}>Employee Nr:</label>
                                            <MyTextInput name="employeeNr" type="text" placeholder="Employee Number" />
                                        </Form.Group>
                                        <Form.Group inline>
                                            <label style={{ width: "80px" }}>Site:</label>
                                            <MySelectInput
                                                width={8}
                                                name="siteId"
                                                options={siteList}
                                                value={values.siteId}
                                                onChange={(field, value) => {
                                                    setFieldValue("siteId", value.value);
                                                }}
                                                placeholder="Select your Site"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <label>
                                                <strong>Role: &nbsp;</strong>
                                            </label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="isStaff"
                                                    value="true"
                                                    className="ui checkbox"
                                                    checked={isStaff}
                                                    onChange={(value) => {
                                                        isStaffUser(value);
                                                        setFieldValue("isStaff", value.target.checked);
                                                    }}
                                                />
                                                &nbsp; Staff &nbsp;
                                            </label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="isLineManager"
                                                    value="true"
                                                    className="ui checkbox"
                                                    checked={isLineManager}
                                                    onChange={(value) => {
                                                        lineManagerUser(value);
                                                        setFieldValue("isLineManager", value.target.checked);
                                                    }}
                                                />
                                                &nbsp; Line Manager &nbsp;
                                            </label>

                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="isAdmin"
                                                    value="true"
                                                    className="ui checkbox"
                                                    checked={isAdmin}
                                                    onChange={(value) => {
                                                        adminUser(value);
                                                        setFieldValue("isAdmin", value.target.checked);
                                                    }}
                                                />
                                                &nbsp; Admin &nbsp;
                                            </label>

                                            {auth.isSuper && auth.isSuper === true && (
                                                <label className="ui">
                                                    <input
                                                        type="checkbox"
                                                        name="isHR"
                                                        value="true"
                                                        className="ui checkbox"
                                                        checked={isHR}
                                                        onChange={(value) => {
                                                            HRUser(value);
                                                            setFieldValue("isHR", value.target.checked);
                                                        }}
                                                    />
                                                    &nbsp; HR User &nbsp;
                                                </label>
                                            )}

                                            {auth.isSuper && auth.isSuper === true && (
                                                <label className="ui">
                                                    <input
                                                        type="checkbox"
                                                        name="isSuper"
                                                        value="true"
                                                        className="ui checkbox"
                                                        checked={isSuper}
                                                        onChange={(value) => {
                                                            superUser(value);
                                                            setFieldValue("isSuper", value.target.checked);
                                                        }}
                                                    />
                                                    &nbsp; Super User &nbsp;
                                                </label>
                                            )}
                                        </Form.Group>
                                        {isAdmin && (auth.isSuper || lastSegment !== auth.id) && (
                                            <Form.Group inline>
                                                <label>Access: &nbsp;</label>
                                                <label className="ui">
                                                    <input
                                                        type="checkbox"
                                                        name="viewDocuments"
                                                        value="true"
                                                        className="ui checkbox"
                                                        checked={viewDocuments}
                                                        onChange={(value) => {
                                                            viewDocumentsUser(value);
                                                            setFieldValue("viewDocuments", value.target.checked);
                                                        }}
                                                    />
                                                    &nbsp; View Documents &nbsp;
                                                </label>

                                                <label className="ui">
                                                    <input
                                                        type="checkbox"
                                                        name="viewPayslips"
                                                        value="true"
                                                        className="ui checkbox"
                                                        checked={viewPayslips}
                                                        onChange={(value) => {
                                                            viewPayslipUser(value);
                                                            setFieldValue("viewPayslips", value.target.checked);
                                                        }}
                                                    />
                                                    &nbsp; View Payslips &nbsp;
                                                </label>

                                                <label className="ui">
                                                    <input
                                                        type="checkbox"
                                                        name="viewIrp5"
                                                        value="true"
                                                        className="ui checkbox"
                                                        checked={viewIrp5}
                                                        onChange={(value) => {
                                                            viewIRP5User(value);
                                                            setFieldValue("viewIrp5", value.target.checked);
                                                        }}
                                                    />
                                                    &nbsp; View IRP 5 &nbsp;
                                                </label>
                                            </Form.Group>
                                        )}
                                        <Form.Group inline>
                                            <label>Terminated:</label>
                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="isTerminated"
                                                    value="true"
                                                    className="ui checkbox"
                                                    checked={isTerminated}
                                                    onChange={(value) => {
                                                        isTerminatedUser(value);
                                                        setFieldValue("isTerminated", value.target.checked);
                                                    }}
                                                />
                                                &nbsp; Terminated &nbsp;
                                            </label>
                                        </Form.Group>
                                        <Form.Group inline>
                                            <label>Send Onboarding Email:</label>
                                            <label className="ui">
                                                <input
                                                    type="checkbox"
                                                    name="onboardingEmail"
                                                    value="true"
                                                    className="ui checkbox"
                                                    checked={onboardingEmail}
                                                    onChange={(value) => onBoardingEmailUser(value)}
                                                />
                                                &nbsp; Yes &nbsp;
                                            </label>
                                        </Form.Group>
                                        <Form.Group inline>
                                            <label>Send Payslip</label>
                                            <MySelectInput
                                                width={8}
                                                name="sendPayslip"
                                                options={sendPayslip}
                                                value={values.sendPayslip}
                                                onChange={(field, value) => setFieldValue("sendPayslip", value.value)}
                                                placeholder="Send Payslip"
                                            />
                                        </Form.Group>

                                        <Form.Group inline>
                                            <label>Employment Start Date</label>
                                        </Form.Group>
                                        <Form.Group inline>
                                            <DatePicker
                                                name="employmentStartDate"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.employmentStartDate}
                                                selected={employmentStartDate}
                                                disabledKeyboardNavigation
                                                inline
                                                onChange={(value) => {
                                                    setFieldValue("employmentStartDate", value);
                                                    changeStartDate(value);
                                                }}
                                            />
                                        </Form.Group>
                                        <p>
                                            <em>This date is used to work out the Sick Leave 3 year cycle and also when the users Leave will calculate from.</em>
                                        </p>
                                        <Form.Group inline>
                                            <label>Take On Start Date</label>
                                        </Form.Group>
                                        <Form.Group inline>
                                            <DatePicker
                                                name="takeOnStartDate"
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                dropdownMode="select"
                                                value={values.takeOnStartDate}
                                                selected={takeOnStartDate}
                                                disabledKeyboardNavigation
                                                inline
                                                onChange={(value) => {
                                                    setFieldValue("takeOnStartDate", value);
                                                    ChangeTakeOn(value);
                                                }}
                                            />
                                        </Form.Group>
                                        <p>
                                            <em>
                                                This date is used to work out Leave Totals when a user is Taken On and back capturing leave is not going to happen. If the user has worked for the
                                                company for many years we don't want the Annual Rollover leave to count up depending on the Employee Start date so this date will control when leave
                                                starts counting without affecting the actual Start Date which is required for Sick Leave.
                                            </em>
                                        </p>

                                        {/* {requiredTime && requiredTime.length < 0 && (
                                            <> */}
                                        <Header dividing size="large" content="Required time (%) per project" />

                                        <Grid>
                                            {requiredTime &&
                                                requiredTime.length > 0 &&
                                                requiredTime.map((time) => (
                                                    <Grid.Row columns={2} key={time.id}>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>{time.projectName}</label>
                                                                <MyTextInput name={"Required|" + time.id} placeholder="% of time required per month" />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ))}
                                        </Grid>

                                        {companyChosen.hasOwnProperty("leaveMethodType") && companyChosen.leaveMethodType == "New Method" ? (
                                            <>
                                                {" "}
                                                <Header dividing size="large" content="Take on Leave" />
                                                <p>
                                                    <em>Leave blank to use company defaults</em>
                                                </p>
                                                <Grid>
                                                    <Grid.Row columns={2}>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>Take On Balance</label>
                                                                <MyTextInput name="takeOnBalance" placeholder="Start Leave Balance" />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Family Balance</label>
                                                                <MyTextInput name="familyBalance" type="text" placeholder="Start Family Leave" />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Moving Balance</label>
                                                                <MyTextInput name="movingBalance" type="text" placeholder="Start Moving Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>CSR Balance</label>
                                                                <MyTextInput name="CSRBalance" type="text" placeholder="Start CSR Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>Study Balance</label>
                                                                <MyTextInput name="studyBalance" type="text" placeholder="Start Study Leave" />
                                                            </Form.Field>
                                                        </Grid.Column>

                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>Accumulative Balance</label>
                                                                <MyTextInput name="accumBalance" placeholder="Start Accumulative Balance" />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Sick Balance</label>
                                                                <MyTextInput name="sickbalance" type="text" placeholder="Start Sick Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>Maternity Balance</label>
                                                                <MyTextInput name="maternityBalance" type="text" placeholder="Start Maternity Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>Paternity Balance</label>
                                                                <MyTextInput name="paternityBalance" type="text" placeholder="Start Paternity Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>IOD Balance</label>
                                                                <MyTextInput name="IODBalance" type="text" placeholder="Start IOD Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>Parental Balance</label>
                                                                <MyTextInput name="parentalBalance" type="text" placeholder="Start Parental Leave" />
                                                            </Form.Field>

                                                            <Form.Field>
                                                                <label>Religious Balance</label>
                                                                <MyTextInput name="religiousBalance" type="text" placeholder="Start Religious Leave" />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </>
                                        ) : null}

                                        <Header dividing size="large" content="Custom Leave Allowance" />
                                        <p>
                                            <em>Leave blank to use company defaults</em>
                                        </p>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={8}>
                                                    <Form.Field>
                                                        <label>Family (Days)</label>
                                                        <MyTextInput name="leaveTotalFamily" type="text" placeholder="Total Family Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Moving (Days)</label>
                                                        <MyTextInput name="leaveTotalMoving" type="text" placeholder="Total Moving Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>CSR (Days)</label>
                                                        <MyTextInput name="leaveTotalCSR" type="text" placeholder="Total CSR Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Leave per Month</label>
                                                        <MyTextInput name="leavePerMonth" type="text" placeholder="Leave per Month" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Study (Days)</label>
                                                        <MyTextInput name="leaveTotalStudy" type="text" placeholder="Total Study Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Leave Rollover Type</label>
                                                        <MySelectInput
                                                            name="leaveRolloverType"
                                                            options={RolloverType}
                                                            value={values.leaveRolloverType}
                                                            onChange={(field, value) => {
                                                                changeRollType(value.value);
                                                                setFieldValue("leaveRolloverType", value.value);
                                                            }}
                                                            placeholder="Leave Rollover Type"
                                                        />
                                                    </Form.Field>

                                                    {leaveRolloverType && leaveRolloverType === "Accumulative" && (
                                                        <Form.Field>
                                                            <label>Accumulative Leave after Forfeit</label>
                                                            <MyTextInput name="leaveAccumulativeAfterForfeit" type="text" placeholder="Leave after Forfeit?" />
                                                        </Form.Field>
                                                    )}
                                                </Grid.Column>

                                                <Grid.Column width={8}>
                                                    <Form.Field>
                                                        <label>Sick (Days)</label>
                                                        <MyTextInput name="leaveTotalSick" type="text" placeholder="Total Sick Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Maternity (Days)</label>
                                                        <MyTextInput name="leaveTotalMaternity" type="text" placeholder="Total Maternity Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Paternity (Days)</label>
                                                        <MyTextInput name="leaveTotalPaternity" type="text" placeholder="Total Paternity Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>IOD (Days)</label>
                                                        <MyTextInput name="leaveTotalIOD" type="text" placeholder="Total IOD Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Parental (Days)</label>
                                                        <MyTextInput name="leaveTotalParental" type="text" placeholder="Total Parental Leave" />
                                                    </Form.Field>

                                                    <Form.Field>
                                                        <label>Religious (Days)</label>
                                                        <MyTextInput name="leaveTotalReligious" type="text" placeholder="Total Religious Leave" />
                                                    </Form.Field>

                                                    {leaveRolloverType && leaveRolloverType === "Accumulative" && (
                                                        <Form.Field>
                                                            <label>Accumulative Leave Forfeit Months</label>
                                                            <MyTextInput name="leaveAccumulativeForfeit" type="text" placeholder="Does Accumulative Leave Forfeit?" />
                                                        </Form.Field>
                                                    )}

                                                    {leaveRolloverType && leaveRolloverType === "Accumulative" && (
                                                        <Form.Field>
                                                            <label>Maximum Accumulated Leave</label>
                                                            <MyTextInput name="leaveAccumulativeMaximum" type="text" placeholder="How much leave can accumulate?" />
                                                        </Form.Field>
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column>
                                    <Segment hidden={isStaff === false && isLineManager === false}>
                                        <Header dividing size="large" content="Departments" />

                                        <div hidden={isStaff === false}>
                                            <p>
                                                <strong>Staff Department</strong>
                                                <br />
                                                What department does this user work in?
                                            </p>
                                            <MySelectInput
                                                width={8}
                                                name="departmentId"
                                                options={departmentList}
                                                value={values.departmentId}
                                                onChange={(field, value) => {
                                                    setFieldValue("departmentId", value.value);
                                                }}
                                                placeholder="Select your Department"
                                            />

                                            <p>Who will approve this staff members leave?</p>
                                            <MySelectInput
                                                width={8}
                                                name="approvalType"
                                                options={approvalTypeList}
                                                value={values.approvalType}
                                                onChange={(field, value) => {
                                                    changeApproval(value.value);
                                                    setFieldValue("approvalType", value.value);
                                                }}
                                                placeholder="Select your Approval Type"
                                            />

                                            <div hidden={approvalType !== "Specific User"}>
                                                <p>
                                                    <strong>Approval Line Manager</strong>
                                                    <br />
                                                    Choose a Line Manager that will approve this users Leave
                                                </p>
                                                <MySelectInput
                                                    width={8}
                                                    name="approvalUser"
                                                    options={usersList}
                                                    value={values.approvalUser}
                                                    onChange={(field, value) => setFieldValue("approvalUser", value.value)}
                                                    placeholder="Select a User"
                                                />
                                            </div>
                                            <br />
                                            {values.companyNegLeaveApproval == "HR User" && (
                                                <div>
                                                    <p>
                                                        <strong>Approval HR</strong>
                                                        <br />
                                                        Choose an HR user that will approve this users negative Leave
                                                    </p>
                                                    <MySelectInput
                                                        width={8}
                                                        name="approvalHRUser"
                                                        options={hrUsersList}
                                                        value={values.approvalHRUser}
                                                        onChange={(field, value) => setFieldValue("approvalHRUser", value.value)}
                                                        placeholder="Select a User"
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div hidden={isStaff === false || isLineManager === false}>
                                            <Divider />
                                        </div>

                                        {isLineManager && departmentList && (
                                            <div>
                                                <p>
                                                    <strong>Line Manager Department/s</strong>
                                                    <br />
                                                    If this Line Manager is responsible for approving leave for all staff in a given department, choose the deparment/s.
                                                </p>
                                                <MySelectInput
                                                    width={8}
                                                    name="departments"
                                                    options={departmentList}
                                                    value={values.departments || []}
                                                    onChange={(field, value) => setFieldValue("departments", value.value)}
                                                    placeholder="Select your Department/s"
                                                    multiple
                                                />
                                            </div>
                                        )}
                                    </Segment>

                                    <Segment>
                                        <Header dividing size="large" content="Expense Tracking" />
                                        <p>
                                            <em>Leave blank to use company defaults</em>
                                        </p>
                                        <Grid>
                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Allow Per Month To:</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MySelectInput
                                                        name="expenseToDate"
                                                        options={days}
                                                        onChange={(field, value) => setFieldValue("expenseToDate", value.value)}
                                                        placeholder="To day of month"
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>

                                    <Segment>
                                        <Header dividing size="large" content="Banking Details" />

                                        <Grid>
                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Type of Bank A/C:</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="bank_type" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Name of Bank</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="bank_name" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Branch Name & Code</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="bank_branch" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2} verticalAlign="middle">
                                                <Grid.Column width={4}>
                                                    <label>Bank Account No</label>
                                                </Grid.Column>

                                                <Grid.Column width={12}>
                                                    <MyTextInput name="bank_acc_no" type="text" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <Header dividing size="large" content="Onboarding Details" />
                                        {/* <p>
                                            <em>Additional information not required if user fills in Onboarding</em>
                                        </p> */}

                                        <Form.Group inline>
                                            <label>Full Name:</label>
                                            <MyTextInput width={8} name="displayName" type="text" placeholder="Known As" required />
                                        </Form.Group>

                                        <Form.Group inline>
                                            <label>Gender:</label>
                                            <MyRadioButton labelcb="Male" name="gender" type="radio" value="male" label="" />

                                            <MyRadioButton labelcb="Female" name="gender" type="radio" value="female" label="" />
                                        </Form.Group>

                                        {auth.isSuper && auth.isSuper === true && (
                                            <div>
                                                <Header dividing size="large" content="Password Reset" />
                                                <Button /*disabled={submitting} loading={submitting}*/ size="large" color="red" type="button" content="Reset Password" onClick={doResetPassword} />

                                                {userEdit.passwordResetLink && userEdit.passwordResetLink !== "" && (
                                                    <a href={userEdit.passwordResetLink} target="_blank">
                                                        Reset Now
                                                    </a>
                                                )}
                                                <br />
                                                <br />
                                            </div>
                                        )}

                                        {isLineManager === true || isSuper === true || isAdmin === true ? (
                                            <Form.Group inline>
                                                <label>Signature:</label>
                                                <input
                                                    id="file"
                                                    name="file"
                                                    type="file"
                                                    onChange={(event) => {
                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                    }}
                                                />
                                            </Form.Group>
                                        ) : (
                                            ""
                                        )}
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Segment textAlign="center">
                            <Button disabled={!dirty || isSubmitting} loading={isSubmitting} size="large" type="submit" positive content={lastSegment !== "0" ? "Update User" : "Create User"} />

                            <Button size="large" as={Link} to={`/super/users/`}>
                                Cancel
                            </Button>
                        </Segment>
                    </form>
                )}
            </Formik>
        </Container>
    );
}
