import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import LeaveChangeForm from "../staff/LeaveList/LeaveChangeForm";

export default function LeaveChangeModal(props) {
    const dispatch = useDispatch();
    let leaveCurrent = props.leaveCurrent;
    let profileClient = props.profileClient;
    let company = props.company;

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Content>
                <Modal.Description>
                    <LeaveChangeForm leave={leaveCurrent} company={company} profileClient={profileClient} />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
