//This is used if we want to access firebase directly without using getFirestore
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";

export async function supportTrack(support) {
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    support.userUid = user.uid;
    support.status = "New Request";
    support.createDate = new Date(Date.now());

    let assUsrs = [support.assignedUid];
    support.assignedDisplayName = [];
    support.assignedUid = [];


    Object.keys(assUsrs).forEach(async function (key) {
        let currVal = assUsrs[key];
        let nameArr = currVal.split("|");

        support.assignedUid.push(nameArr[0]);
        support.assignedDisplayName.push(nameArr[1]);
    });

    if (support.assignedHRUid) {
        let assHRUsrs = [support.assignedHRUid];
        support.assignedHRDisplayName = [];
        support.assignedHRUid = [];

        Object.keys(assHRUsrs).forEach(async function (key) {
            let currVal = assHRUsrs[key];
            let nameArr = currVal.split("|");

            support.assignedHRUid.push(nameArr[0]);
            support.assignedHRDisplayName.push(nameArr[1]);
        });
    }

    try {
        let supportNew = await firestore.collection("support").add(support);

        Object.keys(assUsrs).forEach(async function (key) {
            let currVal = assUsrs[key];
            let nameArr = currVal.split("|");

            //Action Center
            let notification = {
                createDate: new Date(Date.now()),
                notificationType: "Notification",
                rowModule: "Support",
                rowType: support.requestType,
                rowId: supportNew.id,
                description: "Support Ticket assigned to you",
                read: false,
                userUid: nameArr[0],
                displayName: nameArr[1],
            };

            await firestore.collection("notifications").add(notification);
        });

        toast.success("Support has been requested");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function supportReply(support_reply, support_req) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;

    let userQuery = await firestore.collection("users").doc(auth.uid).get();
    let userData = userQuery.data();

    support_reply.userUid = auth.uid;
    support_reply.createDate = new Date(Date.now());
    support_reply.displayName = userData.displayName;

    try {
        await firestore.collection("support").doc(support_req.id).collection("support_replies").add(support_reply);

        let status = "";
        if (support_req.assignedUid === auth.uid) {
            status = "Admin Reply Sent";
        } else {
            status = "Reply Sent";
        }

        let support_data = {
            status: status,
        };

        await firestore.collection("support").doc(support_req.id).set(support_data, { merge: true });

        toast.success(status);
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function closeticket(support_req) {
    const firestore = firebase.firestore();

    try {
        let new_status = {};
        new_status.status = "Complete";

        await firestore.collection("support").doc(support_req.id).set(new_status, { merge: true });

        toast.success("Ticket has been closed");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function deleteticket(support_req) {
    const firestore = firebase.firestore();

    try {
        //Remove Comments
        await firestore
            .collection("support")
            .doc(support_req.id)
            .collection("support_replies")
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    await firestore.collection("support").doc(support_req.id).collection("support_replies").doc(doc.id).delete();
                });
            });

        //Remove timetrack
        await firestore.collection("support").doc(support_req.id).delete();

        toast.success("Ticket has been deleted");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function getSupport() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser.uid;
    return await firestore.collection("support").where("userUid", "==", auth).get();
}
export async function getSupportAss() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser.uid;
    return await firestore.collection("support").where("assignedUid", "array-contains", auth).get();
}
export async function getSupportAssHr() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser.uid;
    return await firestore.collection("support").where("assignedHRUid", "array-contains", auth).get();
}
export async function getAuthUser() {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
}
export async function getUsersList(companyId) {
    const firestore = firebase.firestore();
    return await firestore.collection("users").where("companyId", "==", companyId).where("isTerminated", "==", false).get();
}
export async function getSupportRec(id) {
    const firestore = firebase.firestore();
    return firestore.collection("support").doc(id).get();
}
export async function getSupportReplies(supportId) {
    const firestore = firebase.firestore();
    return firestore.collection("support").doc(supportId).collection("support_replies").orderBy("createDate", "desc").get();
}
