import firebase from "../../app/config/firebase";

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export const getUserProfile = (userData) => {
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getStaff(companyId) {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userId = auth.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    if (userInfo.isSuper) {
        // return firestore.collection("users").where("companyId", "==", companyId).where("isTerminated", "==", false).get();
        return firestore.collection("users").where("companyId", "==", companyId).get();
    } else if (userInfo.isAdmin && companyId === userInfo.companyId) {
        // return firestore.collection("users").where("companyId", "==", companyId).where("isTerminated", "==", false).get();
        return firestore.collection("users").where("companyId", "==", companyId).get();
    } else if (userInfo.departments && userInfo.departments.length > 0) {
        // return firestore.collection("users").where("companyId", "==", userInfo.companyId).where("departmentId", "in", userInfo.departments).where("isTerminated", "==", false).get();
        return firestore.collection("users").where("companyId", "==", userInfo.companyId).where("departmentId", "in", userInfo.departments).get();
    } else {
        return [];
    }
}

export async function getCompany() {
    const firestore = firebase.firestore();
    return firestore.collection("companies").get();
}

export async function getTime(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("timetrack").where("userUid", "==", userId).where("status", "in", ["Approved", "Pending", "In Progress"]).get();
}
