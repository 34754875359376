import firebase from "../../../app/config/firebase";

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};
export async function getLeave() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userId = auth.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    if (userInfo.isSuper === true) {
        return firestore.collection("leave").where("status", "==", "Approved").where("companyId", "==", userInfo.companyId).get();
    } else {
        return firestore.collection("leave").where("status", "==", "Approved").where("companyId", "==", userInfo.companyId).where("departmentId", "in", userInfo.departments).get();
    }
}
export async function getEmployees() {
    const firestore = firebase.firestore();
    const auth = firebase.auth().currentUser;
    let userId = auth.uid;
    let userInfo = [];
    await firestore
        .collection("users")
        .doc(userId)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), id: res.id };
                    userInfo = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );
    if (userInfo.isSuper === true) {
        return firestore.collection("users").where("isTerminated", "==", false).where("companyId", "==", userInfo.companyId).get();
    } else {
        return firestore.collection("users").where("isTerminated", "==", false).where("companyId", "==", userInfo.companyId).where("departmentId", "in", userInfo.departments).get();
    }
}
