import React, { useEffect, useState } from "react";
import TimeTrackListItem from "./TimeTrackListItem";
import { Grid, List, Segment } from "semantic-ui-react";
import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const firestore = firebase.firestore();

export default function TimeTrackOutstanding() {
    const [loading, setLoading] = useState(false);
    const [outstanding, setOutstanding] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        getAllOutstanding();
    }, []);

    async function getAllOutstanding() {
        setLoading(true);

        let userList = [];

        await firestore
            .collection("users")
            .where("companyId", "==", currentUserProfile.companyId)
            .where("approvalUser", "==", currentUserProfile.id ? currentUserProfile.id : ["None"])
            // .where("isTerminated", "==", false)
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                userList.push(list);
            });

        await firestore
            .collection("users")
            .where("approvalType", "==", "Line Manager/s in Department")
            .where("companyId", "==", currentUserProfile.companyId)
            .where("departmentId", "in", currentUserProfile.departments.length > 0 ? currentUserProfile.departments : ["None"])
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                userList.push(list);
            });

        const someTimeoutAction = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(userList.flat());
                }, 3000);
            });
        };

        const status = await someTimeoutAction();
        const response = await axios.post(apiURL + "/api/get_outstanding", { userList: status });

        setOutstanding(response.data);
        setLoading(false);
    }

    return (
        <div>
            <h2>Outstanding</h2>

            {loading && loading === true && <LoadingComponent />}

            {outstanding &&
                outstanding.length > 0 &&
                outstanding.map((row) => (
                    <>
                        <Segment>
                            <Grid>
                                <Grid.Row columns={3} verticalAlign="middle">
                                    <Grid.Column width={6}>
                                        <div className="header">
                                            <b>{row.displayName}</b>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{ color: "black" }}>
                                        Time Tracking Outstanding for: <b>{row.month}</b>
                                    </Grid.Column>
                                    <Grid.Column width={4} textAlign="right">
                                        <b>{row.behind}</b> {parseFloat(row.behind) > 1 ? "months" : "month"} overdue
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </>
                ))}
        </div>
    );
}
