//This is used if we want to access firebase directly without using getFirestore
import firebase from "../../app/config/firebase";
import format from "date-fns/format";
import axios from "axios";
import apiURL from "../../app/config/apiURL";
import { generateNewLeaveStats } from "../manager/managerActions";
import { addDays } from "date-fns/esm";

export const updateTerminatedUsers = (leave) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const firestore2 = firebase.firestore();

        try {
            let updated = 0;
            let skipped = 0;

            await firestore2
                .collection("users")
                .get()
                .then(function (querySnapshot) {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach(function (doc) {
                            if (!doc.empty) {
                                let data = doc.data();

                                if (typeof data.isTerminated === "undefined") {
                                    updated++;

                                    firestore.update(`users/${doc.id}`, { isTerminated: false });
                                } else {
                                    skipped++;
                                }
                            }
                        });
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
};

export const updateOldLeaveDates = () => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const firestore2 = firebase.firestore();

        try {
            let updated = 0;
            let skipped = 0;

            await firestore2
                .collection("leave")
                .get()
                .then(function (querySnapshot) {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach(async function (doc) {
                            if (!doc.empty) {
                                let data = doc.data();

                                if (data.companyId && data.createDate && data.createDate !== "" && data.annualFrom && data.annualFrom !== "" && data.createDate > data.annualFrom) {
                                    //Get company data
                                    let company = await firestore.get(`companies/${data.companyId}`);
                                    company = company.data();

                                    if (typeof company !== "undefined" && company.yearStart && company.yearStart !== "") {
                                        let leaveYearStart = parseInt(company.yearStart);
                                        let yearNow = parseInt(format(Date.now(), "yyyy"));

                                        let year_annualFrom = parseInt(format(data.annualFrom.toDate(), "yyyy"));
                                        let month_annualFrom = parseInt(format(data.annualFrom.toDate(), "M"));

                                        if (year_annualFrom < yearNow || (year_annualFrom == yearNow && month_annualFrom < leaveYearStart)) {
                                            updated++;

                                            await firestore.update(`leave/${doc.id}`, {
                                                createDateOld: data.createDate,
                                                createDate: data.annualFrom,
                                            });
                                        } else {
                                            skipped++;
                                        }
                                    } else {
                                        skipped++;
                                    }

                                    //What about financial year, if its before this year vs just earlier in an existing year
                                } else {
                                    skipped++;
                                }
                            }
                        });
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };
};

export const updateDepName = async () => {
    const firestore = firebase.firestore();

    await firestore
        .collection("users")
        .get()
        .then((result) => {
            result.docs.forEach(async (doc) => {
                let userObj = { ...doc.data(), id: doc.id };
                console.log(userObj.id);

                if (userObj.departmentName === "" || userObj.departmentName === undefined) {
                    if (userObj.departmentId !== "" && userObj.departmentId !== undefined) {
                        await firestore
                            .collection("departments")
                            .doc(userObj.departmentId)
                            .get()
                            .then(async (res) => {
                                if (res.exists) {
                                    await firestore.collection("users").doc(userObj.id).update({ departmentName: res.data().departmentName });
                                }
                            });
                    }
                }
            });
        });

    return true;
};

export const terminateThink = async () => {
    const firestore = firebase.firestore();

    let users = [];

    await firestore
        .collection("users")
        .where("companyId", "==", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach((doc) => {
                let userObj = { ...doc.data(), id: doc.id };
                users.push(userObj);
            });
        });

    run1(users, 0);

    async function run1(users, count) {
        console.log(count, users[count]);

        if (users[count]) {
            await firestore.collection("users").doc(users[count].id).update({ isTerminated: false, thinkSplit: true });

            console.log("updated " + users[count].id);

            count++;

            setTimeout(function () {
                console.log("timer done, now moving to " + count);
                run1(users, count);
            }, 100);
        } else {
            console.log("done");
        }
    }
};

export const unterminateThink = async () => {
    const firestore = firebase.firestore();

    await firestore
        .collection("users")
        .where("companyId", "==", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let userObj = { ...doc.data(), id: doc.id };

                if (userObj.thinkSplit && userObj.thinkSplit === true) {
                    setTimeout(async function () {
                        await firestore.collection("users").doc(userObj.id).update({ isTerminated: false });
                    }, 5000);
                }
            });
        });
};

export const terminateNonThink = async () => {
    const firestore = firebase.firestore();

    await firestore
        .collection("users")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let userObj = { ...doc.data(), id: doc.id };

                await firestore.collection("users").doc(userObj.id).update({ isTerminated: true });
            });
        });
};

export const removeNonThink = async () => {
    const firestore = firebase.firestore();

    //NOTE: Test deleting something that has a subcollection then refresh firestore, as far as I recall the collection will remain because of that sub sollection.
    //You will need to first loop over and remove all sub data

    //remove non think timetrack
    //NOTE: Do a check but if timetracking is not actually used we can delete that entirely
    await firestore
        .collection("timetrack")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let timetrackObj = { ...doc.data(), id: doc.id };

                //remove non think timetrack row
                await firestore
                    .collection("timetrackRow")
                    .where("timeId", "==", timetrackObj.id)
                    .get()
                    .then(async (resultRow) => {
                        resultRow.docs.forEach(async (docRow) => {
                            let timetrackRowObj = { ...docRow.data(), id: docRow.id };
                            await firestore.collection("timetrackRow").doc(timetrackRowObj.id).delete();
                        });
                    });

                //remove timetrack sub collection comments
                await firestore
                    .collection("timetrack")
                    .doc(timetrackObj.id)
                    .collection("comments")
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let commentId = doc.id;

                            await firestore.collection("timetrack").doc(timetrackObj.id).collection("comments").doc(commentId).delete();
                        });
                    });

                await firestore.collection("timetrack").doc(timetrackObj.id).delete();
            });
        });

    //remove non think support
    await firestore
        .collection("support")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let id = doc.id;

                //remove leave sub collection comments
                await firestore
                    .collection("support")
                    .doc(id)
                    .collection("support_replies")
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let supportId = doc.id;

                            await firestore.collection("support").doc(id).collection("support_replies").doc(supportId).delete();
                        });
                    });

                await firestore.collection("support").doc(id).delete();
            });
        });

    //remove non think resources
    await firestore
        .collection("resources")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let id = doc.id;

                await firestore.collection("resources").doc(id).delete();
            });
        });

    //remove non think projects
    await firestore
        .collection("projects")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let id = doc.id;

                await firestore.collection("projects").doc(id).delete();
            });
        });

    //remove non think leave
    await firestore
        .collection("leave")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let id = doc.id;

                //remove leave sub collection comments
                await firestore
                    .collection("leave")
                    .doc(id)
                    .collection("comments")
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let commentId = doc.id;

                            await firestore.collection("leave").doc(id).collection("comments").doc(commentId).delete();
                        });
                    });

                await firestore.collection("leave").doc(id).delete();
            });
        });

    //remove non think departments
    await firestore
        .collection("departments")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let id = doc.id;

                await firestore.collection("departments").doc(id).delete();
            });
        });

    //remove non think companies
    await firestore
        .collection("companies")
        .where("id", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc) => {
                let id = doc.id;

                await firestore.collection("companies").doc(id).delete();
            });
        });

    // Remove non think users
    //NOTE: this will need to happen last, we can then on the user loop loop over all of the collections without a company id to
    //remove that data and once that data is deleted then remove user data
    await firestore
        .collection("users")
        .where("companyId", "!=", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (result) => {
            result.docs.forEach(async (doc3) => {
                let id = doc3.id;

                //remove user sub collection photos
                await firestore
                    .collection("users")
                    .doc(id)
                    .collection("photos")
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let photoId = doc.id;

                            await firestore.collection("users").doc(id).collection("photos").doc(photoId).delete();
                        });
                    });

                //remove user sub collection onboarding
                await firestore
                    .collection("users")
                    .doc(id)
                    .collection("onboarding")
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let onboardingId = doc.id;

                            await firestore.collection("users").doc(id).collection("photos").doc(onboardingId).delete();
                        });
                    });

                //remove Users IRP5 documents
                await firestore
                    .collection("irp5")
                    .where("userUid", "==", id)
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let irpId = doc.id;

                            await firestore.collection("irp5").doc(irpId).delete();
                        });
                    });

                //remove Users payslips documents
                await firestore
                    .collection("payslips")
                    .where("userUid", "==", id)
                    .get()
                    .then(async (res) => {
                        res.docs.forEach(async (doc) => {
                            let payslipId = doc.id;

                            await firestore.collection("payslips").doc(payslipId).delete();
                        });
                    });

                await firestore.collection("users").doc(id).delete();
            });
        });
};

export const updateThinkLeaveUsers = async () => {
    const firestore = firebase.firestore();

    await firestore
        .collection("users")
        .where("companyId", "==", "tSCqYFwyczd0DXOUi18F")
        .get()
        .then(async (res) => {
            res.docs.forEach(async (doc) => {
                console.log(doc.id);
                await generateNewLeaveStats(doc.id);
            });
        });
};

export const testPDFAction = async () => {
    const response = await axios.get(apiURL + "/api/test_pdf");
    console.log(response.data);
};

export const testIRP5Actions = async () => {
    const firestore = firebase.firestore();

    // console.log("hete");

    // await firestore
    //     .collection("irp5")
    //     .where("documentName", "==", "28 February 2022 - THINK")
    //     .get()
    //     .then(async (res) => {
    //         // console.log(res);
    //         res.docs.forEach(async (doc) => {
    //             // console.log("1");
    //             //             // console.log(doc.id);
    //             //             // await generateNewLeaveStats(doc.id);
    //                         await firestore
    //                             .collection("users")
    //                             .doc(doc.data().userUid)
    //                             .get()
    //                             .then(async (res) => {
    //                                 if (!res.empty) {
    //                                     // console.log(doc.id);
    //                                     console.log(res.data().displayName + " - " + res.data().email);
    //                                 }
    //                             });
    //         });
    //     });

    await firestore
        .collection("users")
        .where("companyId", "==", "tSCqYFwyczd0DXOUi18F")
        .where("isTerminated", "==", false)
        .get()
        .then(async (res) => {
            res.docs.forEach(async (doc) => {
                // console.log(doc.id);
                // await generateNewLeaveStats(doc.id);
                await firestore
                    .collection("irp5")
                    .where("documentName", "==", "28 February 2022 - DRG")
                    .where("userUid", "==", doc.id)
                    .get()
                    .then(async (res) => {
                        if (res.empty) {
                            console.log(doc.data().displayName + " - " + doc.data().email);
                        }
                    });
            });
        });
};

export const checkLeaveOutstandingAction = async () => {
    const firestore = firebase.firestore();
    const date_now = new Date(Date.now());
    await firestore
        .collection("leave")
        .where("status", "==", "Pending")
        .get()
        .then(function (querySnapshot) {
            if (!querySnapshot.empty) {
                querySnapshot.forEach(function (doc) {
                    if (!doc.empty) {
                        if (date_now > new Date(addDays(doc.data().createDate.toDate(), 5))) {
                            console.log(doc.data());
                            console.log(new Date(addDays(doc.data().createDate.toDate(), 5)));
                        }
                    }
                });
            }
        });
};

export const updateSpesificUserId = async () => {
    const firestore = firebase.firestore();

    const newId = "lJXyEKwYSsXc70QhFNReW0M0qVs2";
    const oldId = "s45wy7N0bxaLxzwnXCdRKwlQ5KD3";

    //COMMENTS
    await firestore
        .collection("comments")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("comments").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Comments Update Complete");

    //IRP5
    await firestore
        .collection("irp5")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("irp5").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("IRP5 Update Complete");

    //LEAVE
    await firestore
        .collection("leave")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("leave").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Leave Update Complete");

    //NOTIFICATIONS
    await firestore
        .collection("notifications")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("notifications").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Notification Update Complete");

    //PAYSLIPS
    await firestore
        .collection("payslips")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("payslips").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Payslip Update Complete");

    //REMOTEWORK
    await firestore
        .collection("remoteWork")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("remoteWork").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Remote Work Update Complete");

    //RESOURCES
    await firestore
        .collection("resources")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("resources").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Resources Work Update Complete");

    //SUPPORT
    await firestore
        .collection("support")
        .where("userUid", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("support").doc(doc.id).update({ userUid: newId });
                });
            }
        });

    console.log("Support Update Complete");

    //APPROVAL USER
    await firestore
        .collection("users")
        .where("approvalUser", "==", oldId)
        .get()
        .then(async (result) => {
            if (result.docs.length > 0) {
                result.docs.forEach(async (doc) => {
                    await firestore.collection("users").doc(doc.id).update({ approvalUser: newId });
                });
            }
        });

    console.log("Approval User Update Complete");

    const response = await axios.post(apiURL + "/api/update_specific_user", { newId: newId, oldId: oldId });

    console.log(response.data);
    console.log("Time Updated Completed");
};
