import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Segment, Grid, Container } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import format from "date-fns/format";
import { generateLeaveStats, clearProfileLeave, generateNewLeaveStats, generateNew, genSickLeave } from "../../manager/managerActions";
import "react-datepicker/dist/react-datepicker.css";
import LeaveLeftTbl from "../LeaveList/LeaveLeftTbl";
import { Link } from "react-router-dom";
import ReactExport from "react-data-export";

import firebase from "../../../app/config/firebase";
import { useCallback } from "react";
import LeaveLeftNew from "../LeaveList/LeaveLeftNew";
import LeaveLeftOld from "../LeaveList/LeaveLeftOld";
const firestore = firebase.firestore();

export default function StaffDashboardStats(props) {
    let profile = props.profile;
    let profileClient = props.profileClient;
    let monthNow = format(Date.now(), "M");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let selectedYear = "";
    let parts = window.location.href.split("/");
    let id = parts.pop() || parts.pop();
    const [company, setCompany] = useState([]);
    const [generatingStats, setGeneratingStats] = useState(false);
    const [leaveUsed, setLeaveUsed] = useState([]);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    if (props.year !== undefined) {
        selectedYear = props.year;
    } else {
        selectedYear = new Date().getFullYear();
    }

    async function genStats() {
        setGeneratingStats(true);
        //Generate Leave Data
        // console.log(props.profileClient.id);
        await generateLeaveStats(props.profileClient.id, "Leave stats updated!");
        setGeneratingStats(false);
    }

    const [leave, setLeave] = useState({});
    // WE'RE GOOD HERE
    // console.log(profileClient);

    useEffect(() => {
        if (selectedYear !== "") {
            const unsubscribe = firestore
                .collection("leaveStatus")
                .doc(selectedYear)
                .onSnapshot((snapshot) => {
                    setLeave(snapshot.data());
                });

            return () => {
                unsubscribe();
            };
        }
    }, [selectedYear]);

    useEffect(() => {
        if (profileClient.companyId) {
            console.log("Entering profileClient.companyId condition");
            firestore
                .collection("companies")
                .doc(profileClient.companyId)
                .onSnapshot((snapshot) => {
                    setCompany(snapshot.data());
                });
        } else {
            firestore
                .collection("companies")
                .doc(profile.companyId)
                .onSnapshot((snapshot) => {
                    setCompany(snapshot.data());
                });
        }

        getDownloadData();
    }, [profileClient]);

    async function getDownloadData() {
        // console.log("sdfsfd");
        // console.log(props.profileClient);
        if (props.profileClient && profileClient.id !== undefined) {
            await firestore
                .collection("leave")
                .where("userUid", "==", profileClient.id)
                .where("annualFrom", ">=", new Date(new Date().getFullYear() + "-01-01 00:00:00"))
                .onSnapshot((res) => {
                    let mainLeave = [];
                    res.docs.forEach((doc) => {
                        mainLeave.push({
                            leaveType: doc.data().leaveType,
                            annualFrom: doc.data().annualFrom.toDate(),
                            annualTo: doc.data().annualTo.toDate(),
                            halfDay: doc.data().halfDay,
                            status: doc.data().status,
                            annualDays: doc.data().annualDays,
                            description: doc.data().description,
                        });
                    });

                    setLeaveUsed(mainLeave);

                    // console.log("mainLeave");
                    // console.log(mainLeave);
                });
        }
    }

    if (leaveUsed.length < 1) {
        getDownloadData();
    }

    async function generateNewLeave(id) {
        setLoading(true);

        await generateNewLeaveStats(id);
        await genSickLeave(id);

        setLoading(false);
    }

    async function genStatsAll() {
        setGeneratingStats(true);

        let profileClient = props.profileClient;
        let years = [];
        let startYear = "";
        let startMonth = "";
        let yearNow = "";

        //Check if this user has a Take On start date
        if (profileClient.takeOnStartDate) {
            startYear = format(profileClient.takeOnStartDate.toDate(), "yyyy");
            startMonth = format(profileClient.takeOnStartDate.toDate(), "M");
            yearNow = format(Date.now(), "yyyy");

            //Check if this user has an employment start date
        } else if (profileClient.employmentStartDate) {
            startYear = format(profileClient.employmentStartDate.toDate(), "yyyy");
            startMonth = format(profileClient.employmentStartDate.toDate(), "M");
            yearNow = format(Date.now(), "yyyy");
        }

        //Default to Company Financial Year
        let leaveYearStart = parseInt(profileClient.companyYearStart);

        //Now check if the company has a different Leave Type set
        if (profileClient.companyLeaveType && profileClient.companyLeaveType === "Anniversary Date of Employee") {
            if (profileClient.employmentStartDate) {
                leaveYearStart = format(profileClient.employmentStartDate.toDate(), "M");
            }
        }

        //Work out the financial year
        if (startMonth < leaveYearStart) {
            startYear--;
        }

        for (let i = startYear; i <= yearNow; i++) {
            years.push(parseInt(i));
        }

        //Delete the current leaveMonths
        if (profileClient.leaveMonths) {
            profileClient.userId = profileClient.id;
            await clearProfileLeave(profileClient);
        }

        //Use reduce to run through each and generate stats
        await years.reduce(async (promise, year) => {
            await promise;

            let date = new Date(year + "-" + leaveYearStart + "-01");

            await generateLeaveStats(profileClient.id, "Leave stats updated for " + year + "!", date);
        }, undefined);

        setGeneratingStats(false);
    }

    return (
        <div>
            <Container>
                {profileClient && profileClient.hasLeave && (
                    <Segment>
                        <h2>Current Leave Usage and Availability</h2>

                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    {company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method" ? (
                                        <>{profileClient && leave.hasOwnProperty("userUid") && <LeaveLeftNew profileClient={profileClient} year={selectedYear} layout="horizontal" />}</>
                                    ) : null}
                                    {(company && company.leaveMethodType === undefined) || company.leaveMethodType === "Old Method" ? (
                                        <>{profileClient && <LeaveLeftOld profileClient={profileClient} year={selectedYear} layout="horizontal" />}</>
                                    ) : null}
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                {profile.id === profileClient.id && (
                                                    <Button
                                                        onClick={() =>
                                                            dispatch(
                                                                openModal({
                                                                    modalType: "LeaveTrackModal",
                                                                    modalProps: { profile: profile, profileClient: profileClient, type: "add", company: company },
                                                                })
                                                            )
                                                        }
                                                        color="blue"
                                                    >
                                                        APPLY FOR LEAVE
                                                    </Button>
                                                )}

                                                {profile && (profile.isSuper === true || profile.isAdmin === true) && profile.id !== profileClient.id && (
                                                    <Button
                                                        onClick={() =>
                                                            dispatch(openModal({ modalType: "LeaveManualTrackModal", modalProps: { profile: profileClient, profileClient: profileClient } }))
                                                        }
                                                        color="blue"
                                                    >
                                                        LOAD MANUAL LEAVE
                                                    </Button>
                                                )}

                                                {/* GENERATE LEAVE FOR OLD METHOD BUTTON */}
                                                {(company && company.leaveMethodType === undefined) || (company && company.leaveMethodType === "Old Method") ? (
                                                    <>
                                                        {profile && profile.isSuper === true && (
                                                            <Button onClick={() => genStats()} disabled={generatingStats} loading={generatingStats} color="blue">
                                                                GENERATE STATS
                                                            </Button>
                                                        )}
                                                    </>
                                                ) : null}

                                                {/* GENERATE LEAVE FOR OLD METHOD BUTTON */}
                                                {(company && company.leaveMethodType === undefined) || (company && company.leaveMethodType === "Old Method") ? (
                                                    <>
                                                        {profile && profile.isSuper === true && (
                                                            <Button onClick={() => genStatsAll()} disabled={generatingStats} loading={generatingStats} color="blue">
                                                                GENERATE ALL STATS
                                                            </Button>
                                                        )}
                                                    </>
                                                ) : null}

                                                {/* GENERATE LEAVE FOR NEW METHOD BUTTON */}
                                                {company && company.leaveMethodType !== undefined && company.leaveMethodType === "New Method" ? (
                                                    <>
                                                        {profile && profile.isSuper === true && profile.id === profileClient.id && (
                                                            <Button onClick={() => generateNewLeave(profile.id)} loading={loading} color="blue">
                                                                GENERATE NEW STATS
                                                            </Button>
                                                        )}
                                                        {profile && profile.isSuper === true && profile.id !== profileClient.id && (
                                                            <Button
                                                                onClick={() => {
                                                                    // console.log(profileClient.id);
                                                                    generateNewLeave(profileClient.id);
                                                                }}
                                                                loading={loading}
                                                                color="blue"
                                                            >
                                                                GENERATE NEW STATS
                                                            </Button>
                                                        )}
                                                    </>
                                                ) : null}

                                                {company &&
                                                    company.leaveMethodType !== undefined &&
                                                    company.leaveMethodType === "New Method" &&
                                                    profile &&
                                                    profile.isSuper === true &&
                                                    profile.companyName === "Whitehart" &&
                                                    profileClient["leaveMonths"] &&
                                                    profileClient["leaveMonths"][new Date().getFullYear()] &&
                                                    profileClient["leaveMonths"][new Date().getFullYear()]["nonGrowing"] && (
                                                        <Button color="blue" as={Link} to={"/compare/" + id}>
                                                            COMPARE LEAVE TABLES
                                                        </Button>
                                                    )}

                                                {profile &&
                                                    (profile.isSuper === true ||
                                                        profile.isAdmin === true ||
                                                        profile.isHR === true ||
                                                        (profile.isLineManager === true && profile.departments.includes(profileClient.departmentId))) && (
                                                        <ExcelFile element={<Button color="blue">DOWNLOAD LEAVE REPORT</Button>}>
                                                            <ExcelSheet data={leaveUsed} name={"Leave " + profileClient.displayName}>
                                                                <ExcelColumn label="Leave Type" value="leaveType" />
                                                                <ExcelColumn label="Date From" value="annualFrom" />
                                                                <ExcelColumn label="Date To" value="annualTo" />
                                                                <ExcelColumn label="Total Days" value="annualDays" />
                                                                <ExcelColumn label="Half Day" value="halfDay" />
                                                                <ExcelColumn label="Status" value="status" />
                                                                <ExcelColumn label="Description" value="description" />
                                                            </ExcelSheet>
                                                        </ExcelFile>
                                                    )}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                )}
            </Container>
        </div>
    );
}
