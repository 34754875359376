import React from "react";
import LeaveListItem from "./LeaveListItem";
import { List } from "semantic-ui-react";

export default function LeaveList(props) {
    const { leave, profileClient, year } = props;

    return (
        <div>
            <List>{leave && leave.length > 0 && leave.map((lv) => <LeaveListItem key={lv.id} leave={lv} profileClient={profileClient} year={year} />)}</List>
        </div>
    );
}
