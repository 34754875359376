import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Divider } from "semantic-ui-react";
import { leaveManualTrack, getBlockedDates } from "../staffActions";
import { generateLeaveStats, generateNew } from "../../manager/managerActions";
import format from "date-fns/format";
import addSeconds from "date-fns/addSeconds";
import add from "date-fns/add";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import { startOfMonth } from "date-fns/esm";
import { closeModal } from "../../../app/common/modals/modalReducer";

import firebase from "../../../app/config/firebase";
import { addMonths, subDays } from "date-fns";
const firestore = firebase.firestore();

export default function LeaveManualTrackForm(props) {
    const { currentUserProfile } = useSelector((state) => state.profile);
    let profileClient = props.profileClient;
    let profile = props.profile;
    let parts = window.location.href.split("/");
    let userId = parts.pop() || parts.pop();
    const dispatch = useDispatch();
    let company = props.company;

    let leaveType = [
        { key: "Annual", text: "Annual Leave", value: "Annual" },
        { key: "Sick", text: "Sick Leave", value: "Sick" },
        { key: "Study", text: "Study Leave", value: "Study" },
        { key: "Family", text: "Family Leave", value: "Family" },
        { key: "Maternity", text: "Maternity Leave", value: "Maternity" },
        { key: "Paternity", text: "Paternity Leave", value: "Paternity" },
        { key: "IOD", text: "IOD Leave", value: "IOD" },
        { key: "Parental", text: "Parental Leave", value: "Parental" },
        { key: "Moving", text: "Moving Leave", value: "Moving" },
        { key: "CSR", text: "CSR Leave", value: "CSR" },
        { key: "Religious", text: "Religious Leave", value: "Religious" },
        { key: "Special Leave", text: "Special Leave", value: "Special Leave" },
    ];

    if (currentUserProfile.isSuper) {
        leaveType.push({ key: "Incentive", text: "Incentive Leave", value: "Incentive" });
    }

    const leaveStatus = [
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Approved", text: "Approved", value: "Approved" },
        { key: "Denied", text: "Denied", value: "Denied" },
    ];

    const accumulativeChoices = [
        { key: "Yes", text: "Yes", value: "Yes" },
        { key: "No", text: "No", value: "No" },
    ];

    const [annualFrom, setAnnualFrom] = useState("");
    const [annualTo, setAnnualTo] = useState("");
    const [halfDay, setHalfDay] = useState("no");
    const [annualdays, setAnnualDays] = useState("0");
    const [blockedDates, setBlockedDates] = useState("");
    const [toMinDate, setToMinDate] = useState("");
    const [leave, setLeave] = useState([]);
    const [leaveMonthly, setLeaveMonthly] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [allowSaturday, setAllowSaturday] = useState(false);
    const [allowSunday, setAllowSunday] = useState(false);

    useEffect(() => {
        getBlockedDates().then(
            (res) => {
                if (res.exists) {
                    setBlockedDates(res.data());
                }
            },
            (err) => {
                console.log(err);
            }
        );

        const unsubscribe2 = firestore
            .collection("companies")
            .doc(profileClient.companyId)
            .onSnapshot(async (snapshot) => {
                if (snapshot.exists) {
                    setCompanyData(snapshot.data());
                    let companyInfo = { ...snapshot.data(), id: snapshot.id };

                    setAllowSaturday(companyInfo.saturdayWorkDay);
                    setAllowSunday(companyInfo.sundayWorkDay);

                    if (companyInfo && companyInfo.leaveMethodType && companyInfo.leaveMethodType === "New Method") {
                        await firestore
                            .collection("leaveStatus")
                            .where("userUid", "==", profileClient.id)
                            .where("toDate", ">", new Date())
                            .where("periodType", "==", "Leave")
                            .onSnapshot(async (snapshot) => {
                                setLeave(snapshot.docs[0].data());
                                await firestore
                                    .collection("leaveStatus")
                                    .doc(snapshot.docs[0].id)
                                    .collection("Months")
                                    .where("datePeriod", "==", startOfMonth(new Date()))
                                    // .where("datePeriod", "==", new Date("'2023-02-15T12:00:00.000z'"))
                                    .onSnapshot((snapshot2) => {
                                        // console.log(snapshot2.docs);
                                        setLeaveMonthly(snapshot2.docs[0].data());
                                    });
                            });
                    }
                }
            });

        return () => {
            unsubscribe2();
        };
    }, [company]);

    let excludedDates = [];

    Object.keys(blockedDates).forEach(function (key) {
        excludedDates.push(blockedDates[key].blockDate.toDate());
    });

    const updateAnnualFrom = useCallback((value) => {
        setAnnualFrom(new Date(value));
        setToMinDate(new Date(value));
    }, []);
    const updateAnnualTo = useCallback((value) => {
        setAnnualTo(value);
    }, []);
    const updateHalfDay = useCallback((value) => {
        setHalfDay(value);
    }, []);
    const updateAnnualDays = useCallback((value) => {
        setAnnualDays(value);
    }, []);

    function calcDays(type, val, annualFromDate, annualToDate, halfDaySpec, blockedDatesDays) {
        let annualFrom = annualFromDate;
        let annualTo = annualToDate;
        let halfDay = halfDaySpec;
        let c = 0;

        if (type === "annualFrom") {
            updateAnnualFrom(val);
            annualFrom = val;
        }
        if (type === "annualTo") {
            updateAnnualTo(val);
            annualTo = val;
        }
        if (type === "halfDay") {
            updateHalfDay(val);
            halfDay = val;
        }

        let blockedDates = blockedDatesDays;

        if (annualFrom !== "" && annualTo === "" && halfDay !== "no") {
            c = 0.5;

            if (type === "annualFrom") {
                annualTo = annualFrom;
            }
            if (type === "annualTo") {
                annualFrom = annualTo;
            }
        }

        if (annualFrom !== "" && annualTo !== "") {
            if (halfDay !== "no") {
                updateAnnualTo(annualFrom);
                annualTo = annualFrom;
                c = 0.5;
            } else {
                let a = annualFrom;
                let b = annualTo;
                let foundblock = 0;
                let compare_dates = function (date1, date2) {
                    if (date1 > date2) return "<";
                    else if (date1 < date2) return ">";
                    else return "=";
                };

                let compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));

                while (compared === ">" || compared === "=") {
                    // if (format(a, "EEE") !== "Sat" && format(a, "EEE") !== "Sun") {
                    if ((format(a, "EEE") == "Sat" && allowSaturday == true) || (format(a, "EEE") == "Sun" && allowSunday == true) || (format(a, "EEE") !== "Sat" && format(a, "EEE") !== "Sun")) {
                        foundblock = 0;
                        // eslint-disable-next-line no-loop-func
                        Object.keys(blockedDates).forEach(function (key) {
                            let helperDate = addSeconds(new Date(0), blockedDates[key].blockDate.seconds);
                            if (format(helperDate, "yyyy-MM-dd") === format(a, "yyyy-MM-dd")) {
                                foundblock = 1;
                            }
                        });
                        if (foundblock === 0) {
                            c++; //add 1 to your counter if its not a weekend day
                        }
                    }
                    a = add(new Date(format(new Date(a), "yyyy"), format(new Date(a), "MM"), format(new Date(a), "dd")), {
                        years: 0,
                        months: -1,
                        days: 1,
                    });
                    compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));
                }
            }
        }

        if (c === 0) {
            c = null;
        }

        updateAnnualDays(c);
    }

    let accumTotal = 0;
    let accumUsed = 0;
    let accumLeft = 0;
    let accumForfeit = "";
    let showAccum = false;
    let showAccum2 = false;

    if (leaveMonthly.hasOwnProperty("accumTotal") && leaveMonthly.hasOwnProperty("accumUsed")) {
        accumTotal = leaveMonthly.accumTotal;
        accumUsed = leaveMonthly.accumUsed;
        accumLeft = accumTotal - accumUsed;
        showAccum = true;

        if (companyData.leaveRolloverType === "Accumulative") {
            accumForfeit = subDays(addMonths(leave.fromDate.toDate(), companyData.leaveAccumulativeForfeit), 1);

            if (new Date() > accumForfeit) {
                accumForfeit = "";
                showAccum = false;

                if (leaveMonthly.accumTotal2 > 0) {
                    accumTotal = leaveMonthly.accumTotal2;
                    accumUsed = leaveMonthly.accumUsed2;
                    accumLeft = accumTotal - accumUsed;
                    showAccum2 = true;
                }
            }
        }
    }

    if (profile["leaveMonths"] && profile["leaveMonths"][format(Date.now(), "yyyy")]) {
        let yearNow = format(Date.now(), "yyyy");
        if (profile["leaveMonths"][yearNow]["nonGrowing"]["accumTotal"] && profile["leaveMonths"][yearNow]["nonGrowing"]["accumTotal"] > 0) {
            accumTotal = parseFloat(profile["leaveMonths"][yearNow]["nonGrowing"]["accumTotal"]);
            accumUsed = parseFloat(profile["leaveMonths"][yearNow]["nonGrowing"]["accumUsed"]);
            accumLeft = accumTotal - accumUsed;
            showAccum = true;

            if (profile["leaveMonths"][yearNow]["nonGrowing"]["accumForfeitDate"]) {
                accumForfeit = profile["leaveMonths"][yearNow]["nonGrowing"]["accumForfeitDate"];

                //Zero the forfeit date if we are past it
                if (new Date() > accumForfeit.toDate()) {
                    accumForfeit = "";
                    showAccum = false;

                    //If we have a post accumulative leave assign that
                    if (profile["leaveMonths"][yearNow]["nonGrowing"]["accumTotal2"] && profile["leaveMonths"][yearNow]["nonGrowing"]["accumTotal2"] > 0) {
                        accumTotal = parseFloat(profile["leaveMonths"][yearNow]["nonGrowing"]["accumTotal2"]);
                        accumUsed = parseFloat(profile["leaveMonths"][yearNow]["nonGrowing"]["accumUsed2"]);
                        accumLeft = accumTotal - accumUsed;
                        showAccum2 = true;
                    }
                }
            }
        }
    }

    return (
        <Formik
            initialValues={{
                leaveType: "",
                unpaidLeave: "No",
                description: "",
                useAccumulative: "",
                useAccumulative2: "",
                uploadFile: "",
                annualDays: "0",
                annualFrom: "",
                annualTo: "",
                halfDay: "no",
                fromTime: "",
                toTime: "",
                timeHours: "",
                status: "",
            }}
            enableReinitialize="true"
            validate={(values) => {
                const error = {};

                return error;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                values.companyId = profile.companyId;
                values.departmentId = profile.departmentId;
                values.displayName = profile.displayName;
                values.userUid = profile.id;
                values.addedBy = currentUserProfile.id;
                values.addedByDisplayName = currentUserProfile.displayName ? currentUserProfile.displayName : currentUserProfile.email;
                values.annualFrom = annualFrom;
                if (annualTo === "") {
                    values.annualTo = values.annualFrom;
                } else {
                    values.annualTo = annualTo;
                }
                values.annualDays = annualdays;

                if (values.leaveType == "Incentive") {
                    if (values.halfDay !== "no") {
                        let a = annualFrom;
                        let b = annualTo;
                        let c = 0;
                        let compare_dates = function (date1, date2) {
                            if (date1 > date2) return "<";
                            else if (date1 < date2) return ">";
                            else return "=";
                        };

                        let compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));

                        while (compared === ">" || compared === "=") {
                            c++;

                            a = add(new Date(format(new Date(a), "yyyy"), format(new Date(a), "MM"), format(new Date(a), "dd")), {
                                years: 0,
                                months: -1,
                                days: 1,
                            });
                            compared = compare_dates(new Date(format(new Date(a), "yyyy/MM/dd")), new Date(format(new Date(b), "yyyy/MM/dd")));
                        }

                        values.annualDays = c;
                    } else {
                        values.annualDays = "0.5";
                    }
                }

                // console.log("1");

                await leaveManualTrack(values);

                // console.log("2");

                // console.log(companyData);

                if (companyData.leaveMethodType === undefined || companyData.leaveMethodType === "Old Method") {
                    // console.log(props.profileClient.id);
                    await generateLeaveStats(props.profileClient.id, "Leave stats updated!");
                    setSubmitting(false);
                    dispatch(closeModal());
                }

                if (companyData.leaveMethodType !== undefined || companyData.leaveMethodType === "New Method") {
                    await generateNew(profile.id);
                    setSubmitting(false);
                    dispatch(closeModal());
                }
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="ui form">
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Type</label>
                            <MySelectInput
                                name="leaveType"
                                options={leaveType}
                                value={values.leaveType}
                                onChange={(field, value) => setFieldValue("leaveType", value.value)}
                                placeholder="Select your Leave Type"
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>From</label>
                            <DatePicker
                                name="annualFrom"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                value={values.annualFrom}
                                selected={annualFrom}
                                onChange={(value) => {
                                    calcDays("annualFrom", value, annualFrom, annualTo, halfDay, blockedDates);
                                    setFieldValue("annualFrom", value.value);
                                }}
                                disabledKeyboardNavigation
                                inline
                                excludeDates={blockedDates}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>To</label>
                            <DatePicker
                                name="annualTo"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                value={values.annualTo}
                                selected={annualTo}
                                onChange={(value) => {
                                    calcDays("annualTo", value, annualFrom, annualTo, halfDay, blockedDates);
                                    setFieldValue("annualTo", value.value);
                                }}
                                disabledKeyboardNavigation
                                inline
                                excludeDates={blockedDates}
                                minDate={toMinDate}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>No. of Days</label>
                            <MyTextInput
                                name="annualDays"
                                onChange={(field, value) => {
                                    setFieldValue("annualDays", field.target.value);
                                    setAnnualDays(field.target.value);
                                }}
                                value={annualdays}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Status</label>
                            <MySelectInput
                                name="status"
                                options={leaveStatus}
                                value={values.status}
                                onChange={(field, value) => setFieldValue("status", value.value)}
                                placeholder="Select your Leave Status"
                            />
                        </Form.Field>
                    </Form.Group>

                    {/* {console.log((showAccum === true || showAccum2 === true) && accumLeft && accumLeft > 0)} */}

                    <Form.Group widths="equal">
                        <Form.Field>
                            {((showAccum === true || showAccum2 === true) && accumLeft && accumLeft > 0) === true && (
                                <label>
                                    Accumulative Leave Days = {accumLeft}
                                    {accumForfeit ? "\nExpires " + new Date(accumForfeit).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ""}
                                </label>
                            )}

                            {(showAccum === true && accumLeft && accumLeft > 0) === true && (
                                <MySelectInput
                                    name="useAccumulative"
                                    options={accumulativeChoices}
                                    value={values.useAccumulative}
                                    onChange={(field, value) => setFieldValue("useAccumulative", value.value)}
                                    placeholder="Use Accumulative Leave"
                                />
                            )}

                            {showAccum2 === true && accumLeft && accumLeft > 0 && (
                                <MySelectInput
                                    name="useAccumulative2"
                                    options={accumulativeChoices}
                                    value={values.useAccumulative2}
                                    onChange={(field, value) => setFieldValue("useAccumulative2", value.value)}
                                    placeholder="Use Accumulative Leave"
                                />
                            )}
                        </Form.Field>

                        <Form.Field>
                            <label className="ui">
                                <input
                                    type="checkbox"
                                    name="dontTrackUsed"
                                    value="true"
                                    checked={values.dontTrackUsed}
                                    onChange={(value) => {
                                        setFieldValue("dontTrackUsed", value.target.checked);
                                    }}
                                    className="ui checkbox"
                                />
                                &nbsp; Unpaid Leave
                                <br />
                                (Don't track as Used Leave)
                            </label>
                        </Form.Field>

                        <Form.Field>
                            <label className="ui">
                                <input
                                    type="checkbox"
                                    name="dontSendEmails"
                                    value="true"
                                    checked={values.dontSendEmails}
                                    onChange={(value) => {
                                        setFieldValue("dontSendEmails", value.target.checked);
                                    }}
                                    className="ui checkbox"
                                />
                                &nbsp; Don't send Emails
                            </label>
                        </Form.Field>

                        <Form.Field>
                            <label>Half Day</label>

                            <label>
                                <input
                                    name="halfDay"
                                    type="radio"
                                    value="no"
                                    checked={halfDay === "no" ? true : false}
                                    onChange={(event) => calcDays("halfDay", "no", annualFrom, annualTo, halfDay, blockedDates)}
                                />{" "}
                                No
                            </label>

                            <label>
                                <input name="halfDay" type="radio" value="yes" onChange={(event) => calcDays("halfDay", "yes", annualFrom, annualTo, halfDay, blockedDates)} /> Yes
                            </label>
                        </Form.Field>

                        <Form.Field>
                            <label>Description</label>
                            <MyTextInput name="description" placeholder="Additional description for leave taken" />
                        </Form.Field>
                    </Form.Group>

                    <Divider />

                    <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" type="submit" color="blue">
                        ADD LEAVE
                    </Button>
                </form>
            )}
        </Formik>
    );
}
