import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { uploadIRP5, getUsers, customUploadIRP5 } from "../irp5Actions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import axios from "axios";
import apiURL from "../../../app/config/apiURL";

export default function IRP5UploadForm(props) {
    const [assignUsers, setAssignUsers] = useState([]);
    const [userDetails, setUserDetails] = useState([]);

    useEffect(() => {
        getUsers().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let assignUsers = [
                    {
                        key: "",
                        text: "Choose a User",
                        value: "",
                    },
                ];
                let Users = [];

                Object.keys(list).forEach(function (key) {
                    // if (props.profileOriginal && props.profileOriginal.isSuper) {
                    //     //For Super we can add all users
                    //     assignUsers.push({
                    //         key: list[key].id,
                    //         text: list[key].displayName && list[key].displayName.length > 0 ? list[key].companyName + " - " + list[key].displayName : list[key].companyName + " - " + list[key].email,
                    //         value: list[key].id,
                    //     });
                    // } else if (props.profileOriginal && (props.profileOriginal.isAdmin || props.profileOriginal.isHR)) {
                    //For Admin and HR just add users for the users company
                    // if (props.profileOriginal.companyId === list[key].companyId) {
                    assignUsers.push({
                        key: list[key].id,
                        text: list[key].displayName && list[key].displayName.length > 0 ? list[key].companyName + " - " + list[key].displayName : list[key].companyName + " - " + list[key].email,
                        value: list[key].id,
                    });
                    Users.push({ userId: list[key].id, userIdentity: list[key].employee_id_no });
                    // }
                    // }
                });

                setAssignUsers(assignUsers);
                setUserDetails(Users);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    return (
        <Segment>
            <Formik
                initialValues={{ documentName: "", encrypt: true }}
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {

                    if(values.encrypt === true){

                        var formData = new FormData();
                        var imagefile = document.querySelector("#uploadFile");
                        formData.append("file", imagefile.files[0]);
                        formData.append("id", values.userUid);
                        formData.append("encrypt", values.encrypt);
    
                        // userDetails
                        userDetails.forEach((doc) => {
                            if (doc.userId === values.userUid) {
                                formData.append("pass", doc.userIdentity);
                            }
                        });
    
                        let FileUpload = await axios.post(apiURL + "/api/encrypt_pdf", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                        delete values.uploadFile;
    
                        values.downloadURL = apiURL + "/" + FileUpload.data;
    
                        await uploadIRP5(values);
                    }else{
                        await customUploadIRP5(values)
                    }
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Form.Field>
                                        <label>IRP5 Date</label>
                                        <MyTextInput name="documentName" placeholder="Enter a date for the IRP5" />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <Form.Field>
                                        <label>User</label>

                                        <MySelectInput
                                            name="userUid"
                                            options={assignUsers}
                                            value={values.userUid}
                                            onChange={(field, value) => setFieldValue("userUid", value.value)}
                                            placeholder="Choose a User"
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <Form.Field>
                                        <label>File to Upload</label>
                                        <input
                                            id="uploadFile"
                                            name="uploadFile"
                                            type="file"
                                            onChange={(event) => {
                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                            }}
                                            className="form-control"
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Field>
                                        <label style={{ marginTop: "30px" }}>
                                            <input
                                                type="checkbox"
                                                name={"encrypt"}
                                                id="encrypt"
                                                defaultChecked="true"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => {
                                                    setFieldValue("encrypt", value.target.checked);
                                                }}
                                            />
                                            &nbsp; Add Password
                                        </label>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={1}>
                                <Grid.Column width={16}>
                                    <Button disabled={isSubmitting} loading={isSubmitting} fluid size="large" color="blue">
                                        UPLOAD
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Segment>
    );
}
