import cuid from "cuid";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";
import axios from "axios";
import apiURL from "../../app/config/apiURL";
// var qpdf = require("node-qpdf");

export async function uploadPayslip(payslip) {
    const firestore = firebase.firestore();

    // const path = `payslips`;
    // const options = {
    //     name: cuid() + "-" + payslip.uploadFile.name,
    // };
    // const file = payslip.uploadFile;

    // console.log(file);

    try {
        // let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        let new_payslip = {};
        // new_payslip.fileName = payslip.uploadFile.name;
        new_payslip.fileUrl = payslip.downloadURL;
        new_payslip.documentName = payslip.documentName;
        // new_payslip.documentNameRand = options.name;
        new_payslip.userUid = payslip.userUid;

        new_payslip.date = new Date();
        new_payslip.version = 1;
        new_payslip.status = "Mapped";

        // var optionsPass = {
        //     keyLength: 128,
        //     password: "YOUR_PASSWORD_TO_ENCRYPT",
        // };
        // qpdf.encrypt(downloadURL, optionsPass);

        await firestore.collection("payslips").add(new_payslip);

        toast.success("Payslip added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function customUploadPayslip(payslip) {
    const firestore = firebase.firestore();

    const path = `payslips`;
    const options = {
        name: cuid() + "-" + payslip.uploadFile.name,
    };
    const file = payslip.uploadFile;

    // console.log(file);

    try {
        let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        let new_payslip = {};
        new_payslip.fileName = payslip.uploadFile.name;
        new_payslip.fileUrl = downloadURL;
        new_payslip.documentName = payslip.documentName;
        new_payslip.documentNameRand = options.name;
        new_payslip.userUid = payslip.userUid;

        new_payslip.date = new Date();
        new_payslip.version = 1;
        new_payslip.status = "Mapped";

        await firestore.collection("payslips").add(new_payslip);

        toast.success("Payslip added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function deletePayslip(payslip) {
    const firestore = firebase.firestore();
    var storageRef = firebase.storage().ref();

    storageRef.child(`payslips/${payslip.documentNameRand}`).getDownloadURL().then(onResolve, onReject);

    async function onResolve(foundURL) {
        await firebase.deleteFile(`payslips/${payslip.documentNameRand}`);
        await firestore.collection("payslips").doc(payslip.id).delete();
    }

    async function onReject(error) {
        await firestore.collection("payslips").doc(payslip.id).delete();
    }
}

export async function customBulkpayslip(payslip) {
    console.log(payslip);
    const firestore = firebase.firestore();

    try {
        let new_payslip = {};
        new_payslip.fileName = payslip.documentFullName;
        new_payslip.fileUrl = payslip.downloadURL;
        new_payslip.date = new Date();
        new_payslip.status = "Unsorted";
        new_payslip.documentName = payslip.documentName;
        new_payslip.companyId = payslip.companyId;
        new_payslip.encrypt = payslip.encrypt;

        //Get company data
        let company = await firestore.collection("companies").doc(payslip.companyId).get();
        company = company.data();
        new_payslip.companyName = company.companyName;

        //     //Try to map to user
        let search = new_payslip.fileName.replace(".pdf", "");

        if (payslip.mapBy === "Employee Number") {
            await firestore
                .collection("users")
                .where("employeeNr", "==", search)
                .where("companyId", "==", payslip.companyId)
                .limit(1)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            new_payslip.userUid = doc.id;
                            new_payslip.displayName = doc.data().displayName;
                            new_payslip.employeeNr = doc.data().employeeNr;
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error find user: ", error);
                });
        } else {
            await firestore
                .collection("users")
                .where("companyId", "==", payslip.companyId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            let parts = search.split("_");

                            if (doc.data().employee_initials !== undefined) {
                                if (parts[1] == doc.data().employee_initials.toLowerCase() && parts[2] == doc.data().employee_surname.toLowerCase()) {
                                    new_payslip.userUid = doc.id;
                                    new_payslip.displayName = doc.data().displayName;
                                    new_payslip.employeeNr = doc.data().employeeNr;
                                }
                            }
                        }
                    });
                });
        }

        await firestore.collection("payslips").add(new_payslip);

        toast.success("Payslips added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function uploadBulkPayslips(payslip, documentName, companyId, mapBy, encrypt) {
    const firestore = firebase.firestore();

    const path = `payslips`;
    const options = {
        name: cuid() + "-" + payslip.name,
    };
    const file = payslip;

    try {
        let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        let new_payslip = {};
        new_payslip.fileName = payslip.name;
        new_payslip.fileUrl = downloadURL;
        new_payslip.documentNameRand = options.name;
        new_payslip.date = new Date();
        new_payslip.status = "Unsorted";
        new_payslip.documentName = documentName;
        new_payslip.companyId = companyId;
        new_payslip.encrypt = encrypt;

        //Get company data
        let company = await firestore.collection("companies").doc(companyId).get();
        company = company.data();
        new_payslip.companyName = company.companyName;

        //Try to map to user
        let search = new_payslip.fileName.replace(".pdf", "");

        if (mapBy === "Employee Number") {
            await firestore
                .collection("users")
                .where("employeeNr", "==", search)
                .where("companyId", "==", companyId)
                .limit(1)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            new_payslip.userUid = doc.id;
                            new_payslip.displayName = doc.data().displayName;
                            new_payslip.employeeNr = doc.data().employeeNr;
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error find user: ", error);
                });
        } else {
            await firestore
                .collection("users")
                .where("companyId", "==", companyId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.exists) {
                            let parts = search.split("_");

                            if (doc.data().employee_initials !== undefined) {
                                if (parts[1] == doc.data().employee_initials.toLowerCase() && parts[2] == doc.data().employee_surname.toLowerCase()) {
                                    new_payslip.userUid = doc.id;
                                    new_payslip.displayName = doc.data().displayName;
                                    new_payslip.employeeNr = doc.data().employeeNr;
                                }
                            }
                        }
                    });
                });
        }

        await firestore.collection("payslips").add(new_payslip);

        toast.success("Payslips added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function confirmMapping(payslip) {
    const firestore = firebase.firestore();

    try {
        let new_payslip = {
            status: "Mapped",
        };

        if (payslip.encrypt === true) {
            let user = firestore
                .collection("users")
                .where("employeeNr", "==", payslip.employeeNr)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(async function (doc) {
                        if (doc.exists) {
                            new_payslip.userUid = doc.id;
                            new_payslip.displayName = doc.data().displayName;
                            new_payslip.employeeNr = doc.data().employeeNr;

                            let userData = doc.data();

                            var formData = new FormData();
                            formData.append("documentName", payslip.documentName);
                            formData.append("fileUrl", payslip.fileUrl);
                            formData.append("fileUrlString", payslip.fileUrl.split("/").pop());
                            formData.append("fileName", payslip.fileName);
                            formData.append("pass", userData.employee_id_no);
                            formData.append("id", doc.id);
                            formData.append("employeeNr", payslip.employeeNr);

                            let FileUpload = await axios.post(apiURL + "/api/encrypt_bulk_pdf", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });

                            new_payslip.fileUrl = apiURL + "/" + FileUpload.data;

                            await firestore.collection("payslips").doc(payslip.id).update(new_payslip);
                        }
                    });
                })
                .catch(function (error) {
                    console.log("Error find user: ", error);
                });
        } else {
            await firestore.collection("payslips").doc(payslip.id).update(new_payslip);
        }

        //Action Center
        let notification = {
            createDate: new Date(Date.now()),
            notificationType: "Notification",
            rowModule: "Payslips",
            rowType: "",
            rowId: payslip.id,
            description: "Payslip Uploaded - " + payslip.documentName,
            read: false,
            userUid: payslip.userUid,
            displayName: payslip.displayName,
        };

        await firestore.collection("notifications").add(notification);

        toast.success("Payslips added");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getPayslips(id) {
    let userId = "";
    if (id === "payslips") {
        userId = firebase.auth().currentUser.uid;
    } else {
        userId = id;
    }
    const firestore = firebase.firestore();
    return firestore.collection("payslips").where("userUid", "==", userId).where("status", "==", "Mapped").get();
}

export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};

export async function getProfile(id) {
    const firestore = firebase.firestore();
    let userId = "";
    if (id === "payslips") {
        userId = firebase.auth().currentUser.uid;
    } else {
        userId = id;
    }
    return firestore.collection("users").doc(userId).get();
}

export async function getUsers() {
    const firestore = firebase.firestore();
    return firestore.collection("users").get();
}
export async function getPayslip() {
    const firestore = firebase.firestore();
    return firestore.collection("payslips").where("status", "==", "Unsorted").get();
}
export async function getCompanies() {
    const firestore = firebase.firestore();
    return firestore.collection("companies").get();
}
