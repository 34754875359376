import React from "react";
import { Modal, Button, Image, Divider } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import LeaveAppForm from "../manager/ManagerLeaveList/LeaveAppForm";

export default function LeaveAppModal(props) {
    let { leaveCurrent, profileClient, company, preApprove } = props;
    let header_cls = "orange_header";
    const dispatch = useDispatch();

    if (leaveCurrent.status === "Approved") {
        header_cls = "green_header";
    } else if (leaveCurrent.status === "Denied") {
        header_cls = "red_header";
    }

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false} trigger={<Button>Show Modal</Button>} closeIcon>
            <Modal.Header>
                <Image style={{ zIndex: 2, maxHeight: "130px", maxWidth: "130px", width: "auto" }} avatar spaced="right" size="small" src={profileClient.photoURL || "/assets/user.png"} />

                <div className={header_cls}>
                    <div className="ui right aligned header" style={{ marginRight: 40, marginTop: 5, color: "white" }}>
                        <h3>{leaveCurrent.status}</h3>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Content>
                <Modal.Description>
                    <div className="content_top">
                        <img className="img_header" src="/assets/your_documents.png" alt="Your Documents" />

                        <h2 className="ui center aligned header">{props.preApprove && props.preApprove === true && "PRE "}APPROVE/DENY LEAVE FORM</h2>
                    </div>

                    {leaveCurrent.changeReason && leaveCurrent.changeReason.length > 0 && (
                        <div>
                            <Divider />

                            <h3>CHANGE REQUEST:</h3>
                            {leaveCurrent.changeReason}
                        </div>
                    )}

                    <Divider />

                    <LeaveAppForm leave={leaveCurrent} company={company} currentUser={props.authUser} preApprove={props.preApprove} />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
