import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Segment, Grid, Button, Table } from "semantic-ui-react";
import format from "date-fns/format";
import { markRead } from "../notificationActions";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function NotificationsDashboardPage(props) {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("notifications")
            .where("userUid", "==", firebase.auth().currentUser.uid)
            .where("read", "==", false)
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setNotifications(list);
            });

        return () => unsubscribe();
    }, []);

    async function markThisRead(notif, url) {
        await markRead(notif);
        props.history.push(url);
    }

    return (
        <Container>
            <Segment>
                <Grid>
                    <Grid.Row verticalAlign="middle" columns={1}>
                        <Grid.Column width={8}>
                            <h1>Action Centre</h1>
                        </Grid.Column>

                        <Grid.Column width={8} textAlign="right">
                            <Link as="a" to="/home">
                                <Button color="blue">BACK TO DASHBOARD</Button>
                            </Link>
                        </Grid.Column>
                    </Grid.Row>

                    {notifications && notifications.length > 0 && (
                        <Grid.Row>
                            <Grid.Column>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Details</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Requested On</Table.HeaderCell>
                                            <Table.HeaderCell>Actions</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {notifications &&
                                            notifications.length > 0 &&
                                            notifications.map((notif) => (
                                                <Table.Row key={notif.id}>
                                                    <Table.Cell>{notif.notificationType}</Table.Cell>

                                                    <Table.Cell>
                                                        {notif.rowType && notif.rowType.length > 0 ? (
                                                            <p>
                                                                {notif.rowModule} - {notif.rowType}
                                                            </p>
                                                        ) : (
                                                            <p>{notif.rowModule}</p>
                                                        )}

                                                        {notif.notificationType === "Action" && <p>{notif.displayName}</p>}
                                                    </Table.Cell>

                                                    <Table.Cell>{notif.description}</Table.Cell>
                                                    <Table.Cell>
                                                        {format(notif.createDate.toDate(), "d MMMM yyyy")}
                                                        <br />
                                                        {format(notif.createDate.toDate(), "HH:mm")}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {notif.rowModule === "Leave" && (
                                                            <Button color="blue" onClick={() => markThisRead(notif, "/staff/view/" + notif.rowId)}>
                                                                View
                                                            </Button>
                                                        )}

                                                        {notif.rowModule === "Support" && (
                                                            <Button color="blue" onClick={() => markThisRead(notif, "/support/view/" + notif.rowId)}>
                                                                View
                                                            </Button>
                                                        )}

                                                        <Button onClick={() => markThisRead(notif)}>Mark Read</Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {notifications && notifications.length === 0 && (
                        <Grid.Row>
                            <Grid.Column>You don't currently have any notifications</Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </Segment>
        </Container>
    );
}
