import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Container } from "semantic-ui-react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import addSeconds from "date-fns/addSeconds";
import format from "date-fns/format";
import { getLeave, getEmployees } from "./calendarActions";

export default function StaffCalendarPage(props) {
    const [myEventsList, setLeave] = useState([]);
    const localizer = momentLocalizer(moment);

    useEffect(() => {
        getEmployees().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                let users = [];
                let userIDS = [];
                let employeeData = [];
                if (list) {
                    employeeData = list;
                    Object.keys(employeeData).forEach(function (key) {
                        userIDS.push(employeeData[key].id);
                        users.push({ id: employeeData[key].id, name: employeeData[key].displayName !== "" ? employeeData[key].displayName : employeeData[key].email });
                    });
                }

                getLeave().then(
                    (res) => {
                        let list = [];
                        res.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });

                        const myEventsList = [];

                        Object.keys(list).forEach(function (key) {

                            if (userIDS.includes(list[key].userUid)) {
                                let helperDateFrom = addSeconds(new Date(0), list[key]["annualFrom"]["seconds"]);
                                let helperDateTo = addSeconds(new Date(0), list[key]["annualTo"]["seconds"]);
                                let monthFrom = format(helperDateFrom, "MM");
                                let monthTo = format(helperDateTo, "MM");
                                let currentMonth = format(new Date(), "MM");
                                let setMonthFrom = "";
                                let setMonthTo = "";

                                if (monthFrom !== currentMonth) {
                                    setMonthFrom = monthFrom - currentMonth + 1;
                                } else {
                                    setMonthFrom = 1;
                                }
                                if (monthTo !== currentMonth) {
                                    setMonthTo = monthTo - currentMonth + 1;
                                } else {
                                    setMonthTo = 1;
                                }

                                Object.keys(users).forEach(function (key2) {
                                    if (users[key2].id === list[key].userUid) {
                                        myEventsList.push({
                                            title: users[key2].name + " on Leave",
                                            allDay: true,
                                            start: new Date(format(helperDateFrom, "yyyy"), monthFrom, format(helperDateFrom, "dd")),
                                            end: new Date(format(helperDateTo, "yyyy"), monthTo, parseInt(format(helperDateTo, "dd")) + 1),
                                        });
                                    }
                                });
                            }
                        });

                        setLeave(myEventsList);
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    return (
        <Container>
            <Calendar localizer={localizer} events={myEventsList} startAccessor="start" endAccessor="end" style={{ height: 700 }} />
        </Container>
    );
}
