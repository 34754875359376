import React, { useState, useEffect } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getCompany } from "../superActions";

export default function CompaniesPage(props) {
    const [companies, setCompanies] = useState([]);
    useEffect(() => {
        getCompany().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
                setCompanies(list);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);
    return (
        <>
            <Container>
                <Button as={Link} to={`/super/company/0`}>
                    Add Company
                </Button>
                <Button as={Link} to={`/super/landing`}>
                    Back to Super
                </Button>

                <table className="ui celled table selectable">
                    <thead>
                        <tr>
                            <th>Company</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {companies &&
                            companies.length > 0 &&
                            companies.map((company) => (
                                <tr key={company.id}>
                                    <td data-label="Name">{company.companyName}</td>
                                    <td data-label="Action">
                                        <Button as={Link} to={`/super/company/${company.id}`}>
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Container>
        </>
    );
}
